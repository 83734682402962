import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { List, ListItem } from 'material-ui/List';
import Paper from 'material-ui/Paper';
import Avatar from 'material-ui/Avatar';
import ActionLanguage from 'material-ui/svg-icons/action/language';
import { path } from '../utils/utilities';

const styles = {
  header: {
    display: 'block',
    height: 55,
    clear: 'both',
  },
  avatar: {
    float: 'left',
  },
  title: {
    fontSize: '1em',
    lineHeight: '0.7em',
    paddingLeft: 10,
    color: '#00BCD4',
    float: 'left',
  },
  box: {
    width: 220,
    paddingTop: 30,
    margin: '0 auto',
  },
  paper: {
    width: 220,
  },
};

const Language = (props) => {
  const {
    name: siteName,
    config: { localeList },
    theme: { appbarColor },
  } = props.site;

  return (
    <Fragment>
      <div style={{ width: '100%', borderTop: `10px solid ${appbarColor}` }}>
        <div style={styles.box}>
          <div style={styles.header}>
            <Avatar icon={<ActionLanguage />} backgroundColor={appbarColor} style={styles.avatar} />
            <h2 style={styles.title}>
              <span style={{ color: appbarColor }}>
                {props.strings['labels/availableLanguages']}
              </span>
            </h2>
          </div>
          <Paper style={styles.paper} zDepth={1}>
            <List>
              {Object.keys(localeList).map((locale) => (
                <ListItem
                  key={locale}
                  primaryText={localeList[locale]}
                  containerElement={<Link to={`/${siteName}${path.home}`} />}
                  onClick={() => {
                    props.changeLanguage(siteName, locale);
                  }}
                />
              ))}
            </List>
          </Paper>
        </div>
      </div>
    </Fragment>
  );
};

Language.propTypes = {
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  changeLanguage: PropTypes.func.isRequired,
};

export default Language;
