import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Lock from './Lock';
import { path } from '../utils/utilities';
import { isAuthenticated } from '../utils/auth0';

// const Login = props =>
//   isAuthenticated(props.site) ? (
//     <Redirect to={{ pathname: `/${props.site.name}${path.home}` }} />
//   ) : (
//     <Fragment>
//       <Lock site={props.site} strings={props.strings} />
//     </Fragment>
//   );

class Login extends Component {
  componentDidMount() {
    // log('componentDidMount: this.props', this.props);
    document.title = `Log in or Sign up,${this.props.site.title}`;
  }

  componentWillUnmount() {
    document.title = `${this.props.site.title}`;
  }

  render() {
    return isAuthenticated(this.props.site) ? (
      <Redirect to={{ pathname: `/${this.props.site.name}${path.home}` }} />
    ) : (
      <Fragment>
        <Lock site={this.props.site} strings={this.props.strings} />
      </Fragment>
    );
  }
}

Login.propTypes = {
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
};

export default Login;
