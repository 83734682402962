import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { RaisedButton } from 'material-ui';
import { SelectField } from './SelectField';
import { FormLabel } from './FormLabel';
import { Form } from './Form';
import AppNavigation from './AppNavigation';
import Notify from './Notify';
import AddressLocator from './AddressLocator';
import Header from './Header';
import { ErrorIcon } from './icons/ErrorIcon';
import {
  path,
  isMobile,
  possibleAddressFields,
  localeSelectionRequired,
  defineValues,
  capitalize,
  log,
} from '../utils/utilities';
import {
  validateProfile,
  isProfileCompleted,
  isValid,
  validateProfileRequired,
  readProfile,
  writeProfile,
} from '../utils/profile';
import { isAuthenticated, isLoginSkipped, getDetails, needsAuthentication } from '../utils/auth0';
import { AddressLookupUtils } from '../utils/AddressLookupUtils';
import { TextField } from './TextField';

const styles = {
  findLocation: { margin: '5px 8px 10px 8px' },
  buttonLabel: { textTransform: 'initial', fontWeight: 'normal', fontSize: 15 },
  goToCompose: { marginTop: '0px' },
  iconStyle: { fill: '#757575' },
  errorTexts: {
    position: 'relative',
    fontWeight: 'bolder',
    color: 'rgb(244, 67, 54)',
    fontSize: '14px',
    fontFamily: '"Roboto", sans-serif',
    display: 'flex',
    alignItems: 'center',
  },
};

const StyledErrorIcon = styled(ErrorIcon)`
  margin-right: 0.25rem;
`;

class Profile extends Component {
  constructor(props) {
    super(props);
    this.notifyComp = React.createRef();
    this.addressLocator = React.createRef();
    this.data = this.props.profileData;
    this.loadFrom = true;
    this.selectNodes = {};
    const errorDefinedValues = defineValues(this.props.defineProfile, true);
    errorDefinedValues.state = '';
    this.state = {
      errorsTexts: '',
      mobile: isMobile(),
      profile: this.props.profileData,
      // showProfileCompleteMsg: isProfileCompleted(this.data, this.props.defineProfile, this.props.strings),
      errors: validateProfileRequired(this.props.site.name)
        ? validateProfile(
            this.props.profileData,
            this.props.defineProfile,
            this.props.strings,
            this.props.site.config
          )
        : errorDefinedValues,
    };
  }

  componentDidMount() {
    // Modify the material UI node to reach accessibility purpose
    // Object.keys(this.selectNodes).forEach(key => {
    //   appendButtonText(this.selectNodes[key].querySelector('button'), 'dropdown');
    //   appendAccessibility(this.selectNodes[key].querySelector('button'), 'aria-label', 'dropdown');
    // });
    window.scrollTo(0, 0);
    this.checkLayout();
    window.addEventListener('resize', this.checkLayout);

    document.querySelectorAll('hr').forEach((item) => {
      item.style.borderBottom = '1px solid #949494';
    });

    const titleField = document.querySelector('#title');
    titleField && titleField.setAttribute('autocomplete', 'honorific-prefix');

    const firstnameField = document.querySelector('#name');
    firstnameField && firstnameField.setAttribute('autocomplete', 'given-name');

    const lastnameField = document.querySelector('#surname');
    lastnameField && lastnameField.setAttribute('autocomplete', 'family-name');

    const streetField = document.querySelector('#address1');
    streetField && streetField.setAttribute('autocomplete', 'address-line1');

    const cityField = document.querySelector('#city');
    cityField && cityField.setAttribute('autocomplete', 'address-level2');

    const stateField = document.querySelector('#state');
    stateField && stateField.setAttribute('autocomplete', 'address-level1');

    const zipcodeField = document.querySelector('#zipcode');
    zipcodeField && zipcodeField.setAttribute('autocomplete', 'postal-code');

    const homephoneField = document.querySelector('#homephone');
    homephoneField && homephoneField.setAttribute('autocomplete', 'home tel-national');

    const workphoneField = document.querySelector('#workphone');
    workphoneField && workphoneField.setAttribute('autocomplete', 'work tel-national');

    const cellphoneField = document.querySelector('#cellphone');
    cellphoneField && cellphoneField.setAttribute('autocomplete', 'mobile tel-national');
  }

  componentDidUpdate(nextProps, nextState) {
    if (this.reqToAddLocation && this.props.locationAddress.length > 0) {
      this.reqToAddLocation = false;
    }
  }

  componentWillUnmount() {
    this.loadFrom = true;
    this.updateAppState();
    window.removeEventListener('resize', this.checkLayout);
  }

  onNotifyClose = () => {
    this.focusInvalidField();
  };

  focusInvalidField = () => {
    const stateErrors = this.state.errors;
    const errors = Object.keys(stateErrors).filter((key) => stateErrors[key]);
    if (errors.length === 0) {
      return;
    }
    const errorFields = this.props.defineProfile.filter(({ name }) => errors.indexOf(name) >= 0);
    if (errorFields.length === 0) {
      return;
    }
    const firstErrorField = errorFields[0];
    const firstErrorFieldEl = document.querySelector(`#${firstErrorField.name}`);
    firstErrorFieldEl && firstErrorFieldEl.focus();
  };

  // selectFieldKeyDownHandler = (ev, field, option) => {
  //   if (ev.key === 'Enter') {
  //     this.handleSelectChange(field, option.value);
  //     ev.target.click();
  //   }
  // };

  generateForm = (profile, strings) => {
    const fields = [];
    const accessToken = getDetails(this.props.site.name, 'accessToken');
    const idToken = getDetails(this.props.site.name, 'idToken');

    if (this.state.errorsTexts) {
      fields.push(
        <p key="error message texts" style={styles.errorTexts}>
          {this.state.errorsTexts}
        </p>
      );
    }

    this.props.defineProfile.forEach((field) => {
      const errorText = this.state.errors[field.name];
      if (field.element === 'text') {
        if (field.name === 'address1') {
          fields.push(
            <div key={field.name}>
              <TextField
                aria-invalid={errorText && true}
                aria-required={field.required && true}
                fullWidth
                id={field.name}
                autoCapitalize="words"
                type="text"
                defaultValue={profile[field.name]}
                floatingLabelText={
                  <span>
                    {field.label}{' '}
                    <span aria-hidden className="required">
                      {field.labelExtension}
                    </span>
                  </span>
                }
                floatingLabelFixed
                hintText={field.hint}
                maxLength={field.maxlength}
                onChange={(event) => {
                  this.addingNewAddress(event.target.value);
                }}
                onBlur={(event) => {
                  this.validateInput(field, event.target.value);
                }}
              />
              {errorText && (
                <div id={`${field.name}_error`} style={styles.errorTexts}>
                  <StyledErrorIcon />
                  <span>{`${field.label} ${errorText}`}</span>
                </div>
              )}
              <AddressLocator
                ref={this.addressLocator}
                site={this.props.site}
                siteName={this.props.site.name}
                selectAddress={this.selectAddress}
              />
            </div>
          );
        } else {
          fields.push(
            <div key={field.name}>
              <TextField
                aria-invalid={errorText && true}
                aria-required={field.required && true}
                aria-describedby={`${field.name}_error`}
                fullWidth
                autoCapitalize={field.type === 'email' || field.type === 'tel' ? 'none' : 'words'}
                // type={field.type === 'tel' ? 'number' : field.type}
                type={field.type}
                id={field.name}
                key={field.name}
                defaultValue={profile[field.name]}
                floatingLabelText={
                  <span>
                    {field.label}{' '}
                    <span aria-hidden className="required">
                      {field.labelExtension}
                    </span>
                  </span>
                }
                floatingLabelFixed
                hintText={field.hint}
                maxLength={field.maxlength}
                disabled={
                  !!(field.type === 'email' && accessToken && idToken && profile[field.name])
                }
                onChange={(event) => {
                  this.handleTextChange(field.name, event.target.value);
                }}
                onBlur={(event) => {
                  this.validateInput(field, event.target.value);
                }}
              />
              {errorText && (
                <div id={`${field.name}_error`} style={styles.errorTexts}>
                  <StyledErrorIcon />
                  <span>{`${field.label} ${errorText}`}</span>
                </div>
              )}
            </div>
          );
        }
      }
      if (field.element === 'select') {
        // Add a 'please select' option if one does not exist
        if (!field.options.some(({ value }) => value === '')) {
          field.options.unshift({ label: '', value: '' });
        }
        if (field.multiSelect) {
          this.menuItems = () =>
            field.options.map((option) => (
              <option key={option.value} onClick={() => log('fire onclick')} value={option.value}>
                {option.label}
              </option>
            ));
        } else {
          this.menuItems = () =>
            field.options.map((option) => {
              const { value, label } = option;
              if (value === '') {
                const selectString = strings['forms/complaint/hint/select'];
                return (
                  <option key={value} value={value} disabled>
                    {selectString}
                  </option>
                );
              }
              return (
                <option key={value} value={value}>
                  {label}
                </option>
              );
            });
        }
        fields.push(
          <div
            ref={(node) => {
              this.selectNodes[field.name] = node;
            }}
            key={field.name}>
            {/* eslint-disable-next-line */}
            <FormLabel htmlFor={field.name}>
              {field.label}{' '}
              <span aria-hidden className="required">
                {field.labelExtension}
              </span>
            </FormLabel>
            <SelectField
              aria-invalid={this.state.errors[field.name] && true}
              aria-required={field.required && true}
              id={field.name}
              key={field.name}
              value={profile[field.name]}
              multiple={field.multiSelect}
              onChange={(event) => {
                this.handleSelectChange(field, event.target.value);
                // log('this.state.complaint[field.name]', this.state.profile[field.name]);
              }}>
              {this.menuItems(profile[field.name])}
            </SelectField>
            {errorText && (
              <div
                id={`${field.name}_error`}
                style={{
                  marginTop: '8px',
                  ...styles.errorTexts,
                }}>
                <StyledErrorIcon />
                <span>{`${field.label} ${errorText}`}</span>
              </div>
            )}
          </div>
        );
      }
    });
    return fields;
  };

  handleTextChange = (fieldName, value) => {
    this.data[fieldName] = value;
  };

  typingTimer = null;

  addingNewAddress = (value) => {
    let address = value;
    this.addressLocator.current.typing = true;
    if (this.typingTimer) clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.addressLocator.current.typing = false;
      ['city', 'county', 'state'].forEach((key) => {
        if (typeof this.data[key] !== 'undefined' && this.data[key]) {
          address += `, ${this.data[key]}`;
        }
      });
      this.addressLocator.current.searchAddress(address);
    }, 1200);
  };

  textChanged = (field, value) => {
    const stateObject = {};
    stateObject[field.name] = value;
    this.setState(stateObject);
  };

  handleSelectChange = (field, value) => {
    // log('handleSelectChange', field, value);
    const stateObject = {};
    stateObject[field.name] = value;
    // log('stateObject', field, value, stateObject, this.state);
    this.setState({
      profile: Object.assign({}, this.state.profile, {
        [field.name]: value,
      }),
    });
    this.validateInput(field, value);
  };

  validateInput = (field, value) => {
    const {
      complaintsform: { require_valid_address: requireValidAddress },
    } = this.props.site;
    if (field.name === 'address1' && requireValidAddress === '1') return;
    this.data[field.name] = value;
    // const errorsObject = this.state.errors;
    // const error = isValid(field, value, this.props.strings, true);
    // if (errorsObject[field.name] !== error) {
    //   errorsObject[field.name] = error;
    //   this.setState(errorsObject);
    // }
    // if (
    //   !this.state.showProfileCompleteMsg &&
    //   isProfileCompleted(this.data, this.props.defineProfile, this.props.strings)
    // ) {
    //   this.notifyComp.current.openSnackBar('Profile completed!');
    //   this.updateComponent = true;
    //   this.setState({ showProfileCompleteMsg: true });
    // }
  };

  checkProfile = () => {
    const { name: siteName, productId } = this.props.site;
    const storedProfile = readProfile(siteName, productId);
    const compare = [];
    if (storedProfile) {
      [storedProfile, this.data].forEach((data) => {
        compare.push(
          possibleAddressFields
            .filter((field) => typeof data[field] !== 'undefined' && data[field])
            .map((field) => data[field])
            .join(', ')
        );
      });
      if (compare[0] && compare[0] !== compare[1]) {
        this.notifyComp.current.openAddressChangeConfirmation();
        return false;
      }
    }
    return true;
  };

  validateProfileData = () => {
    const errorsObject = this.state.errors;
    const { strings } = this.props;
    const { config } = this.props.site;

    this.props.defineProfile.forEach((field) => {
      if (typeof this.data[field.name] !== 'undefined') {
        const error = isValid(field, this.data[field.name], strings, true, config);
        if (errorsObject[field.name] !== error) {
          errorsObject[field.name] = error;
        }
      }
    });
    this.setState(errorsObject);
    if (
      Object.keys(errorsObject)
        .map((key) => errorsObject[key])
        .join('')
    ) {
      const errorMessage = `${strings['labels/pleaseCorrectMarkedFields']}: ${Object.keys(
        errorsObject
      )
        .filter((key) => errorsObject[key])
        .map((key) => {
          let temp = key;
          if (temp.search('address') !== -1) {
            temp = 'address';
          }
          // TODO: I18N - should be internationalized
          return capitalize(temp);
        })
        .join(', ')}`;

      this.setState({ errorsTexts: errorMessage });
      this.focusInvalidField();
      // this.notifyComp.current.notifyProfileInvalid(errorMessage);
    } else if (this.checkProfile()) {
      this.storeProfile();
    }
  };

  storeProfile = () => {
    const { name: siteName, productId } = this.props.site;
    writeProfile(this.data, siteName, productId);
    this.notifyComp.current.notifyProfileCompleted();
  };

  addLocation = () => {
    this.reqToAddLocation = true;
    if (this.props.geolocation[0] && this.props.geolocation[1]) {
      if (!this.props.geoLocationBlocked && this.props.locationAddress.length > 0) {
        this.reqToAddLocation = false;
      }
    } else {
      this.props.checkLocationAddress();
    }
  };

  selectAddress = (address) => {
    const data = Object.assign({}, this.data);
    const apartmentNo =
      typeof address.street_number !== 'undefined' && address.street_number
        ? `${address.street_number} `
        : '';
    let [address1] = address.formatted_address.split(',') || '';
    if (typeof address.street_name !== 'undefined' && address.street_name) {
      address1 = `${apartmentNo}${address.street_name}`;
    }
    const newCity = AddressLookupUtils.getCityFromFormattedAddress(address);

    Object.keys(address).forEach((entry) => {
      switch (entry) {
        case 'street_name':
          data.address1 = address1;
          break;
        case 'city':
          data.city = newCity;
          break;
        case 'county':
          data.county = address.county || '';
          break;
        case 'state': {
          let { aliases } = this.props.site.config;
          let { state } = address;
          if (aliases) {
            aliases = JSON.parse(aliases);
            state = aliases[state] || state;
          }
          // log('new state', state);
          data.state = state || '';
          break;
        }
        case 'postal_code':
          if (typeof data.zipcode !== 'undefined') data.zipcode = address.postal_code || '';
          if (typeof data.postal_code !== 'undefined') data.postal_code = address.postal_code || '';
          break;
        case 'zipcode':
          if (typeof data.zipcode !== 'undefined') data.zipcode = address.zipcode || '';
          if (typeof data.postal_code !== 'undefined') data.postal_code = address.zipcode || '';
          break;
        case 'country':
          data.country = address.country || '';
          break;
        case 'elevation':
          data.elevation = address.elevation || '';
          break;
        case 'lat':
          data.lat = address.lat || '';
          break;
        case 'lon':
          data.lon = address.lon || '';
          break;
        default:
      }
    });
    this.data = data;
    this.setState({ profile: data });
    this.updateAppState(true);
  };

  updateAppState = (updateForm = false) => {
    setTimeout(() => {
      this.props.saveState('profile', this.data);
      if (this.addressLocator && updateForm) this.addressLocator.current.addressSelected();
    }, 1);
  };

  // check object equality
  isEquivalent = (a, b) => {
    if (!a || !b) return true;
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);
    if (aProps.length !== bProps.length) return false;
    Object.keys(aProps).forEach((i) => {
      const propName = aProps[i];
      if (a[propName] !== b[propName]) {
        return false;
      }
    });
    return true;
  };

  checkLayout = () => {
    if (this.state.mobile !== isMobile()) {
      this.setState({ mobile: isMobile() });
    }
  };

  render() {
    const { state } = this.props.location;
    const { strings } = this.props;
    const pageTitle = strings['labels/profile'];

    // is language selection required?
    if (localeSelectionRequired(this.props.site))
      return <Redirect to={{ pathname: `/${this.props.site.name}${path.language}` }} />;
    if (isAuthenticated(this.props.site)) {
      if (
        !this.props.newProfileLoaded &&
        !isLoginSkipped(this.props.site) &&
        needsAuthentication(this.props.site)
      ) {
        return <Redirect to={{ pathname: `/${this.props.site.name}${path.getProfileInfo}` }} />;
      }
      const check = this.props.isAppJustLoaded();
      if (!check.isPrivacyAccepted)
        return <Redirect to={{ pathname: `/${this.props.site.name}${path.privacy}/accept` }} />;
      if (
        check.isProfileValid &&
        this.props.site.complaintsform.openComplaintFormOnLaunch === '1'
      ) {
        return <Redirect to={{ pathname: `/${this.props.site.name}${path.compose}` }} />;
      }

      return (
        <Fragment>
          <AppNavigation site={this.props.site} strings={this.props.strings} title={pageTitle} />

          <div className={this.state.mobile ? 'none-flex-container' : 'flex-container'}>
            {!this.state.mobile && (
              <Header site={this.props.site} strings={this.props.strings} page={path.profile} />
            )}
            <Form>{this.generateForm(this.state.profile, this.props.strings)}</Form>
            <div className="button-wrapper">
              <RaisedButton
                fullWidth
                primary
                label={strings['labels/saveProfile']}
                style={styles.goToCompose}
                onClick={() => {
                  this.validateProfileData();
                }}
              />
            </div>
            <Notify
              ref={this.notifyComp}
              siteName={this.props.site.name}
              strings={this.props.strings}
              profile={this.state.profile}
              profileIncomplete={
                !isProfileCompleted(
                  this.data,
                  this.props.defineProfile,
                  this.props.site.config,
                  this.props.strings
                )
              }
              storeProfile={this.storeProfile}
              goCheckProfile={!!(state !== undefined && state.goCheckProfile)}
              onClose={this.onNotifyClose}
            />
          </div>
        </Fragment>
      );
    }
    return (
      <Redirect
        to={{
          pathname: `/${this.props.site.name}${path.login}`,
        }}
      />
    );
  }
}

Profile.defaultProps = {
  location: PropTypes.shape({
    state: undefined,
  }),
};

Profile.propTypes = {
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  defineProfile: PropTypes.array.isRequired,
  profileData: PropTypes.object.isRequired,
  checkLocationAddress: PropTypes.func.isRequired,
  geolocation: PropTypes.array.isRequired,
  geoLocationBlocked: PropTypes.bool.isRequired,
  locationAddress: PropTypes.array.isRequired,
  saveState: PropTypes.func.isRequired,
  newProfileLoaded: PropTypes.string.isRequired,
  isAppJustLoaded: PropTypes.func.isRequired,
  location: PropTypes.shape({
    state: PropTypes.object,
  }),
};

export default Profile;
