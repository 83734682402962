import CryptoES from 'crypto-es';

export const writeData = (key, dataToSave, siteName, staging) => {
  try {
    const encrypted = CryptoES.AES.encrypt(JSON.stringify(dataToSave), siteName);
    localStorage.setItem(`${siteName}_${key}${staging ? '_staging' : ''}`, encrypted);
  } catch (err) {
    console.error(`Failed to store ${key} into local storage.`);
  }
};

export const readData = (key, siteName, staging) => {
  const cipherText = localStorage.getItem(`${siteName}_${key}${staging ? '_staging' : ''}`);
  if (cipherText) {
    try {
      const bytes = CryptoES.AES.decrypt(cipherText.toString(), siteName);
      const plaintext = bytes.toString(CryptoES.enc.Utf8);
      const dataToLoad = JSON.parse(plaintext);
      return dataToLoad;
    } catch (err) {
      console.error(`Failed to load ${key} from local storage.`);
      return false;
    }
  }
  return false;
};

export const removeData = (key, siteName) => {
  localStorage.removeItem(`${siteName}_${key}`);
};
