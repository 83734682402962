import CryptoES from 'crypto-es';
import moment from 'moment-timezone/builds/moment-timezone-with-data';

// Application main routes
export const path = {
  home: '',
  login: '/login',
  goToLogin: '/gotologin',
  skipLogin: '/skip',
  getProfileInfo: '/getprofileinfo',
  language: '/language',
  logout: '/logout',
  profile: '/profile',
  compose: '/compose',
  submitNow: '/submitnow',
  history: '/history',
  settings: '/settings',
  privacy: '/privacystatement',
  reset: '/reset',
  correspondence: '/correspondence',
};

export const addressFields = [
  'apartment_number',
  'address1',
  'address2',
  'city',
  'county',
  'state',
  'country',
  'zipcode',
];

export const appName = 'Viewpoint';
export const productName = 'ViewpointCollector';
export const appValues = (siteName) => ({
  locale: `${siteName}_locale`,
  acceptPrivacy: `${siteName}_accept_privacy`,
  build: `build`,
});
// staging switch
// export const staging = () => false;
export const staging = () =>
  !!(window.location.hostname === 'localhost' || window.location.hostname.search('staging') !== -1);

// eslint-disable-next-line
const [slash, sName] = window.location.pathname.split('/');
export const s3Url = 'https://s3-us-west-2.amazonaws.com/viewpoint-mobile-pwa/';

export const focusEndpoint = process.env.REACT_APP_FOCUS_API_URL;

export const getViewpointCollectorUrl = (site = null) => {
  const protocol = process.env.REACT_APP_ENVIRONMENT === 'local' ? 'http' : 'https';
  if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
    return `${process.env.REACT_APP_COLLECTOR_API_URL}/`;
  }
  return site
    ? `${protocol}://${site.frontEndServer}/`
    : `${process.env.REACT_APP_COLLECTOR_API_URL}/`;
};

export const getViewpointApiUrl = (site = null) =>
  site ? `https://${site.serverName}/` : `${process.env.REACT_APP_VIEWPOINT_API_URL}/`;

export const monthsShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
export const possibleAddressFields = [
  'apartment_number',
  'address1',
  'address2',
  'city',
  'county',
  'state',
  'zipcode',
  'postal_code',
  'postcode',
  'country',
];

export const mobileMinWidth = 1024;

export const isMobile = () => {
  if (window.innerWidth < mobileMinWidth) return true;
  return false;
};

export const defineLocale = (site) => {
  const storedLocale = localStorage.getItem(appValues(site.name).locale);
  if (storedLocale && site.config.locales.indexOf(storedLocale) !== -1) return storedLocale; // valid local is already set
  return site.defaultLocale;
};

export const localeSelectionRequired = (site) => {
  // TODO: Temporary disable multi language support as there is no support
  const storedLocale = localStorage.getItem(appValues(site.name).locale);
  return site.multipleLang && site.config.locales.indexOf(storedLocale) === -1;
};

export const localeSelected = (siteName, selectedLocale) => {
  localStorage.setItem(appValues(siteName).locale, selectedLocale);
};

export const acceptPrivacy = (siteName, terms, passPhrase = '') => {
  try {
    const encrypted = CryptoES.AES.encrypt(terms, String(passPhrase));
    localStorage.setItem(appValues(siteName).acceptPrivacy, encrypted);
  } catch (err) {
    console.error('Failed to store privacy terms into local storage.');
  }
};

export const acceptedPrivacyTerms = (siteName, passPhrase = '') => {
  const ciphertext = localStorage.getItem(appValues(siteName).acceptPrivacy);
  if (ciphertext) {
    try {
      const bytes = CryptoES.AES.decrypt(ciphertext.toString(), String(passPhrase));
      const plaintext = bytes.toString(CryptoES.enc.Utf8);
      return plaintext;
    } catch (err) {
      console.error('Failed to load privacy terms from local storage.');
      return false;
    }
  }
  return false;
};

export const defineFields = (config, fieldDefs, strings, defaultValues, scope) => {
  // console.log('defineFields: config', config, 'fieldDefs', fieldDefs, 'strings', strings, 'defaultValues', defaultValues, 'scope', scope);
  // const addressHint = strings['forms/complaint/hint/address'];
  // const selectHint = strings['forms/complaint/hint/select'];
  // const selectMultiHint = strings['forms/complaint/hint/selectMulti'];
  const optionalHint = strings['forms/complaint/hint/optional'];
  const stateObject = [];
  Object.keys(fieldDefs).forEach((key) => {
    if (fieldDefs[key] && fieldDefs[key].scope === scope) {
      const item = fieldDefs[key];
      const labelExtension = item.required ? '*' : '';
      const multiSelect = !!(config.multiSelects && config.multiSelects.indexOf(key) !== -1);
      const contactField = !!(config.contactFields && config.contactFields.indexOf(key) !== -1);
      const name = key;
      const field = {
        name,
        label: `${item.label}` || 'Unknown',
        hint: item.required ? '' : optionalHint,
        maxlength: item.maxlength || null,
        required: item.required || false,
        labelExtension,
        contactField,
        type: item.type,
        multiLine: false,
      };
      switch (item.type) {
        case 'list':
          if (key === 'locations') {
            const locationsLength = Object.keys(config[key]).length;
            if (locationsLength <= 1) {
              break;
            }
          }
          field.element = 'select';
          field.options = getOptions(key, item.required, config, strings);
          field.value = multiSelect ? [] : '';
          field.value =
            typeof defaultValues[key] !== 'undefined' ? defaultValues[key] : field.value;
          field.multiSelect = multiSelect;
          field.hint = '';
          break;
        case 'text':
          field.element = 'text';
          field.value = typeof defaultValues[key] !== 'undefined' ? defaultValues[key] : '';
          if (field.name === 'address1') field.hint = '';
          if (name === 'name' || name === 'surname') field.validate = 'name';
          break;
        case 'tel':
          field.element = 'text';
          field.value = '';
          field.validate = 'tel';
          break;
        case 'email':
          field.element = 'text';
          field.value = '';
          field.validate = 'email';
          break;
        case 'textarea':
          field.element = 'text';
          field.multiLine = true;
          field.value = '';
          break;
        case 'datetime':
          if (name === 'date') field.element = 'date';
          if (name === 'time') field.element = 'time';
          field.value = null;
          field.validate = 'dateTime';
          field.hint = '';
          break;
        case 'boolean':
          field.element = 'select';
          field.options = [
            {
              value: '',
            },
            {
              label: strings['labels/no'],
              value: 'N',
            },
            {
              label: strings['labels/yes'],
              value: 'Y',
            },
          ];
          field.value =
            typeof defaultValues[key] !== 'undefined' &&
            (defaultValues[key] === 'N' || defaultValues[key] === 'Y')
              ? defaultValues[key]
              : '';
          field.multiSelect = false;
          field.hint = '';
          break;
        default:
          field.value = null;
          field.validate = null;
      }
      stateObject.push(field);
    }
  });
  return stateObject;
};

export const defineValues = (fields, errorfield = false) => {
  const stateObject = {};
  fields.forEach((field) => {
    if (!errorfield) {
      if (
        field.element === 'select' &&
        !field.multiSelect &&
        field.options &&
        field.options.length === 1 &&
        field.required
      ) {
        stateObject[field.name] = field.options[0].value;
      } else if (field.value !== null) {
        stateObject[field.name] = field.value;
      }
    } else if (field.value !== null) {
      stateObject[field.name] = field.value;
    }
  });
  return stateObject;
};

const getOptions = (key, required, config, strings) => {
  let options = [];
  const dropDownsKey = `${key}sDropdown`;
  const dropDownKey = `${key}Dropdown`;
  if (typeof config[dropDownsKey] === 'string' || typeof config[dropDownKey] === 'string') {
    let found = null;
    if (typeof config[dropDownsKey] === 'string' && config[dropDownsKey] !== '') {
      found = dropDownsKey;
    } else if (typeof config[dropDownKey] === 'string' && config[dropDownKey] !== '') {
      found = dropDownKey;
    }
    if (!key) return options;
    try {
      const availableOptions = JSON.parse(config[found]);
      let keys = Object.keys(availableOptions);
      if (required) {
        keys = keys.filter(Boolean);
      }
      options = keys.map((eachKey) => ({
        label: availableOptions[eachKey],
        value: eachKey,
      }));
    } catch (err) {
      console.log(err);
    }
  } else if (key === 'locations') {
    options = Object.keys(config.locations).map((locationKey) => ({
      label: config.locations[locationKey],
      value: locationKey,
    }));
  } else {
    let found = null;
    if (typeof strings[`lists/${key}`] !== 'undefined') {
      found = `lists/${key}`;
    } else if (typeof strings[`complaintsform/lists/${key}`] !== 'undefined') {
      found = `complaintsform/lists/${key}`;
    } else if (typeof strings[`complaintsform/lists/${key}s`] !== 'undefined') {
      found = `complaintsform/lists/${key}s`;
    } else if (key === 'aircrafttype') {
      // Hard-coded because the key and string param is not the same
      found = 'complaintsform/lists/acTypes';
    } else if (key === 'adflag') {
      found = 'complaintsform/lists/acModes';
    }

    if (!found) {
      return options;
    }
    const labels = strings[found].split(',');
    const values =
      typeof strings[`${found}_internal`] !== 'undefined' && strings[`${found}_internal`]
        ? strings[`${found}_internal`].split(',')
        : labels;

    if (values.length !== labels.length) {
      console.error(
        `Translations are misconfigured in Focus: the number of values needs to match the number of labels, but there are ${values.length} values and ${labels.length} labels.`
      );
    }
    const DEBUG_TRANSLATIONS = false;
    if (DEBUG_TRANSLATIONS) {
      const translationMapping = [];
      for (let i = 0, ii = Math.max(values.length, labels.length); i < ii; i += 1) {
        translationMapping.push({
          value: values[i] ? values[i] : undefined,
          translation: labels[i] ? labels[i] : undefined,
        });
      }
      console.table(translationMapping);
    }

    for (let i = 0, ii = Math.min(values.length, labels.length); i < ii; i += 1) {
      options.push({
        label: labels[i].replace(/[\r\n\t]/g, '').trim(),
        value: values[i].replace(/[\r\n\t]/g, '').trim(),
      });
    }

    //  Prepend an empty option to the list
    options.unshift({
      label: '',
      value: '',
    });
  }
  return options;
};

export const shouldClearCache = (buildNumber) => {
  let storedBuildNumber = localStorage.getItem(appValues('').build);
  if (!storedBuildNumber) {
    storedBuildNumber = buildNumber;
    updateBuild(buildNumber);
  }
  if (storedBuildNumber === buildNumber) return false;
  updateBuild(buildNumber);
  return true;
};

export const updateBuild = (buildNumber) => {
  localStorage.setItem(appValues('').build, buildNumber);
};

export const mapping = {
  name: ['name', 'first_name'],
  surname: ['surname', 'last_name'],
  address1: ['address1', 'address_1'],
  address2: ['address2', 'address_2'],
  homephone: ['homephone', 'home_phone'],
  cellphone: ['cellphone', 'mobile_phone'],
  email: ['email', 'email_address'],
  zipcode: ['zipcode', 'postcode'],
};

const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes} ${ampm}`;
};

export const displayDateTime = (date) =>
  `${monthsShort[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()} ${formatAMPM(date)}`;

export const formatSubmissionDateTime = (string = null) => {
  if (typeof string === 'string') {
    const [date, time] = string.split(' ');
    const [, month, day] = date.split('-');
    let [hours, minutes] = time.split(':');
    hours = Number(hours);
    minutes = Number(minutes);
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${monthsShort[Number(month) - 1]} ${Number(day)} ${hours}:${minutes} ${ampm}`;
  }
};

export const howManyDays = (firstDate, secondDate) =>
  Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / (24 * 60 * 60 * 1000)));

export const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const airportCode = (string) => string.substring(0, 3).toUpperCase();

export const getLocalTime = (timezone) => {
  const currentMomentAirportTime = moment(new Date().getTime()).tz(timezone);
  const airportTime = new Date(
    Number(currentMomentAirportTime.format('YYYY')),
    Number(currentMomentAirportTime.format('MM')) - 1,
    Number(currentMomentAirportTime.format('DD')),
    Number(currentMomentAirportTime.format('HH')),
    Number(currentMomentAirportTime.format('mm')),
    Number(currentMomentAirportTime.format('ss'))
  );
  return airportTime;
};

export const appendButtonText = (node, text) => {
  const element = document.createElement('div');
  element.style.display = 'none';
  element.innerHTML = text;
  node.appendChild(element);
};

// rongyadong, try to fix the accessibility issue
export const appendAccessibility = (node, attributeName, attributeValue) => {
  node.setAttribute(attributeName, attributeValue);
};

let xlog = function () {};
const DEBUG = true;
if (DEBUG) {
  xlog = window.console.log.bind(null);
} else {
  console.log('\n\n\t\t Logging is disabled. Enable it in utils/utilities.js.\n\n');
}
export const log = xlog;

export const getDaysBetweenDates = function (startMoment, endMoment) {
  const now = startMoment.clone();
  const dates = [];
  while (now.isSameOrBefore(endMoment)) {
    dates.push(now.format('YYYY-MM-DD'));
    now.add(1, 'days');
  }
  return dates;
};

export const getSpecificDaysBetweenDates = function (startMoment, endMoment, isoDay) {
  const specificDays = [];
  const current = startMoment.clone();
  specificDays.push(current.day(isoDay).format('YYYY-MM-DD'));
  while (current.day(7 + isoDay).isSameOrBefore(endMoment)) {
    specificDays.push(current.format('YYYY-MM-DD'));
  }
  return specificDays;
};

export const getWeeksBetweenDates = function (startMoment, endMoment) {
  const now = startMoment.clone();
  const weeks = [];
  while (now.isSameOrBefore(endMoment)) {
    weeks.push(now.format('YYYY-WW'));
    now.add(7, 'days');
  }
  // weeks.push(now.format('YYYY-WW'));
  // weeks.splice(0, 1);
  return weeks;
};

export const getMinMaxDates = function (arrDateStrings) {
  const arrMoments = arrDateStrings.map((dateString) => moment(dateString));
  const minDate = moment.min(arrMoments);
  const maxDate = moment.max(arrMoments);
  return { minDate, maxDate };
};

const palettes = [
  [
    '#3361A5',
    '#9539FB',
    '#67B47A',
    '#4AF119',
    '#87B20C',
    '#79F680',
    '#4819FE',
    '#50BA3F',
    '#70C4FA',
    '#1BCDD5',
    '#A3A0EF',
    '#80BDBC',
    '#B3EFFF',
    '#83FB41',
    '#735D76',
    '#19A375',
    '#B9817C',
    '#896EC5',
    '#BAF0B0',
    '#3982E4',
  ],
];
export const defaultGraphColors = palettes[0];

export const generateColorPalette = function (numColors) {
  function getIntegerInRange(min, max) {
    return min + Math.floor(Math.random() * (max - min));
  }
  function decToHex(int) {
    return int.toString(16).toUpperCase();
  }
  function diff(dec1, dec2) {
    return Math.abs(dec1 - dec2);
  }

  function RRGGBBToDecimal(rrggbb) {
    const hexR = rrggbb.slice(0, 2);
    const hexG = rrggbb.slice(2, 4);
    const hexB = rrggbb.slice(4, 6);
    const decR = parseInt(hexR, 16);
    const decG = parseInt(hexG, 16);
    const decB = parseInt(hexB, 16);
    return [decR, decG, decB];
  }

  function decimalRgbToHexRRGGBBString(r, g, b) {
    const hr = decToHex(r);
    const hg = decToHex(g);
    const hb = decToHex(b);
    const rrggbb = `${hr}0${hg}0${hb}0`;
    return rrggbb;
  }

  function isCloseToExistingColor(colorTable, hexColor) {
    const existingColors = Object.keys(colorTable);
    const THRESHOLD = 64;
    for (const color of existingColors) {
      const [r1, g1, b1] = RRGGBBToDecimal(color);
      const [r2, g2, b2] = RRGGBBToDecimal(hexColor);
      const diffR = diff(r1, r2);
      const diffG = diff(g1, g2);
      const diffB = diff(b1, b2);
      if (diffR < THRESHOLD && diffG < THRESHOLD && diffB < THRESHOLD) {
        // console.log('%c' + `${diffR}, ${diffG}, ${diffB} - ` + hexColor + ' is too close to existing color ' + color, 'color: #FFF;background:#800');
        return true;
      }
    }
    return false;
  }

  function generateColors(numOfColors) {
    const colors = [];
    const colorTable = {};
    let i;
    let ii;
    // TODO: use a better method to generate palettes
    for (i = 1, ii = 1000; i <= ii; i += 1) {
      const r = Math.min(15, getIntegerInRange(0, 16));
      const g = Math.min(15, getIntegerInRange(0, 16));
      const b = Math.min(15, getIntegerInRange(0, 16));
      if (!(r + g + b > 44 || r + g + b < 12) && !(r > (g + b) * 0.8)) {
        const hexColor = decimalRgbToHexRRGGBBString(r, g, b);
        if (!isCloseToExistingColor(colorTable, hexColor)) {
          colorTable[hexColor] = true;
          colors.push(`#${hexColor}`);
        }
        if (colors.length >= numOfColors) break;
      }
    }
    // colors.sort();
    // console.log(colors.length + " colors, " + i + " iterations");
    // console.log(JSON.stringify(colors));
    return colors;
  }

  return generateColors(numColors);
};

export const CSVToArray = (commaSeparatedValues) => {
  const arr = commaSeparatedValues.split(',');
  if (!arr.length) {
    return false;
  }
  const result = [];
  for (const item of arr) {
    result.push(item.trim());
  }
  return result;
};

export const getTranslatedEventTypes = (config, strings) => {
  const eventTypesList = config.eventTypesDropdown;
  if (eventTypesList) {
    try {
      const eventTypesObject = JSON.parse(eventTypesList);
      const eventTypeLabels = strings[`complaintsform/lists/event_types`];
      const labels = eventTypeLabels.split(',');
      const filteredKeys = Object.keys(eventTypesObject).filter((key) => !!key && key !== '');
      return filteredKeys.map((value, index) => {
        if (index <= labels.length - 1) {
          return { label: labels[index], value };
        }
        console.warn(
          'there is a mismatch in event type configuration and translation - check your event type strings to make sure your event type translations match'
        );
        // Returns a non translated event_type
        return { label: value, value };
      });
    } catch {
      console.error('error in converting event types');
      return [];
    }
  }
};

export const setTimeWithTransformation = (complaintSummaryData, timezoneTransform) => {
  switch (timezoneTransform) {
    case 'Cast to UTC (Deduct Airport TZ)':
      return complaintSummaryData.when_received;
    case 'Do Nothing':
    default:
      return complaintSummaryData.when_received_uncorrected;
  }
};

export const compareNormalizedStrings = (stringOne, stringTwo) => {
  const normalizedOne = stringOne.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const normalizedTwo = stringTwo.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return normalizedOne === normalizedTwo;
};
