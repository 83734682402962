import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import { Toolbar, ToolbarGroup } from 'material-ui';
import IconButton from 'material-ui/IconButton';
import NavigationChevronLeft from 'material-ui/svg-icons/navigation/chevron-left';
import NavigationArrowDownward from 'material-ui/svg-icons/navigation/arrow-downward';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';
import AppBar from 'material-ui/AppBar';
import { Redirect, Link } from 'react-router-dom';
import {
  appName,
  path,
  acceptPrivacy,
  localeSelectionRequired,
  appendAccessibility,
} from '../utils/utilities';
import { isAuthenticated } from '../utils/auth0';

const styles = {
  appBar: { position: 'fixed', top: 0, fontSize: '18px' },
  container: { padding: '2px 22px' },
  buttonWrapper: { paddingTop: 20 },
  acceptButton: { margin: '2px 12px 12px 0px' },
  rejectBtnContainer: { width: '100%', textAlign: 'center', paddingBottom: 30 },
  spacer: { width: '100%', paddingBottom: 55 },
  contentStyle: { maxWidth: 320 },
  dialog: { padding: '5px 24px 20px' },
  dialogTitle: { fontSize: 15, fontWeight: 500, padding: '15px 24px 0px' },
  findLocation: { margin: '10px 8px' },
};

class PrivacyStatement extends Component {
  state = {
    rejectDialog: false,
  };

  componentDidMount() {
    if (this.iconbtn && this.iconbtn.button) {
      const iconbtnNode = this.iconbtn.button.button;
      appendAccessibility(iconbtnNode, 'aria-hidden', 'true');
    }
  }

  privacyStatement = (html) => {
    if (html) {
      return (
        // eslint-disable-next-line
        <div
          id="privacyStatement"
          style={styles.container}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    }
  };

  acceptTerms = () => {
    acceptPrivacy(
      this.props.site.name,
      this.props.strings['complaintsform/blocks/privacyStatement']
    );
  };

  rejectTerms = () => {
    this.setState({ rejectDialog: true });
  };

  handleClose = () => {
    this.setState({ rejectDialog: false });
  };

  render() {
    // eslint-disable-next-line
    const [a, b, c, redirectPath] = window.location.pathname.split('/');
    const {
      name: siteName,
      theme: { appbarColor },
    } = this.props.site;
    // eslint-disable-next-line
    const { strings } = this.props;
    const needToAcceptTerms = strings['alerts/heading/needToAcceptTerms'];
    const privacyStatementHeading = strings['complaintsform/headings/privacyStatement'];
    const privacyStatement = strings['complaintsform/blocks/privacyStatement'];
    // is language selection required
    if (localeSelectionRequired(this.props.site))
      return <Redirect to={{ pathname: `/${siteName}${path.language}` }} />;
    return isAuthenticated(this.props.site) ? (
      <Fragment>
        <AppBar
          title={
            redirectPath === 'accept'
              ? `${appName} ${privacyStatementHeading}`
              : privacyStatementHeading
          }
          style={styles.appBar}
          showMenuIconButton={redirectPath !== 'accept'}
          iconElementLeft={
            <IconButton
              ref={(iconbtn) => {
                this.iconbtn = iconbtn;
              }}>
              <NavigationChevronLeft />
            </IconButton>
          }
          onLeftIconButtonClick={() => {
            window.history.back();
          }}
        />
        <div style={styles.spacer} />
        {redirectPath === 'accept' && (
          <Toolbar>
            <ToolbarGroup firstChild />
            <ToolbarGroup lastChild>
              <FlatButton
                primary
                label={strings['labels/scrollToTheEnd']}
                labelPosition="before"
                style={styles.findLocation}
                icon={<NavigationArrowDownward color={appbarColor} />}
                onClick={() => {
                  // window.scrollTo(0, document.querySelector('#privacyStatement').scrollHeight);

                  document.querySelector('#acceptPrivacyStatement').focus();
                }}
              />
            </ToolbarGroup>
          </Toolbar>
        )}
        {this.privacyStatement(privacyStatement)}
        {redirectPath === 'accept' ? (
          <div style={styles.container}>
            <RaisedButton
              id="acceptPrivacyStatement"
              fullWidth
              primary
              label={strings['labels/acceptTerms']}
              style={styles.acceptButton}
              onClick={this.acceptTerms}
              containerElement={<Link to={`/${this.props.site.name}${path.home}`} />}
            />
            <div style={styles.rejectBtnContainer}>
              <FlatButton
                label={strings['labels/rejectTerms']}
                primary
                onClick={this.rejectTerms}
              />
            </div>
          </div>
        ) : (
          <div style={styles.spacer} />
        )}
        <Dialog
          title={needToAcceptTerms}
          actions={[
            <FlatButton
              label={strings['labels/close']}
              primary
              keyboardFocused
              onClick={this.handleClose}
            />,
          ]}
          modal
          open={this.state.rejectDialog}
          bodyStyle={styles.dialog}
          titleStyle={styles.dialogTitle}
          contentStyle={styles.contentStyle}
        />
      </Fragment>
    ) : (
      <Redirect to={{ pathname: `/${siteName}${path.login}` }} />
    );
  }
}

PrivacyStatement.propTypes = {
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
};

export default PrivacyStatement;
