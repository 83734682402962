import styled from 'styled-components';
import TimePicker from 'material-ui/TimePicker';
import { STYLES } from '../styles';

const StyledTimePicker = styled(TimePicker)`
  hr {
    border-color: ${STYLES.BORDER_COLOR} !important;
  }
`;

export { StyledTimePicker as TimePicker };
