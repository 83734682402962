import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { path } from '../utils/utilities';
import { isAuthenticated } from '../utils/auth0';

const styles = {
  header: { display: 'flex', alignItems: 'center', justifyContent: 'center', height: 55 },
  title: { fontSize: '1em', lineHeight: '0.7em', paddingLeft: 10, color: '#00BCD4', float: 'left' },
  box: { width: 220, paddingTop: 30, margin: '0 auto' },
  spinnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 30,
  },
};

class GetProfileInfo extends Component {
  componentDidMount() {
    this.props.checkLodgerInfo();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.newProfileLoaded) return true;
    return false;
  }

  render() {
    const {
      theme: { appbarColor },
    } = this.props.site;
    if (isAuthenticated(this.props.site)) {
      return !this.props.newProfileLoaded ? (
        <Fragment>
          <div style={{ width: '100%', borderTop: `10px solid ${appbarColor}` }}>
            <div style={styles.box}>
              <div style={styles.header}>
                <h2 style={styles.title}>
                  <span style={{ color: appbarColor }}>
                    {this.props.strings['labels/checkingProfile']}
                  </span>
                </h2>
              </div>
              <div style={styles.spinnerWrapper}>
                <div className="spinner" />
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <Redirect
          to={{
            pathname: `/${this.props.site.name}${path.home}`,
          }}
        />
      );
    }
    return (
      <Redirect
        to={{
          pathname: `/${this.props.site.name}${path.login}`,
        }}
      />
    );
  }
}

GetProfileInfo.propTypes = {
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  newProfileLoaded: PropTypes.string.isRequired,
  checkLodgerInfo: PropTypes.func.isRequired,
};

export default GetProfileInfo;
