import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
// import SocialPerson from 'material-ui/svg-icons/social/person';
import ActionSettings from 'material-ui/svg-icons/action/settings';
import ActionHistory from 'material-ui/svg-icons/action/history';
import { path } from '../utils/utilities';

const styles = {
  content: {
    backgroundColor: '#fff',
  },
  icon: {
    float: 'left',
  },
  heading: {
    fontSize: 15,
    float: 'left',
    margin: '4px 0 0 12px',
    lineHeight: '18px',
  },
  requiredFieldsHeading: {
    fontSize: 15,
    float: 'left',
    color: '#000000',
    margin: 0,
    lineHeight: '18px',
  },
  note: {
    padding: 5,
    color: '#6F7479',
    float: 'right',
  },
};

const heading = (strings, page, appbarColor) => {
  const elements = [];
  const style = Object.assign({}, styles.icon);
  style.color = appbarColor;
  let text = '';
  let note = '';

  const indicatesRequiredField = strings['forms/complaint/hint/indicatesRequiredField'];

  switch (page) {
    case path.profile:
      text = parse(indicatesRequiredField);
      note = '';
      break;
    case path.compose:
      text = parse(indicatesRequiredField);
      note = '';
      break;
    case path.submitNow:
      text = 'Heading goes here';
      note = '';
      break;
    case path.history:
      elements.push(<ActionHistory key="icon" style={style} />);
      text = strings['labels/history'];
      note = '';
      break;
    case path.settings:
      elements.push(<ActionSettings key="icon" style={style} />);
      text = strings['labels/settings'];
      note = '';
      break;
    default:
  }

  if (page === path.compose || page === path.profile) {
    elements.push(
      <p style={styles.requiredFieldsHeading} key="text">
        {text}
      </p>
    );
  } else {
    elements.push(
      <p style={styles.heading} key="text">
        {text}
      </p>
    );
    elements.push(
      <span style={styles.note} key="note">
        {note}
      </span>
    );
  }

  return <div>{elements}</div>;
};

const Header = (props) => {
  const style = Object.assign({}, styles.content);
  style.color = props.site.theme.appbarColor;
  return (
    <div style={style}>{heading(props.strings, props.page, props.site.theme.appbarColor)}</div>
  );
};

Header.propTypes = {
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired,
};

export default Header;
