import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { path } from '../utils/utilities';
import { removeDetails } from '../utils/auth0';

class Logout extends Component {
  componentDidMount() {
    removeDetails(this.props.site.name);
    this.props.clearProfile(true);
  }

  render() {
    return (
      <Redirect
        to={{
          pathname: `/${this.props.site.name}${path.home}`,
        }}
      />
    );
  }
}

Logout.propTypes = {
  site: PropTypes.object.isRequired,
  clearProfile: PropTypes.func.isRequired,
};

export default Logout;
