import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { MenuItem, IconMenu, IconButton } from 'material-ui';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import ActionSettings from 'material-ui/svg-icons/action/settings';
import Input from 'material-ui/svg-icons/action/input';
import Clear from 'material-ui/svg-icons/content/clear';
import { path, appendAccessibility } from '../utils/utilities';
import { isLoginSkipped } from '../utils/auth0';

// rongyadong new one for accessibilty issue
// changed this component from stateless one to class one
class AppMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ariaExpend: false,
    };
  }

  // rongyadong, accessibilty issue
  componentDidMount() {
    if (
      this.iconMenubtn &&
      this.iconMenubtn.refs &&
      this.iconMenubtn.refs.iconButton &&
      this.iconMenubtn.refs.iconButton.button
    ) {
      const iconBtnNode = this.iconMenubtn.refs.iconButton.button.button;
      appendAccessibility(iconBtnNode, 'aria-label', 'Settings or Sign out');
      appendAccessibility(iconBtnNode, 'id', 'SettingsOrSignout');
      const settingsBtn = document.querySelector('#SettingsOrSignout');
      settingsBtn && settingsBtn.removeAttribute('tabindex');
    }
  }

  render() {
    const menuItems = [];
    const { strings } = this.props;
    menuItems.push(
      <MenuItem
        key="Settings"
        primaryText={strings['labels/settings']}
        leftIcon={<ActionSettings />}
        containerElement={<Link to={`/${this.props.site.name}${path.settings}`} />}
        onClick={() => {
          this.setState({ ariaExpend: false });
        }}
      />
    );
    if (isLoginSkipped(this.props.site)) {
      menuItems.unshift(
        <MenuItem
          key="Sign in"
          primaryText={strings['complaintsform/headings/signIn']}
          leftIcon={<Input />}
          containerElement={<Link to={`/${this.props.site.name}${path.goToLogin}`} />}
          onClick={() => {
            this.setState({ ariaExpend: false });
          }}
        />
      );
    } else if (this.props.site.login.behaviour !== 'None') {
      menuItems.push(
        <MenuItem
          key="Sign out"
          primaryText={strings['complaintsform/headings/signOut']}
          leftIcon={<Clear />}
          containerElement={<Link to={`/${this.props.site.name}${path.logout}`} />}
          onClick={() => {
            this.setState({ ariaExpend: false });
          }}
        />
      );
    }

    return (
      <IconMenu
        ref={(iconMenubtn) => {
          this.iconMenubtn = iconMenubtn;
        }}
        {...this.props}
        iconButtonElement={
          <IconButton
            touch
            aria-expanded={this.state.ariaExpend}
            onClick={() => {
              this.setState({ ariaExpend: true });
            }}>
            <MoreVertIcon color={this.props.site.theme.appbarTitleColor} />
          </IconButton>
        }
        menuStyle={{ minWidth: 100 }}
        targetOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
        {menuItems.map((item) => item)}
      </IconMenu>
    );
  }
}

AppMenu.propTypes = {
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
};

export default AppMenu;
