/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Pie } from '@visx/shape';
import { Group } from '@visx/group';
import { LegendOrdinal, LegendItem, LegendLabel } from '@visx/legend';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { localPoint } from '@visx/event';

// const getType = (dataItem) => dataItem.type;
const getCount = (dataItem) => dataItem.complaintsCount;

const ComplaintsPieChart = ({ width, height, complaintsByType, colorScale }) => {
  // console.log('complaintsByType', complaintsByType);
  const margin = { top: 0, bottom: 0, left: 0, right: 0 };
  const innerWidth = width - (margin.left + margin.right);
  const innerHeight = height - (margin.top + margin.bottom);
  const radius = Math.min(innerWidth, innerHeight) / 2;
  // const centerX = innerWidth / 2;
  const centerY = innerHeight / 2;

  const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } =
    useTooltip();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  const handleMouseOver = (event, datum) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: datum,
    });
  };

  return (
    <>
      <div className="pieChartContainer">
        <div className="pieChartContainerLeft">
          <svg width={height} height={height} ref={containerRef}>
            <Group top={centerY + margin.top} left={centerY}>
              <Pie
                data={complaintsByType}
                pieValue={getCount}
                outerRadius={radius}
                innerRadius={40}
                padAngle={0.01}>
                {(pie) =>
                  pie.arcs.map((arc, index) => {
                    const { type } = arc.data;
                    const arcPath = pie.path(arc);
                    const arcFill = colorScale(type);
                    return (
                      <g key={`arc-${type}-${index}`}>
                        <path
                          d={arcPath}
                          fill={arcFill}
                          onMouseOver={(event) => handleMouseOver(event, arc)}
                          onMouseOut={hideTooltip}
                        />
                      </g>
                    );
                  })
                }
              </Pie>
            </Group>
          </svg>
          {tooltipOpen && (
            <TooltipInPortal
              // set this to random so it correctly updates with parent bounds
              key={Math.random()}
              top={tooltipTop}
              left={tooltipLeft}>
              {tooltipData.data.type}: {tooltipData.data.complaintsCount}
            </TooltipInPortal>
          )}
        </div>
        <div className="pieChartContainerRight">
          <LegendOrdinal scale={colorScale}>
            {(labels) => (
              <div>
                {labels.map((label, index) => {
                  if (label.datum === undefined) return null;
                  return (
                    <LegendItem key={`legend-${index}`} margin="0 5px">
                      <svg width="10" height="10">
                        <rect fill={label.value} width="10" height="10" />
                      </svg>
                      <LegendLabel align="left" margin="0 0 0 5px">
                        {label.text}
                      </LegendLabel>
                    </LegendItem>
                  );
                })}
              </div>
            )}
          </LegendOrdinal>
        </div>
      </div>
    </>
  );
};

export default ComplaintsPieChart;
