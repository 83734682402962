import DatePicker from 'material-ui/DatePicker';
import styled from 'styled-components';
import { STYLES } from '../styles';

const StyledDatePicker = styled(DatePicker)`
  hr {
    border-color: ${STYLES.BORDER_COLOR} !important;
  }
`;

export { StyledDatePicker as DatePicker };
