import 'typeface-roboto';
import React from 'react';
import ReactDOM from 'react-dom';
import Site from './site';
import App from './components/App';
import SiteSelector from './components/SiteSelector';
import InvalidSite from './components/InvalidSite';
import { readData, writeData } from './utils/data';
import { log } from './utils/utilities';
import * as serviceWorker from './registerServiceWorker';
import app from './version.json';

serviceWorker.register({
  updateViaCache: 'none',
});

// eslint-disable-next-line
const [headUrl, backUrl] = window.location.pathname.split('/');
if (app.build !== window.localStorage.getItem('build')) {
  // get the site name and clear the cache within this sitename in both the production and the staging
  // eslint-disable-next-line
  for (let key in window.localStorage) {
    if (/.+(_forms|_data|_strings|_skip|_locale).*/.test(key)) {
      window.localStorage.removeItem(key);
    }
  }
}

// eslint-disable-next-line
const [slash, siteName] = window.location.pathname.split('/');
const site = new Site(siteName);
window.siteName = siteName;
// staging switch
// const staging = false;
const staging = !!(
  window.location.hostname === 'localhost' || window.location.hostname.search('staging') !== -1
);
const data = readData('data', siteName, staging);
const forms = readData('forms', siteName, staging);
const strings = readData('strings', siteName, staging);
if (data && forms && strings) {
  site.data = data;
  site.addCustomisation();
  site
    .manifestPath(site.prepareManifest())
    .then((response) => {
      // load the manifest file
      site.manifestlink.setAttribute('href', response);
      site.manifestLoaded = true;
      if (window.location.pathname !== '/') {
        const siteData = Object.assign({}, data);
        siteData.app = app;
        ReactDOM.render(
          <App app={app} site={siteData} forms={forms} strings={strings} />,
          document.getElementById('root')
        );
        // remove the splash screen
        site.splashScreen.remove();
      }
    })
    .catch((error) => {
      console.error(error);
      site.splashScreen.remove();
      ReactDOM.render(
        <InvalidSite error={error} strings={strings} />,
        document.getElementById('root')
      );
    });
}

site
  .getData(`${site.focusApi}/productinfo?product=${site.productName}&action=get_sites`)
  .then((response) => site.validate(response))
  .then((response) => site.getData(`${site.getViewpointCollectorApi()}${site.name}?response=json`))
  .then((response) => {
    site.data.config = response;
    return site.getData(`${site.focusApi}/productinfo`, 'POST', {
      product: site.productName,
      sitename: site.name,
      action: 'get_config',
    });
  })
  .then((response) => {
    site.addAdditionalSiteConfig(response);
    return site.getData(`${site.focusApi}/productinfo`, 'POST', {
      product: site.productName,
      sitename: site.name,
      locale: response.config.airport.locale,
      action: 'get_strings',
    });
  })
  .then((response) => site.getAllTranslations(response.strings))
  .then((response) => {
    if (!site.manifestLoaded) {
      return site.manifestPath(site.prepareManifest());
    }
    return true;
  })
  .then((response) => {
    // load the manifest file
    if (!site.manifestLoaded) {
      site.addCustomisation();
      site.manifestlink.setAttribute('href', response);
    }
    // decide what view to render
    if (window.location.pathname === '/') {
      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
        site.allSites = site.allSites.filter((item) => item.status === 'Public');
      }
      // sitename is missing - show the welcome page or the site selector
      ReactDOM.render(<SiteSelector sites={site.allSites} />, document.getElementById('root'));
    } else if (site.data) {
      log('site.data', site.data);
      document.title = site.data.title; // update site title
      // check the cached data with site data
      let initReactDom = true;
      let reloadApp = false;
      if (data && forms && strings) {
        initReactDom = false;
        if (
          typeof data.lastUpdateDate !== 'undefined' &&
          typeof site.data.lastUpdateDate !== 'undefined' &&
          data.lastUpdateDate !== site.data.lastUpdateDate
        ) {
          reloadApp = true;
        }
      }
      writeData('data', site.data, siteName, staging);
      writeData('forms', site.forms, siteName, staging);
      writeData('strings', site.strings, siteName, staging);
      // console.log(`[+] Data cached for ${siteName}`);
      if (initReactDom) {
        const siteDate = Object.assign({}, site.data);
        siteDate.app = app; // {"version":"x.x.x","build":"xxx"}
        ReactDOM.render(
          <App site={siteDate} forms={site.forms} strings={site.strings} />,
          document.getElementById('root')
        );
      }
      // eslint-disable-next-line
      if (reloadApp) location.reload();
    } else {
      ReactDOM.render(
        <InvalidSite error={false} strings={strings} />,
        document.getElementById('root')
      );
    }
    // remove the splash screen
    site.splashScreen.remove();
  })
  .catch((error) => {
    console.error(error);
    site.splashScreen.remove();
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      site.allSites = site.allSites.filter((item) => item.status === 'Public');
    }
    ReactDOM.render(<SiteSelector sites={site.allSites} />, document.getElementById('root'));
  });
// }
