/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import IconWebTrak from 'material-ui/svg-icons/maps/flight';
import { List } from 'material-ui/List';
import { formatSubmissionDateTime, setTimeWithTransformation } from '../utils/utilities';

export const HistoryList = ({
  complaints,
  colorPalette,
  eventTypesFiltered,
  site,
  userProfile,
  webTrakRootUrl,
  useEventTypeColors,
}) => {
  if (!complaints) return null;
  if (
    useEventTypeColors &&
    (!(colorPalette && eventTypesFiltered) || colorPalette.length < eventTypesFiltered.length)
  ) {
    console.error('Something is wrong with the supplied colorPalette and/or eventTypesFiltered');
    console.log('colorPalette:', colorPalette);
    console.log('eventTypesFiltered:', eventTypesFiltered);
  }
  const eventTypeColorLookup = {};
  for (let i = 0, ii = colorPalette.length; i < ii; i += 1) {
    const color = colorPalette[i];
    const eventType = eventTypesFiltered[i];
    eventTypeColorLookup[eventType] = color;
  }
  if (complaints.length > 0) {
    const lat = userProfile.lat || site.airportLat;
    const long = userProfile.lon || site.airportLong;
    const { displayWebTrakLinksForComplaints } = site.config;
    if (webTrakRootUrl.match(/\/$/)) {
      // eslint-disable-next-line no-param-reassign
      webTrakRootUrl = webTrakRootUrl.substring(0, webTrakRootUrl.length - 1);
    }

    const itemList = complaints.map((item) => {
      const firstEventType = item.event_types[0];
      const eventTypeColor = eventTypeColorLookup[firstEventType];
      const eventDateTime = setTimeWithTransformation(item, site.complaintsform.timezoneTransform);
      const dateTimeString = moment(new Date(eventDateTime))
        .subtract(10, 'seconds')
        .format('YYYYMMDDHHmmss');
      return (
        <div key={item.submission_id} className="complaintRowWrapper">
          {item.category && <h5 className="historyMonth">{item.category}</h5>}
          <div className="complaintRow">
            <div className="complaintRowTop">
              <div className="compCell compDate">{`${formatSubmissionDateTime(
                eventDateTime
              )}`}</div>
              {useEventTypeColors && (
                <div className="compCell compTypeColor">
                  <div className="compTypeColorBadge" style={{ background: eventTypeColor }} />
                </div>
              )}
              <div className="compCell compType">
                {item.event_types.map((eventType, index) =>
                  index === 0 ? `${eventType}` : `, ${eventType}`
                )}
              </div>
              {displayWebTrakLinksForComplaints && lat && long && (
                <div className="compCell compWebTrakLink">
                  <a
                    href={`${webTrakRootUrl}?rls=${dateTimeString}&mz=13&mc=${lat},${long}&mh=${long},${lat}`}
                    target="_blank"
                    rel="noopener noreferrer">
                    {' '}
                    <IconWebTrak style={{ fill: '#888' }} />{' '}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        <List role="list">{itemList}</List>
      </div>
    );
  }
  return null;
};
