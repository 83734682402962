/* eslint-disable react/no-unused-state */
/* eslint-disable react/jsx-no-bind */
import decode from 'decode-html';
import React, { Component, Fragment } from 'react';
import axios from 'axios';
import platform from 'platform';
import uuidv1 from 'uuid/v1';
import Fingerprint2 from 'fingerprintjs2';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import IconSubmitNow from 'material-ui/svg-icons/maps/flight';
import AccountCircle from 'material-ui/svg-icons/action/account-circle';
import CommunicationMessage from 'material-ui/svg-icons/communication/message';
import Subheader from 'material-ui/Subheader';
import { List, ListItem } from 'material-ui/List';
import parse from 'html-react-parser';
import AppNavigation from './AppNavigation';
import { Clock } from './Clock';
import { HistoryList } from './HistoryList';
import {
  path,
  isMobile,
  localeSelectionRequired,
  defineValues,
  getViewpointCollectorUrl,
  getLocalTime,
  getViewpointApiUrl,
  formatSubmissionDateTime,
  months,
  log,
  CSVToArray,
  defaultGraphColors,
  getTranslatedEventTypes,
  defineLocale,
} from '../utils/utilities';
import { isProfileCompleted, profileNeedsValidation, isValid } from '../utils/profile';
import { isAuthenticated, getDetails, isLoginSkipped, needsAuthentication } from '../utils/auth0';
import { readData } from '../utils/data';

/* global grecaptcha */

const STYLES = {
  contentStyle: { maxWidth: 320, wordWrap: 'break-word' },
  dialogTitle: { fontSize: 15, fontWeight: 500, padding: '15px 24px 0px' },
  submitButton: { margin: '12px 12px 12px 0px' },
  submitNowButton: {
    margin: '30px 0',
  },
  submitNowButtonWrapper: {
    paddingTop: '30px',
    paddingBottom: '30px',
  },
  clearButton: { margin: 12 },
  resetBtn: { margin: '10px 8px' },
  iconStyle: { fill: '#757575' },
  divider: { width: '100%', display: 'block', height: 5 },
  spinnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  captchaWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  accessibilityButton: {
    margin: '1rem 0',
    height: '3rem',
    flexGrow: '1',
    textAlign: 'center',
    fontSize: '14px',
    fontFamily: '"Roboto", sans-serif',
    backgroundColor: '#7215D8',
    color: '#FFF',
  },
  errorTexts: {
    fontWeight: 'bolder',
    color: 'rgb(244, 67, 54)',
    fontSize: '14px',
    fontFamily: '"Roboto", sans-serif',
    display: 'flex',
    alignItems: 'center',
  },
};

const getEventTypes = (defineComplaint) => {
  const eventTypesOptions = defineComplaint.find((item) => item.name === 'event_type');
  if (!eventTypesOptions || !eventTypesOptions.options) {
    return [];
  }
  return eventTypesOptions.options;
};
class SubmitNow extends Component {
  constructor(props) {
    super(props);
    this.geolocation = { latitude: null, longitude: null };
    this.selectNodes = {};
    this.textNodes = {};
    this.translatedEventTypes = getTranslatedEventTypes(props.site.config, props.strings);
    this.locale = defineLocale(this.props.site) || props.site.config.locale;
    const isStaging =
      window.location.hostname === 'localhost' || window.location.hostname.search('staging') !== -1;
    const eventTypes = getEventTypes(props.defineComplaint);
    const userGraphColors = readData('userGraphColors', this.props.site.name, isStaging);

    this.state = {
      complaint: this.getDefaultComplaintData(),
      colorPalette: userGraphColors || defaultGraphColors,
      errors: defineValues(this.props.defineComplaint, true),
      captchaResponse: this.props.site.config.showCaptcha ? null : true,
      mobile: isMobile(),
      loading: false,
      validationErrors: '',
      dialogSubmission: false,
      submitMsg: '',
      showPrivacy: false,
      recentSubmissionCounts: {},
      recentSubmissionsList: [],
      selectedSubmitNowOptions:
        readData(
          'submitNowOptions',
          this.props.site.name,
          !!(
            window.location.hostname === 'localhost' ||
            window.location.hostname.search('staging') !== -1
          )
        ) || [],
      eventTypes,
    };
  }

  componentDidMount() {
    // Modify the material UI node to reach accessibility purpose
    Object.keys(this.textNodes).forEach((key) => {
      this.textNodes[key].querySelector('textarea').id = this.textNodes[key]
        .querySelector('label')
        .getAttribute('for');
    });
    window.scrollTo(0, 0);
    if (!this.props.geolocation[0] || !this.props.geolocation[1]) {
      this.props.checkLocationAddress();
    }
    this.checkLayout();
    window.addEventListener('resize', this.checkLayout);
    setTimeout(() => {
      if (
        !this.state.captchaRendered &&
        typeof grecaptcha !== 'undefined' &&
        document.querySelector('#captcha')
      ) {
        try {
          grecaptcha.render('captcha', {
            sitekey: '6LczcFIUAAAAAIX44ppCLxHwZSJPey5SiPHStYIl',
            callback: (response) => {
              this.setState({ captchaResponse: response });
            },
          });
          this.setState({ captchaRendered: true });
        } catch (error) {
          console.warn('grecaptcha.render error:', error);
        }
      }
    }, 2000);

    // set location id if only one location in the location list
    const { locations } = this.props.site.config;
    const locationKeys = Object.keys(locations);
    if (locationKeys.length === 1) {
      [this.state.complaint.locations] = locationKeys;
    } else if (locationKeys.length > 1) {
      // Fix the error on san4 where even though the dropdown shows a value,
      // submitting the form generates an error because locations is empty
      [this.state.complaint.locations] = [locationKeys[0]];
    }

    document.querySelectorAll('hr').forEach((item) => {
      item.setAttribute('aria-hidden', true);
      item.style.borderBottom = '1px solid #949494';
    });

    this.getRecentSubmissions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkLayout);
    this.updateAppState();
  }

  getRecentSubmissions = () => {
    const MAX_SUBMISSIONS = 500;
    const idToken = getDetails(this.props.site.name, 'idToken');
    if (idToken) {
      const getSubmissionsUrl = `${getViewpointApiUrl(
        this.props.site
      )}wc/lodger/latest_submissions?timezone=${
        this.props.site.config.timezone || null
      }&limit=${MAX_SUBMISSIONS}&numberOfDays=90`;
      axios({
        method: 'get',
        url: getSubmissionsUrl,
        headers: {
          Authorization: `Bearer ${idToken}`,
          'X-Clientid': this.props.site.login.auth0clientId,
          'X-Productname': 'ViewpointCollector',
          'X-Sitename': this.props.site.name,
        },
      })
        .then((response) => {
          const {
            data: { data },
          } = response;
          this.setState({
            loading: false,
            recentSubmissionCounts: this.calculateSubmissionCounts(data),
            recentSubmissionsList: this.prepareRecentSubmissionsList(data),
          });
        })
        .catch((error) => {
          log('getSubmissions ERROR', error);
          this.setState({ error: true });
        });
    } else {
      log('ERROR: idToken is', idToken);
      this.setState({ error: true });
    }
  };
  getDefaultComplaintData = () => {
    // console.log('--- getDefaultComplaintData ---');
    const values = Object.assign({}, this.props.complaintData);
    this.props.defineComplaint.forEach((field) => {
      if (field.element === 'date' || field.name === 'time') {
        const localTime = getLocalTime(this.props.site.config.timezone);
        if (!values[field.name]) {
          values[field.name] = localTime;
        }
      } else if (
        this.props.site.config.storeUserPreference === '1' &&
        localStorage.getItem(field.name)
      ) {
        // console.log('storeUserPreference is ON; getting data from localStorage');
        // CMS324873 get the complaint data
        values[field.name] = localStorage.getItem(field.name);
      }
    });
    return values;
  };

  displayRecentSubmissions = (submissions) => {
    const { strings } = this.props;
    if (submissions.length > 0) {
      const {
        theme: { appbarColor },
      } = this.props.site;
      const itemList = submissions.map((item) => (
        <div key={item.submission_id} role="listitem">
          {item.category && <Subheader style={{ color: appbarColor }}>{item.category}</Subheader>}
          <ListItem
            leftIcon={<CommunicationMessage />}
            innerDivStyle={STYLES.messagesList}
            containerElement="button"
            style={{ width: '100%', textAlign: 'left' }}
            // onClick={() => {
            //   this.openDetails(item);
            // }}
          >
            <div style={{ display: 'flex' }}>
              <div className="details" style={{ flex: 1 }}>
                <div>{`${strings['labels/sent']}: ${formatSubmissionDateTime(
                  item.event_datetime_uncorrected
                )}`}</div>
                <p
                  style={{
                    maxWidth: '300px',
                    color: 'rgba(0, 0, 0, 0.54)',
                    margin: '.25rem 0',
                    lineHeight: '18px',
                  }}>
                  <span>
                    {item.event_types.map((eventType, index) =>
                      index === 0 ? `${eventType}` : `, ${eventType}`
                    )}
                  </span>
                </p>
              </div>
            </div>
          </ListItem>
        </div>
      ));
      return (
        <div style={!this.state.mobile ? STYLES.list : STYLES.listMobile}>
          <h2>{strings['labels/last5Complaints']}</h2>
          <List role="list">{itemList}</List>
        </div>
      );
    }
  };

  displayRecentSubmissionCounts = (submissionCounts) => {
    if (submissionCounts && submissionCounts.totalCount)
      return (
        <div className="reportCount">
          <div className="reportCountToday">
            <span>{submissionCounts.todayCount}</span>
            {this.props.strings['labels/numComplaintsToday']}
          </div>
          <div className="reportCount90Days">
            <span>{submissionCounts.totalCount}</span>
            {this.props.strings['labels/numComplaintsLast90Days']}
          </div>
        </div>
      );
  };

  renderSubmitNowButtons = () => {
    // Dirty hack. If the string is updated in Focus, it needs to be updated here as well.
    const NOT_CONFIGURED_STRING =
      '(Comma separated list of event types for the Submit Now buttons)';
    const isReadyToSubmit = this.checkReadyToSubmit();
    // If no 'Submit Now' event types are configured in focus, show the default Submit Now button
    if (
      this.props.strings['complaintsform/lists/event_types_for_submit_now'] ===
        NOT_CONFIGURED_STRING ||
      this.props.strings['complaintsform/lists/event_types_for_submit_now'] === ''
    ) {
      const submitComplaintForCurrentTime =
        this.props.strings['complaintsform/blocks/submitComplaintForCurrentTime'];

      return (
        <div className="recent-submissions" style={STYLES.recentSubmissions}>
          <button
            disabled={!(isReadyToSubmit && !this.state.loading && this.state.captchaResponse)}
            onClick={() => this.readyToSendForCurrentTime('Enquiry')}
            className="submitNowButton2">
            <IconSubmitNow />
            {submitComplaintForCurrentTime}
          </button>
        </div>
      );
    }

    const selectedEventTypes = this.state.selectedSubmitNowOptions.length
      ? this.state.selectedSubmitNowOptions
      : CSVToArray(this.props.strings['complaintsform/lists/event_types_for_submit_now']);
    const headingSubmitComplaintForCurrentTime =
      this.props.strings['complaintsform/blocks/submitComplaintForCurrentTime'];

    if (!(selectedEventTypes && selectedEventTypes.length)) {
      console.warn('No event types configured for Submit Now');
      return;
    }

    const buttons = this.translatedEventTypes.flatMap((eventType, index) => {
      if (selectedEventTypes.includes(eventType.label)) {
        const sendWithEventType = this.readyToSendForCurrentTime.bind(
          null,
          this.locale.indexOf('en') !== -1 ? eventType.value : eventType.label
        );
        const buttonClass = `submitNowButton2 button${index}`;
        return (
          <button
            disabled={!(isReadyToSubmit && !this.state.loading && this.state.captchaResponse)}
            onClick={sendWithEventType}
            className={buttonClass}
            key={eventType.value}>
            <IconSubmitNow />
            {eventType.label}
          </button>
        );
      }
      return [];
    });

    return (
      <>
        <div className="submit-now-heading">
          <h2>{headingSubmitComplaintForCurrentTime}</h2>
        </div>
        <div className="recent-submissions" style={STYLES.recentSubmissions}>
          {buttons}
        </div>
      </>
    );
  };

  timelineHeader = (string) => {
    const { strings } = this.props;
    let title = '';
    const [date] = string.split(' ');
    // eslint-disable-next-line
    let [year, month, day] = date.split('-');
    month = Number(month) - 1;
    year = Number(year);
    if (
      `${this.today.getFullYear()}-${this.today.getMonth()}-${this.today.getDate()}` ===
      `${year}-${month}-${day}`
    ) {
      title = strings['labels/today'];
    } else if (
      `${this.yesterday.getFullYear()}-${this.yesterday.getMonth()}-${this.yesterday.getDate()}` ===
      `${year}-${month}-${day}`
    ) {
      title = strings['labels/yesterday'];
    } else if (
      this.today.getMonth() === month &&
      this.timelineTitles.length > 0 &&
      this.timelineTitles[this.timelineTitles.length - 1] !== months[month]
    ) {
      // title = `${strings['labels/moreFrom']} ${months[month]}`;
      title = `More from ${months[month]}`;
    } else {
      title = this.today.getFullYear() === year ? months[month] : `${months[month]} ${year}`;
    }
    if (this.timelineTitles.indexOf(title) === -1) {
      this.timelineTitles.push(title);
      return title;
    }
    return null;
  };
  prepareRecentSubmissionsList = (submissions) => submissions.slice(0, 5);

  calculateSubmissionCounts = (submissions) => {
    const complaintsByDate = {};
    submissions.forEach((submission) => {
      const complaintDate = moment(new Date(submission.event_datetime_uncorrected)).format(
        'YYYY-MM-DD'
      );
      if (complaintsByDate[complaintDate]) {
        complaintsByDate[complaintDate] += 1;
      } else {
        complaintsByDate[complaintDate] = 1;
      }
    });
    const today = moment(new Date().getTime())
      .tz(this.props.site.config.timezone)
      .format('YYYY-MM-DD');
    const todayCount = complaintsByDate[today] ? complaintsByDate[today] : 0;
    const totalCount = submissions.length;
    console.log('today:', today, 'complaints today:', todayCount, 'complaints total:', totalCount);
    return {
      todayCount,
      totalCount,
    };
  };

  checkLayout = () => {
    if (this.state.mobile !== isMobile()) this.setState({ mobile: isMobile() });
  };

  showPrivacyNow = () => {
    this.setState({ showPrivacy: true });
  };

  afterSubmit = () => {
    this.cForm = true;
    const data = this.state.complaint;
    //  VWA-108
    data.date = getLocalTime(this.props.site.config.timezone);
    data.time = data.date;
    // data.comments = "";
    this.setState({
      complaint: data,
      errors: defineValues(this.props.defineComplaint, true),
    });
    this.props.saveState('complaint', data);
    setTimeout(() => {
      const firstFieldEl = document.querySelector(`input,select`);
      firstFieldEl && firstFieldEl.focus();
      // this.props.getSubmitKey().then(key => { this.submitKey = key; });
    }, 1);
  };

  // CMS324873 Clear form when with local storage
  clearFormAndLocalStorage = () => {
    this.cForm = true;
    const data = defineValues(this.props.defineComplaint);
    this.props.defineComplaint.forEach((field) => {
      if (this.props.site.config.storeUserPreference === '1' && localStorage.getItem(field.name)) {
        localStorage.removeItem(field.name);
      }
    });
    this.setState({
      complaint: data,
      errors: defineValues(this.props.defineComplaint, true),
    });
    this.props.saveState('complaint', data);
    setTimeout(() => {
      const firstFieldEl = document.querySelector(`input,select`);
      firstFieldEl && firstFieldEl.focus();
    }, 1);
  };

  clearForm = () => {
    this.cForm = true;
    const data = defineValues(this.props.defineComplaint);
    // add date and time to data
    const localTime = getLocalTime(this.props.site.config.timezone);
    data.date = localTime;
    // data.time = localTime;
    this.setState({
      complaint: data,
      errors: defineValues(this.props.defineComplaint, true),
    });
    this.props.saveState('complaint', data);
    setTimeout(() => {
      const firstFieldEl = document.querySelector('input, select');
      firstFieldEl && firstFieldEl.focus();
      // this.props.getSubmitKey().then(key => { this.submitKey = key; });
    }, 10);
  };

  dateTimeUpdated = (field, date) => {
    // log('datetime updated', field, date);
    this.validateInput(field, date);
  };

  checkReadyToSubmit = () => isProfileCompleted(this.state.complaint, this.props.defineComplaint);

  validateInput = (field, value) => {
    const errorsObject = this.state.errors;
    const { config } = this.props.site;
    const error = isValid(field, value, this.props.strings, true, config);
    if (errorsObject[field.name] !== error) {
      errorsObject[field.name] = error;
      this.setState(errorsObject);
    }
  };

  updateAppState = () => {
    setTimeout(() => {
      if (!this.cForm) {
        this.props.saveState('complaint', this.state.complaint);
      }
    }, 1);
  };

  readyToSend = () => {
    this.formData = new FormData();
    this.addValues('complaint', this.state.complaint);
    this.addValues('profile', this.props.profileData);
    this.formData.append('source', 'PWA');
    this.appendMetaData();
    this.validateBeforeSubmit();
  };

  readyToSendForCurrentTime = (eventType) => {
    this.formData = new FormData();
    const data = this.state.complaint;
    data.event_type = typeof eventType === 'string' ? eventType : '';
    data.date = getLocalTime(this.props.site.config.timezone);
    data.time = data.date;
    this.setState({
      complaint: data,
      errors: defineValues(this.props.defineComplaint, true),
    });
    this.props.saveState('complaint', data);

    this.addValues('complaint', this.state.complaint);
    this.addValues('profile', this.props.profileData);
    this.formData.append('source', 'PWA');
    this.appendMetaData();
    this.validateBeforeSubmit();
  };

  addValues = (type, data) => {
    const definition = type === 'profile' ? this.props.defineProfile : this.props.defineComplaint;
    Object.keys(data).forEach((field) => {
      const find = definition.find((item) => item.name === field);
      if (find) {
        if (find.element === 'select' && find.multiSelect) {
          data[field].forEach((value) => {
            this.formData.append(`${field}[]`, value);
          });
        } else if (find.element === 'date') {
          const date = data[field];
          this.formData.append('year', date.getFullYear());
          this.formData.append('month', date.getMonth() + 1);
          this.formData.append('day', date.getDate());
          const realYear = date.getFullYear();
          const realMonth = date.getMonth() + 1;
          const realDate = date.getDate();
          const monthPadded = (realMonth < 10 ? '0' : '') + String(realMonth);
          const datePadded = (realDate < 10 ? '0' : '') + String(realDate);
          const dateStr = `${String(realYear)}-${String(monthPadded)}-${String(datePadded)}`;
          this.formData.append('date', dateStr);
        } else if (find.element === 'time') {
          const time = data[field];
          this.formData.append('hour', time.getHours());
          this.formData.append('min', time.getMinutes());
          this.formData.append('sec', time.getSeconds());
        } else {
          this.formData.append(field, data[field]);
        }
      }
    });
    if (typeof data.lat !== 'undefined' && typeof data.lon !== 'undefined') {
      this.formData.append('home_latitude', data.lat);
      this.formData.append('home_longitude', data.lon);
    }
  };

  appendMetaData = () => {
    const {
      config: { timezone },
    } = this.props.site;
    this.formData.append('locale', this.locale);
    this.formData.append('timeZone', timezone);
    this.formData.append('browser_name', platform.name || null);
    this.formData.append('browser_version', platform.version.split('.')[0] || null);
    this.formData.append('browser_vendor', navigator.vendor || null);
    this.formData.append('browser_platform', navigator.platform || null);
    // this.formData.append('accept_privacy', 'Y');
    this.formData.append(
      'privacy_statement',
      this.props.strings['complaintsform/blocks/privacyStatement']
    );
    this.formData.append('nowebtrak', 1);
    this.formData.append('webtraklinkback', '');
    this.formData.append('device_latitude', this.props.geolocation[0]);
    this.formData.append('device_longitude', this.props.geolocation[1]);
    this.formData.append('defaulttime', 0);
    this.formData.append('g-recaptcha-response', this.gRecaptchaResponse);
    const idToken = getDetails(this.props.site.name, 'idToken');
    if (idToken) this.formData.append('token', idToken);
    new Fingerprint2().get((result) => {
      this.formData.append('browser_uuid', uuidv1());
      this.formData.append('fingerprint', result);
    });
  };

  validateBeforeSubmit = () => {
    this.setState({ loading: true });
    this.props.getSubmitKey().then((key) => {
      // console.log(`%c submitKey set to ${key}`, "color: #F90");
      this.formData.append('submitkey', key);
      axios({
        method: 'post',
        url: `${getViewpointCollectorUrl(this.props.site)}${this.props.site.name}/home/validateall`,
        data: this.formData,
      })
        .then((response) => {
          if (response && typeof response.data !== 'undefined') {
            const errors = {};
            const isSubmittedDataValid = response.data.validation_result;
            const validationErrors = response.data.validation_errors;
            delete response.data.validation_result;
            delete response.data.validation_errors;
            Object.keys(response.data).forEach((index) => {
              if (response.data[index].ok === false) errors[index] = response.data[index];
            });
            if (!isSubmittedDataValid) {
              console.warn('Submitted data is not valid');
              const errorText = validationErrors.replace('\n', '. ');
              // this.setState({ loading: false, dialog: true, validationErrors: validationErrors.replace('\n', '. ') });
              this.setState({
                loading: false,

                validationErrors: errorText.split('.')[0],
              });
              // this.setState({ loading: false, validationErrors: errorText.split('.')[0] });

              const { errors: submissionErrors } = this.state;
              let firstField = [];

              Object.keys(submissionErrors).forEach((fieldName) => {
                if (submissionErrors[fieldName] === 'Required') {
                  firstField = [...firstField, key];
                }
              });

              const firstFieldEl = document.querySelector(`input[name=${firstField[0]}]`);
              firstFieldEl && firstFieldEl.focus();
            } else {
              this.submitForm();
            }
          }
        })
        .catch((response) => {
          this.setState({ loading: false });
        });
    });
  };

  submitForm = () => {
    const SUBMISSION_DIALOG_TIMEOUT = 3000;
    // console.log(`---------- submitForm: ${this.formData.get('hour')}:${this.formData.get('min')}:${this.formData.get('sec')} - ${this.formData.get('aircrafttype')} - ${this.formData.get('event_type')} - ${this.formData.get('activity_type')} - "${this.formData.get('comments')}"`);
    console.log(
      `%csubmitForm: ${this.formData.get('month')}-${this.formData.get(
        'day'
      )} - ${this.formData.get('hour')}:${this.formData.get('min')}:${this.formData.get(
        'sec'
      )}, Timezone:${this.formData.get('timeZone')}`,
      'color: #FF0'
    );
    // for(let pair of this.formData.entries()) {
    //   console.log('submitForm -  \t\t ' + pair[0]+ ': '+ pair[1]);
    // }
    axios({
      method: 'post',
      url: `${getViewpointCollectorUrl(this.props.site)}${this.props.site.name}?response=json`,
      data: this.formData,
    })
      .then((response) => {
        if (response && typeof response.data !== 'undefined') {
          this.getRecentSubmissions();
          this.setState(
            {
              loading: false,
              dialogSubmission: true,
              submitMsg: response.data,
            },
            () => {
              setTimeout(() => {
                this.setState({ dialogSubmission: false });
                this.afterSubmit();
              }, SUBMISSION_DIALOG_TIMEOUT);
            }
          );
          // CMS324873 Store the complaint values
          if (this.props.site.config.storeUserPreference === '1') {
            Object.keys(this.state.complaint).forEach((item) => {
              localStorage.setItem(item, this.state.complaint[item]);
            });
          }
        }
      })
      .catch((response) => {
        console.error(response);
        this.setState({ loading: false });
      });
  };

  renderHtml = (html) => (
    // eslint-disable-next-line
    <div dangerouslySetInnerHTML={{ __html: html }} />
  );

  render() {
    const {
      site: {
        name: siteName,
        theme: { appbarColor },
      },
      profileData: { name, surname, email },
      strings,
    } = this.props;
    const pageTitle = strings['labels/compose'];
    const isReadyToSubmit = this.checkReadyToSubmit();
    const close = strings['labels/close'];
    const complaintSubmitted = strings['complaintsform/headings/complaintSubmitted'];
    const closeConfirmation = strings['complaintsform/headings/closeConfirmation'];
    const complaintFailedToSubmit = strings['complaintsform/headings/complaintFailedToSubmit'];
    const confirmationText = decode(strings['complaintsform/blocks/complaintSubmitted']);
    const privacyStatement = decode(strings['complaintsform/blocks/privacyStatement']);
    let hasPrivacy = this.props.site.config.fields.indexOf('accept_privacy');
    if (hasPrivacy !== -1) {
      hasPrivacy = true;
    } else {
      hasPrivacy = false;
    }

    // is language selection required?
    if (localeSelectionRequired(this.props.site))
      return <Redirect to={{ pathname: `/${this.props.site.name}${path.language}` }} />;
    if (isAuthenticated(this.props.site)) {
      if (
        !this.props.newProfileLoaded &&
        !isLoginSkipped(this.props.site) &&
        needsAuthentication(this.props.site)
      ) {
        return <Redirect to={{ pathname: `/${this.props.site.name}${path.getProfileInfo}` }} />;
      }
      const check = this.props.isAppJustLoaded();
      if (!check.isPrivacyAccepted) {
        return <Redirect to={{ pathname: `/${this.props.site.name}${path.privacy}/accept` }} />;
      }
      // need to complete your profile first
      if (
        !isProfileCompleted(
          this.props.profileData,
          this.props.defineProfile,
          this.props.site.config
        )
      ) {
        profileNeedsValidation(this.props.site.name);
        return (
          <Redirect
            to={{
              pathname: `/${this.props.site.name}${path.profile}`,
              state: { goCheckProfile: true },
            }}
          />
        );
      }
      return (
        <Fragment>
          <AppNavigation site={this.props.site} strings={this.props.strings} title={pageTitle} />

          {this.state.mobile && (
            <Toolbar>
              <ToolbarGroup firstChild style={{ paddingLeft: 5 }}>
                <ListItem
                  primaryText={`${name} ${surname} - ${email}`}
                  leftIcon={<AccountCircle />}
                  innerDivStyle={{ paddingLeft: 60 }}
                  containerElement={<Link to={`/${siteName}${path.profile}`} />}
                />
              </ToolbarGroup>
            </Toolbar>
          )}

          <div className={this.state.mobile ? 'none-flex-container' : 'flex-container'}>
            <Clock appbarColor={appbarColor} />
            <div className="recent-submissions" style={STYLES.recentSubmissions}>
              {!isReadyToSubmit && (
                <p id="submitButtonDisabledExplanation" style={{ textAlign: 'center' }}>
                  {parse(strings.focusStrings.labels.submitNowDisabledMessage)}{' '}
                  <Link to={`/${this.props.site.name}${path.compose}`}>
                    {strings['labels/compose']}
                  </Link>
                </p>
              )}
            </div>

            {!this.state.loading && this.renderSubmitNowButtons()}
            <div className="recent-submissions" style={STYLES.recentSubmissions}>
              {!this.state.loading &&
                this.displayRecentSubmissionCounts(this.state.recentSubmissionCounts)}
            </div>

            <div className="recent-submissions" style={STYLES.recentSubmissions}>
              {!this.state.loading && (
                <HistoryList
                  complaints={this.state.recentSubmissionsList}
                  colorPalette={this.state.colorPalette}
                  eventTypesFiltered={this.state.eventTypes}
                  strings={this.props.strings}
                  site={this.props.site}
                  userProfile={this.props.userProfile}
                  webTrakRootUrl={this.props.webTrakRootUrl}
                  useEventTypeColors={false}
                />
              )}
            </div>

            <div id="submitNowForm">
              {this.props.site.config.showCaptcha && (
                <div style={STYLES.captchaWrapper}>
                  <div id="captcha" />
                </div>
              )}

              {hasPrivacy && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <div style={{ textAlign: 'center' }}>
                  <a
                    href="#"
                    style={{
                      marginTop: '3px',
                      marginLeft: '0',
                      paddingLeft: '0',
                      color: '#0645AD',
                    }}
                    onClick={() => this.setState({ showPrivacy: true })}>
                    {strings['complaintsform/headings/privacyStatement']}
                  </a>
                </div>
              )}
            </div>
          </div>
          <Dialog
            title={strings['complaintsform/headings/privacyStatement']}
            bodyClassName="privacy-policy "
            titleStyle={STYLES.dialogTitle}
            contentStyle={STYLES.contentStyle}
            actions={[
              <FlatButton
                label={strings['labels/ok']}
                primary
                onClick={() => {
                  this.setState({ showPrivacy: false });
                }}
              />,
            ]}
            modal={false}
            open={this.state.showPrivacy}>
            <div>{this.renderHtml(privacyStatement)}</div>
          </Dialog>
          <Dialog
            title="Error"
            titleStyle={STYLES.dialogTitle}
            contentStyle={STYLES.contentStyle}
            actions={[
              <FlatButton
                label={strings['labels/ok']}
                primary
                onClick={() => {
                  this.props.history.push(`/${this.props.site.name}${path.submitNow}`);
                }}
              />,
            ]}
            modal={false}
            open={false}>
            {this.state.validationErrors}
          </Dialog>
          <Dialog
            title={
              this.state.submitMsg.result === '1' ? complaintSubmitted : complaintFailedToSubmit
            }
            actions={[
              <FlatButton
                label={
                  this.state.submitMsg.result === '1' && closeConfirmation.length
                    ? closeConfirmation
                    : close
                }
                primary
                onClick={() => {
                  this.afterSubmit();
                  this.setState({ dialogSubmission: false });
                }}
              />,
            ]}
            modal={false}
            open={this.state.dialogSubmission}
            titleStyle={STYLES.dialogTitle}
            contentStyle={STYLES.contentStyle}>
            {this.state.submitMsg.result === '1' ? (
              <div>{this.renderHtml(confirmationText)}</div>
            ) : (
              <p>{this.state.submitMsg.error}</p>
            )}
          </Dialog>
        </Fragment>
      );
    }
    return <Redirect to={{ pathname: `/${this.props.site.name}${path.login}` }} />;
  }
}

SubmitNow.propTypes = {
  history: PropTypes.object.isRequired,
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  defineProfile: PropTypes.array.isRequired,
  profileData: PropTypes.object.isRequired,
  newProfileLoaded: PropTypes.string.isRequired,
  defineComplaint: PropTypes.array.isRequired,
  complaintData: PropTypes.object.isRequired,
  geolocation: PropTypes.array.isRequired,
  getSubmitKey: PropTypes.func.isRequired,
  saveState: PropTypes.func.isRequired,
  isAppJustLoaded: PropTypes.func.isRequired,
  checkLocationAddress: PropTypes.func.isRequired,
};

export default withRouter(SubmitNow);
