import CryptoES from 'crypto-es';
import { compareNormalizedStrings } from './utilities';

const appValues = (siteName) => ({
  userProfile: `${siteName}_profile`,
  profileDialog: `${siteName}_profile_dialog`,
  profileNeedsValidation: `${siteName}_validate_profile`,
  remember: `${siteName}_remember_profile`,
});
export const isProfileCompleted = (data, definitions, config = {}, strings = {}) => {
  let valid = true;
  definitions.forEach((field) => {
    if (typeof data[field.name] !== 'undefined') {
      if (!isValid(field, data[field.name], strings, false, config)) {
        console.warn('\t 1 isValid returned false for', field.name);
        valid = false;
      }
    } else if (!isValid(field, null, strings, false, config)) {
      console.warn('\t 2 isValid returned false for', field.name);
      valid = false;
    }
  });
  return valid;
};

export const validateProfile = (data, definitions, strings, config) => {
  const errors = {};
  definitions.forEach((field) => {
    if (typeof data[field.name] !== 'undefined') {
      errors[field.name] = isValid(field, data[field.name], strings, true, config);
    }
  });
  return errors;
};

export const isValid = (field, value, strings = {}, error = false, config = {}) => {
  const val = field.element === 'text' ? value.trim() : value;
  if (field.required) {
    if (field.element !== 'select' && !val) return error ? strings['labels/required'] : false;
    if (
      (field.element === 'select' && Array.isArray(val) && val.length === 0) ||
      (field.element === 'select' && !val)
    )
      return error ? strings['labels/required'] : false;
  }

  if (field.element === 'select') {
    if (value && typeof value === 'string') {
      if (
        !field.options.flatMap((item) => (compareNormalizedStrings(item.value, value) ? value : []))
          .length
      ) {
        return error ? strings['labels/invalid'] : false;
      }
    } else {
      let valid = true;
      if (value) {
        value.forEach((eachValue) => {
          if (field.options.find((item) => item.value === eachValue) === undefined) valid = false;
        });
      }
      if (!valid) return error ? strings['labels/invalid'] : false;
    }
  }

  if (field.element === 'text' && field.maxlength && field.maxlength < val.length)
    return error ? strings['labels/invalid'] : false;
  if (field.validate === 'name' && val !== '' && !isValidName(val)) {
    return error ? strings['labels/invalid'] : false;
  }

  if (field.validate === 'email' && val !== '' && !isValidEmail(val))
    return error ? strings['app/invalidEmail'] : false;
  if (field.validate === 'tel' && val !== '' && !isValidPhoneNumber(val, config))
    return error ? strings['app/invalidPhoneNumber'] : false;

  return error ? '' : true;
};

const isValidName = (number) => {
  const re = /^[a-zA-Z\s`'-]*$/;

  return re.test(String(number));
};

const isValidEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const isValidPhoneNumber = (number, config) => {
  const numberAsString = String(number);
  const defaultRegex = /\d{7,12}/;
  const doesResemblePhoneNumber = defaultRegex.test(numberAsString.replace(/[^\d]+/g, ''));
  let isValidLandlineNumber = false;
  let isValidCellphoneNumber = false;
  if (config) {
    if (config.phoneNumberRegex && config.phoneNumberRegex.length) {
      const regex = new RegExp(config.phoneNumberRegex);
      isValidLandlineNumber = regex.test(String(number));
    }
    if (config.cellPhoneRegex && config.cellPhoneRegex.length) {
      const regex = new RegExp(config.cellPhoneRegex);
      isValidCellphoneNumber = regex.test(String(number));
    }
  }
  return isValidLandlineNumber || isValidCellphoneNumber || doesResemblePhoneNumber;
};

export const profileDialogDisplayed = (siteName) =>
  localStorage.getItem(appValues(siteName).profileDialog);

export const profileDialog = (siteName) => {
  localStorage.setItem(appValues(siteName).profileDialog, true);
};

export const profileNeedsValidation = (siteName) => {
  localStorage.setItem(appValues(siteName).profileNeedsValidation, true);
};

export const validateProfileRequired = (siteName) =>
  localStorage.getItem(appValues(siteName).profileNeedsValidation);

export const profileValidationPerformed = (siteName) => {
  localStorage.removeItem(appValues(siteName).profileNeedsValidation);
};

export const rememberMe = (siteName) => {
  localStorage.setItem(appValues(siteName).remember, true);
};

export const doYouRememberMe = (siteName) => localStorage.getItem(appValues(siteName).remember);

export const dontRememberMe = (siteName) => {
  localStorage.removeItem(appValues(siteName).remember);
};

export const writeProfile = (profile, siteName, passPhrase = '') => {
  try {
    const dataToSave = JSON.stringify(profile);
    const encrypted = CryptoES.AES.encrypt(dataToSave, String(passPhrase));
    localStorage.setItem(appValues(siteName).userProfile, encrypted);
  } catch (err) {
    console.error('Failed to store profile data into local storage.');
  }
};

export const readProfile = (siteName, passPhrase = '') => {
  const ciphertext = localStorage.getItem(appValues(siteName).userProfile);
  if (ciphertext) {
    try {
      const bytes = CryptoES.AES.decrypt(ciphertext.toString(), String(passPhrase));
      const plaintext = bytes.toString(CryptoES.enc.Utf8);
      const dataToLoad = JSON.parse(plaintext);
      // console.log('dataToLoad', dataToLoad);
      return dataToLoad;
    } catch (err) {
      console.error('Failed to load profile data from local storage.');
      deleteProfile(siteName);
      return false;
    }
  }
  return false;
};

export const deleteProfile = (siteName) => {
  localStorage.removeItem(appValues(siteName).userProfile);
};

// TODO: Tempory fix for french title translation
export const tempProfileValueCheckForFr = (savedProfile) => {
  if (typeof savedProfile.state !== 'undefined' && savedProfile.state === 'Québec') {
    savedProfile.state = 'Quebec';
  }
  if (typeof savedProfile.title !== 'undefined') {
    if (savedProfile.title === 'Mr.' || savedProfile.title === 'M.') {
      savedProfile.title = 'Mr';
    }
    if (savedProfile.title === 'Mme.' || savedProfile.title === 'Mrs.') {
      savedProfile.title = 'Mrs';
    }
  }
  return savedProfile;
};
