import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const notFound = ({ strings }) => (
  <Fragment>
    <h2>{strings['labels/notFound']}</h2>
  </Fragment>
);

notFound.propTypes = {
  strings: PropTypes.object.isRequired,
};

export default notFound;
