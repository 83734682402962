/**
 * @class Site
 * load sites data
 * Validate selected site
 * Get site config (e.g. auth0 config and customisation)
 * Generate a dynamic manifest file
 * Define app's behaviours before app init
 */
import { focusEndpoint } from './utils/utilities';

class Site {
  constructor(siteName = '') {
    this.manifestlink = document.querySelector('#manifest');
    this.splashScreen = document.querySelector('#splashScreen');
    this.appName = 'Viewpoint';
    this.productName = 'ViewpointCollector';
    this.name = siteName;
    this.data = null;
    this.appUrl = window.location.origin;
    this.focusApi = focusEndpoint;
    this.language = null;
    this.strings = {};
    this.forms = {};
    this.allSites = [];
    this.manifestLoaded = false;
    this.manifest = {
      short_name: this.appName,
      name: this.appName,
      theme_color: '#000000',
      background_color: '#ffffff',
      start_url: this.appUrl,
      display: 'standalone',
      orientation: 'portrait',
      icons: [],
    };
    this.iconImagesSet = [
      {
        src: '144png',
        sizes: '144x144',
        type: 'image/png',
      },
      {
        src: '192png',
        sizes: '192x192',
        type: 'image/png',
      },
      {
        src: '512png',
        sizes: '512x512',
        type: 'image/png',
      },
    ];
  }

  // staging switch
  getViewpointCollectorApi = () => {
    const protocol = process.env.REACT_APP_ENVIRONMENT === 'local' ? 'http' : 'https';
    if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
      return `${process.env.REACT_APP_COLLECTOR_API_URL}/`;
    }
    return this.data
      ? `${protocol}://${this.data.frontEndServer}/`
      : `${process.env.REACT_APP_COLLECTOR_API_URL}/`;
  };

  getFetchLocaleUrl = (locale) =>
    `${this.getViewpointCollectorApi()}${this.name}?response=json&locale=${locale}`;

  getData = (apiEndpoint, type = 'GET', params = '') =>
    new Promise((resolve, reject) => {
      const xmlhttp = new XMLHttpRequest();
      xmlhttp.onreadystatechange = () => {
        if (xmlhttp.readyState === XMLHttpRequest.DONE) {
          if (xmlhttp.status === 200) {
            try {
              const result = JSON.parse(`${xmlhttp.responseText}`);
              resolve(result);
            } catch (e) {
              console.log('Invalid JSON', e);
              // eslint-disable-next-line
              reject('Invalid JSON');
            }
          } else {
            // eslint-disable-next-line
            reject('Failed to Get Data');
          }
        }
      };
      try {
        xmlhttp.open(type, apiEndpoint, true);
        if (type === 'POST' && typeof params === 'object') {
          const formData = new FormData();
          Object.keys(params).forEach((key) => {
            formData.append(key, params[key]);
          });
          xmlhttp.send(formData);
        } else {
          xmlhttp.send();
        }
      } catch (e) {
        console.log(e);
        // eslint-disable-next-line
        reject('Invalid URL');
      }
    });

  validate = (data) =>
    new Promise((resolve, reject) => {
      if (typeof data.sites !== 'undefined') {
        const siteFound = data.sites.find((config) => config.site_name === this.name);
        if (siteFound) {
          this.data = this.prepareSiteData(siteFound);
        }
        this.allSites = data.sites;
        resolve();
      } else {
        // eslint-disable-next-line
        reject('Site Data Missing');
      }
    });

  prepareSiteData = (data) =>
    // Logic: If auth0 client ID is available then social login is available
    // If require_valid_user = true then it’s “Required”
    ({
      name: data.site_name || null,
      title: data.location_name || null,
      productId: data.deployed_product_id || 0,
      auth0Endpoint: data.auth0_endpoint || null,
      frontEndServer: data.front_end_server || null,
      serverName: data.server_name || null,
      viewpointCollectorName: 'ViewpointCollector',
      theme: null,
      login: null,
      config: null,
      defaultLocale: null,
    });

  addAdditionalSiteConfig = (data) => {
    // add additional config items which we get from productinfo api (get_site)
    if (data) {
      this.data.login = data.config.login; // mapping for easier use
      this.data.login.auth0Domain = data.config.login.auth0_endpoint.replace('https://', '');
      this.data.complaintsform = data.config.complaintsform;
      this.data.lastUpdateDate = data.config.pwa.lastUpdateDate;
      this.data.theme = {
        appbarColor: data.config.pwa.color.appbar || '#00bcd4',
        appbarTitleColor: data.config.pwa.color.title || '#ffffff',
        backgroundColor: data.config.pwa.color.background || '#ffffff',
        headerColor: data.config.pwa.color.header || '#212121',
        subHeaderColor: data.config.pwa.color.subHeader || '#757575',
      };
      this.data.history = data.config.pwa.history;
      this.data.correspondence = data.config.pwa.correspondence;
      this.data.doublepickers = data.config.pwa.doublepickers;
      this.addCustomisation();
      if (data.config && data.config.airport && data.config.airport.map) {
        this.data.airportLat = data.config.airport.map.latitude;
        this.data.airportLong = data.config.airport.map.longitude;
      }
    }
  };

  getAllTranslations = (focusStrings) =>
    new Promise((resolve, reject) => {
      if (
        this.data.config &&
        typeof this.data.config.locales === 'object' &&
        this.data.config.locales.length > 0
      ) {
        let defaultLocal = null;
        // api has a bug, doesn't set the default local correctly so we have to do a bit of work here
        Object.keys(this.data.config.localeList).forEach((key) => {
          if (this.data.config.localeList[key] === 'English') defaultLocal = key;
        });
        this.data.defaultLocale =
          defaultLocal || this.data.config.locales[this.data.config.locales.length - 1];
        // TODO: add multi language support when it's tested
        this.data.multipleLang = this.data.config.locales.length > 1;
        // //////////////////////////// ///////////////////// /////////////////////////////////// //
        if (this.data.config.locales.length >= 1) {
          const promises = [];
          const locals = this.data.config.locales;
          locals.forEach((locale) => {
            promises.push(this.getData(this.getFetchLocaleUrl(locale)));
          });
          Promise.all(promises)
            .then((response) => {
              response.forEach((dataSet, index) => {
                if (dataSet && dataSet.strings && dataSet.field_defs) {
                  this.strings[locals[index]] = dataSet.strings;
                  this.forms[locals[index]] = dataSet.field_defs;
                  if (
                    typeof dataSet.field_defs.remember !== 'undefined' &&
                    typeof dataSet.field_defs.remember.label !== 'undefined'
                  ) {
                    this.strings[locals[index]].app = dataSet.field_defs.remember.label;
                  }
                  // focus strings
                  this.strings[locals[index]].focusStrings = focusStrings;
                }
              });
              delete this.data.config.strings;
              delete this.data.config.field_defs;
              resolve(this.strings);
            })
            .catch((err) => {
              // eslint-disable-next-line
              reject('Failed to get multiple translation strings');
            });
        } else {
          this.strings[this.data.config.locale] = this.data.config.strings;
          this.forms[this.data.config.locale] = this.data.config.field_defs;
          if (
            typeof this.data.config.field_defs.remember !== 'undefined' &&
            typeof this.data.config.field_defs.remember.label !== 'undefined'
          ) {
            this.strings[this.data.config.locale].app = this.data.config.field_defs.remember.label;
            // focus strings
            this.strings[this.data.config.locale].focusStrings = focusStrings;
          }
          delete this.data.config.strings;
          delete this.data.config.field_defs;
          resolve(this.strings);
        }
      } else {
        // eslint-disable-next-line
        reject('Failed to get translation strings');
      }
    });

  getImages = (data) =>
    new Promise((resolve, reject) => {
      if (data) {
        resolve(data);
      } else {
        // eslint-disable-next-line
        reject('Site Images Missing');
      }
    });

  airportCode = (string) => string.substring(0, 3).toUpperCase();

  prepareManifest = () => {
    if (this.data) {
      const {
        name,
        theme: { appbarColor, backgroundColor },
      } = this.data;
      this.manifest.name = this.airportCode(name || this.appName);
      this.manifest.short_name = this.airportCode(name || this.appName);
      this.manifest.theme_color = appbarColor;
      this.manifest.background_color = backgroundColor;
      this.manifest.start_url = `${this.appUrl}/${this.name}?source=pwa`;
      this.manifest.icons = this.iconImagesSet.map((image) => {
        image.src = `${this.focusApi}/productinfo?action=get_object&key=pwa/${image.src}&sitename=${this.name}&product=${this.productName}`;
        return image;
      });
    }
    return this.manifest;
  };

  manifestPath = (jsonManifest) =>
    new Promise((resolve, reject) => {
      try {
        const stringManifest = JSON.stringify(jsonManifest);
        const blob = new Blob([stringManifest], {
          type: 'application/javascript',
        });
        const manifestURL = URL.createObjectURL(blob);
        resolve(manifestURL);
      } catch (e) {
        console.log(e);
        // eslint-disable-next-line
        reject('Failed to Generate the Manifest File');
      }
    });

  addAppleIcons = () => {
    document
      .querySelector('#appleTouchIcon')
      .setAttribute(
        'href',
        `${this.focusApi}/productinfo?action=get_object&key=pwa/192png&sitename=${this.name}&product=${this.productName}`
      );
    document
      .querySelector('#appleTouchStartupImage')
      .setAttribute(
        'href',
        `${this.focusApi}/productinfo?action=get_object&key=pwa/512png&sitename=${this.name}&product=${this.productName}`
      );
  };

  addCustomisation = () => {
    this.addAppleIcons(); // add apple icons
    const ring = document.querySelector('.ring');
    if (this.data && ring) {
      ring.style.borderColor = `${this.data.theme.appbarColor} transparent ${this.data.theme.appbarColor} transparent`;
    }
  };
}

export default Site;
