import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import FlatButton from 'material-ui/FlatButton';
import Input from 'material-ui/svg-icons/action/input';
import Clear from 'material-ui/svg-icons/content/clear';
import { path } from '../utils/utilities';
import { isLoginSkipped } from '../utils/auth0';

const styles = {
  button: { marginTop: 5, color: '#FFF' },
};

const AccountButton = (props) => {
  let icon = null;
  if (isLoginSkipped(props.site)) {
    icon = (
      <FlatButton
        style={styles.button}
        label={props.strings['complaintsform/headings/signIn']}
        icon={<Input />}
        containerElement={<Link to={`/${props.site.name}${path.goToLogin}`} />}
      />
    );
  } else if (props.site.login.behaviour !== 'None') {
    icon = (
      <FlatButton
        style={styles.button}
        label={props.strings['complaintsform/headings/signOut']}
        icon={<Clear />}
        containerElement={<Link to={`/${props.site.name}${path.logout}`} />}
      />
    );
  }
  return icon;
};

AccountButton.propTypes = {
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
};

export default AccountButton;
