/* eslint-disable react/no-unknown-property */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import AppBar from 'material-ui/AppBar';
import IconButton from 'material-ui/IconButton';
import NavigationChevronLeft from 'material-ui/svg-icons/navigation/chevron-left';
import NavigationChevronRight from 'material-ui/svg-icons/navigation/chevron-right';
import ActionLanguage from 'material-ui/svg-icons/action/language';
import { List, ListItem } from 'material-ui/List';
import Snackbar from 'material-ui/Snackbar';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { Redirect, Link } from 'react-router-dom';
import AppNavigation from './AppNavigation';
import Header from './Header';
import {
  path,
  isMobile,
  localeSelectionRequired,
  defineLocale,
  updateBuild,
  displayDateTime,
  appendAccessibility,
  CSVToArray,
} from '../utils/utilities';
import { isAuthenticated, isLoginSkipped } from '../utils/auth0';
import { readProfile, writeProfile, deleteProfile } from '../utils/profile';
import { readData, writeData } from '../utils/data';

const styles = {
  appBar: { fontSize: '18px' },
  list: { listStyle: 'none', lineHeight: '22px', margin: 0, paddingLeft: 0 },
  dialog: { maxWidth: 320 },
  container: {},
  listItem: { padding: '16px 5px' },
  listItemMobile: { padding: 16 },
  submitNowMessage: {
    padding: '16px 0',
    fontWeight: 'bolder',
    fontSize: '14px',
  },
};

class Settings extends Component {
  state = {
    clearProfileNotify: false,
    resetAppDialog: false,
    mobile: isMobile(),
    submitNowOptionsDialog: false,
    selectedSubmitNowOptions:
      readData(
        'submitNowOptions',
        this.props.site.name,
        !!(
          window.location.hostname === 'localhost' ||
          window.location.hostname.search('staging') !== -1
        )
      ) || [],
  };

  componentDidMount() {
    if (this.goBackBtn && this.goBackBtn.button) {
      const goBackBtnNode = this.goBackBtn.button.button;
      appendAccessibility(goBackBtnNode, 'aria-label', 'goback');
    }
    if (
      this.downBtn &&
      this.downBtn.firstElementChild &&
      this.downBtn.firstElementChild.firstElementChild
    ) {
      const downBtnNode =
        this.downBtn.firstElementChild.firstElementChild.firstElementChild.firstElementChild;
      appendAccessibility(downBtnNode, 'aria-label', 'dropdown');
    }
    this.checkLayout();
    window.addEventListener('resize', this.checkLayout);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkLayout);
  }

  checkLayout = () => {
    if (this.state.mobile !== isMobile()) this.setState({ mobile: isMobile() });
  };

  tempProfile = null;

  clearProfile = () => {
    const { name: siteName, productId } = this.props.site;
    this.tempProfile = readProfile(siteName, productId);
    deleteProfile(this.props.site.name);
    this.setState({
      clearProfileNotify: true,
    });
    this.props.clearProfile(true);
  };

  restoreProfile = () => {
    const { name: siteName, productId } = this.props.site;
    if (this.tempProfile) writeProfile(this.tempProfile, siteName, productId);
    this.setState({ clearProfileNotify: false });
    this.props.clearProfile(false);
  };

  clearProfileClosed = () => {
    this.tempProfile = null;
    this.setState({ clearProfileNotify: false });
  };

  resetAppDialog = () => {
    this.setState({ resetAppDialog: true });
  };

  closeResetAppDialog = () => {
    this.setState({ resetAppDialog: false });
  };

  toggleSubmitNowOptionsDialog = () => {
    this.setState({
      submitNowOptionsDialog: !this.state.submitNowOptionsDialog,
    });
  };

  resetApp = () => {
    localStorage.clear();
    console.log('local storage cleared');
    updateBuild(this.props.site.app.build);
    window.location.reload(true);
  };

  handleOptionClick = (eventType) => {
    const siteName = this.props.site.name;
    const { selectedSubmitNowOptions } = this.state;
    const staging = !!(
      window.location.hostname === 'localhost' || window.location.hostname.search('staging') !== -1
    );

    let updatedSelectedSubmitNowOptions = selectedSubmitNowOptions;

    // Not in list, add
    if (!selectedSubmitNowOptions.includes(eventType)) {
      // Max of 4 items allowed
      if (selectedSubmitNowOptions.length >= 4) {
        updatedSelectedSubmitNowOptions = selectedSubmitNowOptions.slice(1);
      }
      updatedSelectedSubmitNowOptions.push(eventType);
    }
    // In list, remove first item and add
    else {
      updatedSelectedSubmitNowOptions = selectedSubmitNowOptions.filter(
        (event) => event !== eventType
      );
    }
    this.setState({
      selectedSubmitNowOptions: updatedSelectedSubmitNowOptions,
    });
    writeData('submitNowOptions', updatedSelectedSubmitNowOptions, siteName, staging);
  };

  renderSubmitNowButtonSettings = () => {
    const availableEventTypes = CSVToArray(this.props.strings['complaintsform/lists/event_types']);
    const { selectedSubmitNowOptions } = this.state;
    return (
      <div>
        <div style={styles.submitNowMessage}>
          <span> {this.props.strings['labels/submitNowSelectionCount']}</span>
        </div>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            margin: '-5px',
          }}>
          {availableEventTypes.map((event) => (
            <div
              key={event}
              style={{
                boxSizing: 'border-box',
                padding: '5px',
                width: '50%',
              }}>
              <button
                className={`submitNowOptionButton ${
                  !!selectedSubmitNowOptions.includes(event) && 'selected'
                }`}
                onClick={() => {
                  this.handleOptionClick(event);
                }}
                primary={selectedSubmitNowOptions.includes(event)}
                fullWidth>
                {event}
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    const {
      name: siteName,
      app: { build },
      multipleLang,
      config: { localeList },
      theme: { appbarColor },
    } = this.props.site;
    const { strings } = this.props;
    const pageTitle = strings['labels/settings'];
    const privacyStatement = strings['complaintsform/blocks/privacyStatement'];
    const currentLocale = defineLocale(this.props.site);
    const lastUpdated = this.props.strings['labels/lastUpdated'];

    const updatedOn = `${lastUpdated}: ${displayDateTime(new Date(Number(build)))}`;
    const actionButtons = [
      <FlatButton label={strings['labels/cancel']} primary onClick={this.closeResetAppDialog} />,
      <FlatButton label={strings['labels/resetButton']} onClick={this.resetApp} />,
    ];
    const submitNowActionButtons = [
      <FlatButton
        label={strings['labels/close']}
        primary
        onClick={this.toggleSubmitNowOptionsDialog}
      />,
    ];
    const appVersion = `${strings['labels/version']} ${this.props.site.app.version}`;
    // is language selection required?
    if (localeSelectionRequired(this.props.site))
      return <Redirect to={{ pathname: `/${siteName}${path.language}` }} />;
    if (isAuthenticated(this.props.site)) {
      const check = this.props.isAppJustLoaded();
      if (!this.props.newProfileLoaded && !isLoginSkipped(this.props.site)) {
        return <Redirect to={{ pathname: `/${this.props.site.name}${path.getProfileInfo}` }} />;
      }
      if (
        check.isProfileValid &&
        this.props.site.complaintsform.openComplaintFormOnLaunch === '1'
      ) {
        return <Redirect to={{ pathname: `/${this.props.site.name}${path.compose}` }} />;
      }

      return (
        <Fragment>
          {this.state.mobile ? (
            <AppBar
              title={pageTitle}
              style={styles.appBar}
              onLeftIconButtonClick={() => {
                window.history.back();
              }}
              iconElementLeft={
                <IconButton
                  ref={(goBackBtn) => {
                    this.goBackBtn = goBackBtn;
                  }}>
                  <NavigationChevronLeft />
                </IconButton>
              }
            />
          ) : (
            <AppNavigation site={this.props.site} strings={this.props.strings} title={pageTitle} />
          )}
          <div className={this.state.mobile ? 'none-flex-container' : 'flex-container'}>
            {!this.state.mobile && (
              <Header site={this.props.site} strings={this.props.strings} page={path.settings} />
            )}
            <div
              ref={(downBtn) => {
                this.downBtn = downBtn;
              }}
              style={!this.state.mobile ? styles.container : {}}>
              <List>
                {multipleLang && (
                  <ListItem
                    key="2"
                    primaryText={this.props.strings['labels/settings']}
                    disabled
                    primaryTogglesNestedList
                    innerDivStyle={!this.state.mobile ? styles.listItem : styles.listItemMobile}
                    nestedItems={Object.keys(localeList).map((locale) => (
                      <ListItem
                        key={locale}
                        primaryText={localeList[locale]}
                        leftIcon={
                          <ActionLanguage color={currentLocale === locale ? appbarColor : null} />
                        }
                        style={currentLocale === locale ? { color: appbarColor } : null}
                        onClick={() => {
                          this.props.changeLanguage(siteName, locale);
                        }}
                      />
                    ))}
                  />
                )}
                <ListItem
                  primaryText={appVersion}
                  innerDivStyle={!this.state.mobile ? styles.listItem : styles.listItemMobile}
                  disabled
                />
                {updatedOn && (
                  <ListItem
                    primaryText={updatedOn}
                    innerDivStyle={!this.state.mobile ? styles.listItem : styles.listItemMobile}
                    disabled
                  />
                )}
                {this.props.site.config.displaySubmitNowForm === true && (
                  <ListItem
                    primaryText={this.props.strings['labels/submitNowOptions']}
                    innerDivStyle={!this.state.mobile ? styles.listItem : styles.listItemMobile}
                    onClick={this.toggleSubmitNowOptionsDialog}
                    rightIcon={<NavigationChevronRight />}
                  />
                )}
              </List>
              {privacyStatement && (
                <ListItem
                  primaryText={this.props.strings['complaintsform/headings/privacyStatement']}
                  innerDivStyle={!this.state.mobile ? styles.listItem : styles.listItemMobile}
                  rightIcon={<NavigationChevronRight />}
                  containerElement={<Link to={`/${siteName}${path.privacy}/review`} />}
                />
              )}
              <ListItem
                primaryText={this.props.strings['labels/resetApp']}
                innerDivStyle={!this.state.mobile ? styles.listItem : styles.listItemMobile}
                onClick={this.resetAppDialog}
                rightIcon={<NavigationChevronRight />}
              />
            </div>
          </div>
          <Snackbar
            open={this.state.clearProfileNotify}
            message={strings['labels/profileCleared']}
            action="undo"
            autoHideDuration={10000}
            onActionClick={this.restoreProfile}
            onRequestClose={this.clearProfileClosed}
            contentStyle={{ fontSize: 15 }}
          />
          <Dialog
            title={`${strings['labels/resetApp']}?`}
            actions={actionButtons}
            modal
            open={this.state.resetAppDialog}
            contentStyle={styles.dialog}>
            <ul style={styles.list}>
              <li>{strings['labels/deleteAllStoredData']}</li>
              <li>{strings['labels/removeLoginDetails']}</li>
              <li>{strings['labels/resetUserPreferences']}</li>
            </ul>
          </Dialog>
          <Dialog
            title={this.props.strings['labels/submitNowOptions']}
            actions={submitNowActionButtons}
            modal
            open={this.state.submitNowOptionsDialog}
            autoScrollBodyContent>
            {this.renderSubmitNowButtonSettings()}
          </Dialog>
        </Fragment>
      );
    }
    return (
      <Redirect
        to={{
          pathname: `/${siteName}${path.login}`,
        }}
      />
    );
  }
}

Settings.propTypes = {
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  clearProfile: PropTypes.func.isRequired,
  isAppJustLoaded: PropTypes.func.isRequired,
  newProfileLoaded: PropTypes.string.isRequired,
};

export default Settings;
