import React, { Component } from 'react';
import axios from 'axios';
import Auth0Lock from 'auth0-lock';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import AppShell from './AppShell';
import Home from './Home';
import Profile from './Profile';
import GetProfileInfo from './GetProfileInfo';
import Compose from './Compose';
import SubmitNow from './SubmitNow';
import Settings from './Settings';
import History from './History';
import Correspondence from './Correspondence';
import HistoryAttachment from './HistoryAttachment';
import PrivacyStatement from './PrivacyStatement';
import Login from '../auth/Login';
import Reset from './Reset';
import Language from './Language';
import SkipLogin from '../auth/SkipLogin';
import GoToLogin from '../auth/GoToLogin';
import Logout from '../auth/Logout';
import NotFound from './NotFound';
import {
  path,
  acceptedPrivacyTerms,
  localeSelected,
  defineLocale,
  defineFields,
  defineValues,
  getViewpointCollectorUrl,
  getViewpointApiUrl,
  mapping,
  log,
} from '../utils/utilities';
import {
  readProfile,
  writeProfile,
  isProfileCompleted,
  tempProfileValueCheckForFr,
} from '../utils/profile';
import { checkAuthExpiry, getDetails, auth0Values, removeDetails } from '../utils/auth0';
import '../styles/App.css';

const mobileMinWidth = 1024;

class App extends Component {
  constructor(props) {
    super(props);
    log('App props', props);
    const { webTrakRootUrl } = props.site.config;
    const { name: siteName, productId } = props.site;
    const locale =
      this.props.site.config && this.props.site.config.locale
        ? this.props.site.config.locale
        : null;
    checkAuthExpiry(this.props.site.name);
    this.setValues(locale);
    this.appJustLoaded = true;
    let savedProfile = readProfile(siteName, productId);

    // TODO: fix french translation later
    if (savedProfile) {
      savedProfile = tempProfileValueCheckForFr(savedProfile);
    }
    if (savedProfile) {
      if (typeof savedProfile.state !== 'undefined' && savedProfile.state === 'Québec') {
        savedProfile.state = 'Quebec';
      }
      if (typeof savedProfile.title !== 'undefined') {
        if (savedProfile.title === 'Mr.' || savedProfile.title === 'M.') {
          savedProfile.state = 'Mr';
        }
        if (savedProfile.title === 'Mme.' || savedProfile.title === 'Mrs.') {
          savedProfile.state = 'Mrs';
        }
      }
    }

    const profileData = defineValues(this.define.profile);
    if (savedProfile) {
      // apply saved profile data if any
      Object.keys(savedProfile).forEach((key) => {
        if (typeof profileData[key] !== 'undefined' && savedProfile[key]) {
          profileData[key] = savedProfile[key];
        }
      });
    }

    this.state = {
      strings: this.strings,
      profile: profileData,
      clearProfile: false,
      geoLocationBlocked: false,
      geolocation: [0, 0],
      locationAddress: [],
      complaint: defineValues(this.define.complaint),
      newProfileLoaded: '',
      mobile: true,
      webTrakRootUrl,
    };
  }

  componentDidMount() {
    this.isMobile();
    window.addEventListener('resize', this.isMobile);
  }

  setValues = (locale = null) => {
    this.locale = defineLocale(this.props.site) || locale;
    this.strings = this.props.strings[this.locale];
    log('\t this.strings:', this.strings);
    this.forms = this.props.forms[this.locale];
    if (this.forms && this.forms.responserequired) {
      console.log('Forcing responserequired to complaint form');
      this.forms.responserequired.scope = 'complaint';
    }
    this.defaultValues = [];
    if (this.props.site.complaintsform.default_values) {
      try {
        this.defaultValues = JSON.parse(this.props.site.complaintsform.default_values);
      } catch (e) {
        console.error(e);
      }
    }
    this.define = {
      profile: defineFields(
        this.props.site.config,
        this.forms,
        this.strings,
        this.defaultValues,
        'profile'
      ),
      complaint: defineFields(
        this.props.site.config,
        this.forms,
        this.strings,
        this.defaultValues,
        'complaint'
      ),
    };
    log('setValues: ', {
      locale,
      site: this.props.site,
      strings: this.strings,
      forms: this.forms,
      define_profile: this.define.profile,
    });
  };

  getSubmitKey = () =>
    new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: `${getViewpointCollectorUrl(this.props.site)}${this.props.site.name}?response=json`,
      })
        .then((response) => {
          if (
            response &&
            typeof response.data !== 'undefined' &&
            typeof response.data.submitKey !== 'undefined'
          ) {
            resolve(response.data.submitKey);
          }
          reject(response);
        })
        .catch((response) => {
          log('getSubmitKey: response', response);
          reject(response);
        });
    });

  isMobile = () => {
    if (window.innerWidth < mobileMinWidth && !this.state.mobile) {
      this.setState({ mobile: true });
    } else if (window.innerWidth >= mobileMinWidth && this.state.mobile) {
      this.setState({ mobile: false });
    }
  };

  isAppJustLoaded = () => {
    let isPrivacyAccepted = true;
    // check privacy statement
    if (this.props.site.complaintsform.require_terms_of_use === '1') {
      isPrivacyAccepted =
        acceptedPrivacyTerms(this.props.site.name) ===
        this.strings['complaintsform/blocks/privacyStatement'];
    }
    if (this.appJustLoaded) {
      this.appJustLoaded = false;
      // log('isAppJustLoaded: this.define.profile', this.define.profile);
      const isProfileValid = isProfileCompleted(this.state.profile, this.define.profile);
      return { isProfileValid, isPrivacyAccepted };
    }
    return { isPrivacyAccepted };
  };

  saveState = (key, data) => {
    const stateObject = {};
    stateObject[key] = data;
    this.setState(stateObject);
  };

  changeLanguage = (siteName, locale) => {
    log('changeLanguage(): siteName:', siteName, 'locale:', locale);
    localeSelected(siteName, locale);
    this.setValues(locale);
    this.setState({
      strings: this.strings,
      complaint: defineValues(this.define.complaint),
    });
  };

  clearProfile = (action) => {
    log('clearProfile');
    this.lodgerInfoChecked = false;
    const { name: siteName, productId } = this.props.site;
    const profileData = action
      ? defineValues(this.define.profile)
      : readProfile(siteName, productId) || defineValues(this.define.profile);
    setTimeout(() => {
      this.setState({ profile: profileData, newProfileLoaded: '' });
    }, 99);
  };

  checkLocationAddress = () => {
    if (this.props.site.complaintsform.html5_geolocation === '1') {
      navigator.geolocation &&
        navigator.geolocation.getCurrentPosition((position) => {
          this.setState({
            geolocation: [position.coords.latitude, position.coords.longitude],
          });

          axios({
            method: 'post',
            url: `${getViewpointCollectorUrl(this.props.site)}geocode/${
              this.props.site.name
            }/?direction=reverse&lat=${this.state.geolocation[0]}&lon=${this.state.geolocation[1]}`,
          })
            .then((response) => {
              this.setState({ locationAddress: response.data.results });
            })
            .catch((response) => {
              log(response);
            });
        });

      navigator.geolocation &&
        navigator.geolocation.watchPosition(
          // eslint-disable-next-line
          (position) => {
            this.setState({ geoLocationBlocked: false });
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) this.setState({ geoLocationBlocked: true });
          }
        );
    }
  };

  retrieveProfileData(accessToken = null) {
    this.auth0 = auth0Values(this.props.site.name);
    this.lock = new Auth0Lock(
      this.props.site.login.auth0clientId,
      this.props.site.login.auth0Domain,
      {
        auth: {
          redirect: false,
          responseType: this.auth0.responseType,
          sso: false,
        },
      }
    );
    return new Promise((resolve, reject) => {
      this.lock.getUserInfo(
        accessToken || localStorage.getItem(this.auth0.accessToken),
        (error, profile) => {
          if (!error) {
            resolve(profile.email);
          }
          // eslint-disable-next-line
          reject('Failed to Get profile');
        }
      );
    });
  }

  checkLodgerInfo = () => {
    const accessToken = getDetails(this.props.site.name, 'accessToken');
    const idToken = getDetails(this.props.site.name, 'idToken');
    if (accessToken && idToken && !this.lodgerInfoChecked) {
      this.retrieveProfileData(accessToken)
        .then((email) => {
          this.lodgerInfoChecked = true;
          axios({
            method: 'get',
            url: `${getViewpointApiUrl(this.props.site)}wc/lodger/from_token`,
            headers: {
              Authorization: `Bearer ${idToken}`,
              'X-Clientid': this.props.site.login.auth0clientId,
              'X-Productname': 'ViewpointCollector',
              'X-Sitename': this.props.site.name,
            },
          })
            .then((response) => {
              // console.log('lodger/from_token: response.request.response', JSON.parse(response.request.response).data);
              const {
                data: { data: lodgerInfo },
              } = response;
              this.profileMapping(email, lodgerInfo);
            })
            .catch((error) => {
              if (error && error.response && error.response.status === 500) {
                removeDetails(this.props.site.name);
                window.location.href = `${window.location.origin}/${this.props.site.name}`;
              }
            });
        })
        .catch((error) => {
          removeDetails(this.props.site.name);
          window.location.href = `${window.location.origin}/${this.props.site.name}`;
        });
    } else {
      removeDetails(this.props.site.name);
      window.location.href = `${window.location.origin}/${this.props.site.name}`;
    }
  };

  profileMapping = (email, lodger) => {
    const { profile } = this.state;
    const { name: siteName, productId } = this.props.site;
    if (email) profile.email = email;
    if (lodger) {
      Object.keys(profile).forEach((key) => {
        if (typeof lodger[key] !== 'undefined' && lodger[key]) {
          profile[key] = lodger[key];
          delete lodger[key];
        }
      });
      Object.keys(mapping).forEach((index) => {
        mapping[index].forEach((key) => {
          if (typeof lodger[key] !== 'undefined' && lodger[key]) {
            profile[index] = lodger[key];
            delete lodger[key];
          }
        });
      });
    }
    const updateProfile = () => {
      this.appJustLoaded = true;
      // if local profile is saved - help to complete the profile
      // TODO: We should change this to save profile info in cloud even if lodger didn't submit a complaint
      const savedProfile = readProfile(siteName, productId);
      if (
        savedProfile &&
        typeof savedProfile.email !== 'undefined' &&
        savedProfile.email === profile.email
      ) {
        Object.keys(savedProfile).forEach((item) => {
          if (typeof profile[item] !== 'undefined') {
            profile[item] = savedProfile[item];
          }
        });
      }
      writeProfile(profile, siteName, productId);
      this.setState({ profile, newProfileLoaded: profile.email });
    };
    if (typeof profile.lat === 'undefined' || typeof profile.lon === 'undefined') {
      this.searchAddress(profile)
        .then((address) => {
          if (typeof address !== 'undefined' && address.length > 0) {
            profile.lat = address[0].lat;
            profile.lon = address[0].lon;
            profile.elevation = address[0].elevation;
          }
          updateProfile(profile);
        })
        .catch((error) => {
          updateProfile(profile);
        });
    } else {
      updateProfile(profile);
    }
  };

  searchAddress(profile) {
    log('App.js: searchAddress');
    return new Promise((resolve, reject) => {
      const { address1, city, zipcode } = profile;
      const address = `${address1} ${city} ${zipcode}`;
      axios({
        method: 'post',
        url: `${getViewpointCollectorUrl(this.props.site)}geocode/${
          this.props.site.name
        }?address=${address}`,
      })
        .then((response) => {
          resolve(response.data.results);
        })
        .catch((response) => {
          reject(response);
        });
    });
  }

  render() {
    const { strings } = this.state;
    return (
      <AppShell site={this.props.site} mobile={this.state.mobile} strings={strings}>
        <Switch>
          <Route
            exact
            path={`/:siteId${path.home}`}
            render={() => (
              <Home
                site={this.props.site}
                strings={this.state.strings}
                isAppJustLoaded={this.isAppJustLoaded}
                newProfileLoaded={this.state.newProfileLoaded}
              />
            )}
          />
          <Route
            exact
            path={`/:siteId${path.language}`}
            render={() => (
              <Language
                site={this.props.site}
                strings={strings}
                changeLanguage={this.changeLanguage}
              />
            )}
          />
          <Route
            exact
            path={`/:siteId${path.login}`}
            render={() => <Login site={this.props.site} strings={strings} />}
          />
          <Route
            exact
            path={`/:siteId${path.getProfileInfo}`}
            render={() => (
              <GetProfileInfo
                site={this.props.site}
                strings={strings}
                newProfileLoaded={this.state.newProfileLoaded}
                checkLodgerInfo={this.checkLodgerInfo}
              />
            )}
          />
          <Route
            exact
            path={`/:siteId${path.skipLogin}`}
            render={() => <SkipLogin site={this.props.site} />}
          />
          <Route
            exact
            path={`/:siteId${path.goToLogin}`}
            render={() => <GoToLogin site={this.props.site} />}
          />
          <Route
            exact
            path={`/:siteId${path.profile}`}
            render={(routeProps) => (
              <Profile
                {...routeProps}
                site={this.props.site}
                isAppJustLoaded={this.isAppJustLoaded}
                strings={strings}
                defineProfile={this.define.profile}
                profileData={this.state.profile}
                clearProfile={this.state.clearProfile}
                newProfileLoaded={this.state.newProfileLoaded}
                checkLocationAddress={this.checkLocationAddress}
                geolocation={this.state.geolocation}
                geoLocationBlocked={this.state.geoLocationBlocked}
                locationAddress={this.state.locationAddress}
                saveState={(key, data) => {
                  this.saveState(key, data);
                }}
              />
            )}
          />
          <Route
            exact
            path={`/:siteId${path.compose}`}
            render={() => (
              <Compose
                site={this.props.site}
                isAppJustLoaded={this.isAppJustLoaded}
                strings={strings}
                defineProfile={this.define.profile}
                defineComplaint={this.define.complaint}
                profileData={this.state.profile}
                complaintData={this.state.complaint}
                getSubmitKey={this.getSubmitKey}
                newProfileLoaded={this.state.newProfileLoaded}
                checkLocationAddress={this.checkLocationAddress}
                geolocation={this.state.geolocation}
                saveState={(key, data) => {
                  this.saveState(key, data);
                }}
              />
            )}
          />
          <Route
            exact
            path={`/:siteId${path.submitNow}`}
            render={() => (
              <SubmitNow
                site={this.props.site}
                userProfile={this.state.profile}
                isAppJustLoaded={this.isAppJustLoaded}
                strings={strings}
                defineProfile={this.define.profile}
                defineComplaint={this.define.complaint}
                profileData={this.state.profile}
                complaintData={this.state.complaint}
                getSubmitKey={this.getSubmitKey}
                newProfileLoaded={this.state.newProfileLoaded}
                checkLocationAddress={this.checkLocationAddress}
                geolocation={this.state.geolocation}
                webTrakRootUrl={this.state.webTrakRootUrl}
                saveState={(key, data) => {
                  this.saveState(key, data);
                }}
              />
            )}
          />
          <Route
            exact
            path={`/:siteId${path.settings}`}
            render={() => (
              <Settings
                site={this.props.site}
                isAppJustLoaded={this.isAppJustLoaded}
                strings={strings}
                changeLanguage={this.changeLanguage}
                clearProfile={this.clearProfile}
                newProfileLoaded={this.state.newProfileLoaded}
              />
            )}
          />
          <Route
            exact
            path={`/:siteId${path.history}`}
            render={() => (
              <History
                site={this.props.site}
                userProfile={this.state.profile}
                strings={strings}
                defineComplaint={this.define.complaint}
                siteName={this.props.site.name}
                isAppJustLoaded={this.isAppJustLoaded}
                newProfileLoaded={this.state.newProfileLoaded}
                geolocation={this.state.geolocation}
                webTrakRootUrl={this.state.webTrakRootUrl}
              />
            )}
          />
          <Route
            exact
            path={`/:siteId${path.history}/:submissionId`}
            render={(props) => (
              <HistoryAttachment
                site={this.props.site}
                strings={strings}
                siteName={this.props.site.name}
                isAppJustLoaded={this.isAppJustLoaded}
                newProfileLoaded={this.state.newProfileLoaded}
                {...props}
              />
            )}
          />

          <Route
            exact
            path={`/:siteId${path.correspondence}`}
            render={(props) => (
              <Correspondence
                site={this.props.site}
                strings={strings}
                siteName={this.props.site.name}
                isAppJustLoaded={this.isAppJustLoaded}
                newProfileLoaded={this.state.newProfileLoaded}
                {...props}
              />
            )}
          />

          <Route
            exact
            path={`/:siteId${path.privacy}/:route`}
            render={() => <PrivacyStatement site={this.props.site} strings={strings} />}
          />
          <Route
            exact
            path={`/:siteId${path.logout}`}
            render={() => <Logout site={this.props.site} clearProfile={this.clearProfile} />}
          />
          <Route
            exact
            path={`/:siteId${path.reset}/:route`}
            render={() => <Reset site={this.props.site} />}
          />
          <Route component={NotFound} strings={strings} />
        </Switch>
      </AppShell>
    );
  }
}

App.propTypes = {
  site: PropTypes.object.isRequired,
  forms: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const EnhancedApp = withRouter(App);

const AppRouter = (props) => (
  <Router>
    <EnhancedApp {...props} />
  </Router>
);

export default AppRouter;
