import styled from 'styled-components';
import { STYLES } from '../styles';

const FormLabel = styled.label`
  margin-top: 0.5rem;
  color: ${STYLES.LABEL_COLOR};
  font-size: ${STYLES.LABEL_FONT_SIZE};
  display: inline-block;
`;

export { FormLabel };
