import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { path } from '../utils/utilities';
import { iWantToLogin } from '../utils/auth0';

class GoToLogin extends Component {
  render() {
    iWantToLogin(this.props.site.name);
    return (
      <Redirect
        to={{
          pathname: `/${this.props.site.name}${path.login}`,
        }}
      />
    );
  }
}

GoToLogin.propTypes = {
  site: PropTypes.object.isRequired,
};

export default GoToLogin;
