export const auth0Values = (siteName) => ({
  accessToken: `${siteName}_access_token`,
  idToken: `${siteName}_id_token`,
  expiresAt: `${siteName}_expires_at`,
  skipLogin: `${siteName}_skip_login`,
  userProfile: `${siteName}_user_profile`,
  responseType: 'token id_token',
  container: 'auth0Box',
});

export const isAuthenticated = (site) => {
  if (
    site.login.behaviour === 'None' ||
    (site.login.behaviour === 'Enable' && localStorage.getItem(auth0Values(site.name).skipLogin))
  )
    return true;
  // Check whether the current time is past the access token's expiry time
  let expiresAt = 0;
  try {
    expiresAt = JSON.parse(localStorage.getItem(auth0Values(site.name).expiresAt));
  } catch (err) {
    console.log(err);
    return false;
  }
  return new Date().getTime() < expiresAt;
};

/**
 * Check if authentication is mandatory on this site
 * @param site
 * @returns {boolean}
 */
export const needsAuthentication = (site) => {
  if (site.login.behaviour === 'Enable') return true;
  return false;
};

export const checkAuthExpiry = (siteName) => {
  let expiresAt = 0;
  try {
    expiresAt = JSON.parse(localStorage.getItem(auth0Values(siteName).expiresAt));
  } catch (err) {
    console.log(err);
    removeDetails(siteName);
    return;
  }
  const check = new Date().getTime() < expiresAt;
  if (!expiresAt || !check) removeDetails(siteName); // remove login details if it's expired
};

export const storeAccessTokenDetails = (siteName, authResult) => {
  const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
  localStorage.setItem(auth0Values(siteName).accessToken, authResult.accessToken);
  localStorage.setItem(auth0Values(siteName).expiresAt, expiresAt);
};

export const getDetails = (siteName, key) => localStorage.getItem(auth0Values(siteName)[key]);

export const removeDetails = (siteName) => {
  localStorage.removeItem(auth0Values(siteName).accessToken);
  localStorage.removeItem(auth0Values(siteName).idToken);
  localStorage.removeItem(auth0Values(siteName).expiresAt);
};

export const skipLogin = (siteName) => {
  localStorage.setItem(auth0Values(siteName).skipLogin, true);
};

export const iWantToLogin = (siteName) => {
  localStorage.removeItem(auth0Values(siteName).skipLogin);
};

export const isLoginSkipped = (site) => {
  if (site.login.behaviour === 'Enable' && localStorage.getItem(auth0Values(site.name).skipLogin))
    return true;
  return false;
};
