/* Disabled these linting rules for because fixing them made the 
  pie/bar charts weird with the colors and this isn't the highest priority
*/
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-shadow */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-unused-state */
import React, { Component, Fragment } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import moment from 'moment';
import { List } from 'material-ui/List';
// import Attachment from 'material-ui/svg-icons/file/attachment';
// import CommunicationMessage from 'material-ui/svg-icons/communication/message';
import ActionDateRange from 'material-ui/svg-icons/action/date-range';
import MapFlight from 'material-ui/svg-icons/maps/flight';
import MapPlace from 'material-ui/svg-icons/maps/place';
import ActionQuestionAnswer from 'material-ui/svg-icons/action/question-answer';
// import IconWebTrak from 'material-ui/svg-icons/maps/flight';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import { darkBlack } from 'material-ui/styles/colors';
import { ParentSize } from '@visx/responsive';
import { scaleOrdinal } from '@visx/scale';
import AppNavigation from './AppNavigation';
import Header from './Header';
import { ListItem } from './ListItem';
import { HistoryList } from './HistoryList';
import {
  path,
  isMobile,
  localeSelectionRequired,
  getViewpointApiUrl,
  formatSubmissionDateTime,
  log,
  getDaysBetweenDates,
  getSpecificDaysBetweenDates,
  getWeeksBetweenDates,
  generateColorPalette,
  defaultGraphColors,
} from '../utils/utilities';
import { readData, writeData } from '../utils/data';
import { isAuthenticated, getDetails, isLoginSkipped } from '../utils/auth0';
import ComplaintsBarStackGraph from './ComplaintsBarStackGraph';
import ComplaintsPieChart from './ComplaintsPieChart';

const isStaging =
  window.location.hostname === 'localhost' || window.location.hostname.search('staging') !== -1;

const styles = {
  list: {},
  listMobile: { padding: '8px 5px', textAlign: 'left' },
  graphCaption: { textAlign: 'center', fontSize: '22px' },
  complaintsGraphBackground: { padding: '0px 30px' },
  messagesList: { padding: '13px 16px 16px 55px', textAlign: 'left' },
  emptyList: { paddingLeft: 18, textAlign: 'center' },
  spinnerWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  dialogTitle: { fontSize: 15, fontWeight: 500, padding: '15px 24px 0px' },
  contentStyle: { maxWidth: 320 },
  description: { color: darkBlack },
  accessibilityButton: {
    margin: '1rem 0',
    height: '3rem',
    flexGrow: '1',
    textAlign: 'center',
    fontSize: '14px',
    fontFamily: '"Roboto", sans-serif',
    backgroundColor: '#7215D8',
    color: '#FFF',
  },
  externalLinksDiv: { textAlign: 'center' },
  centeredButtonContainer: { textAlign: 'center' },
};

class History extends Component {
  constructor(props) {
    super(props);
    const eventTypes = props.defineComplaint
      .find((item) => item.name === 'event_type')
      .options.map((item) => item.label);
    eventTypes.splice(eventTypes.indexOf(''), 1);
    eventTypes.sort();
    const userGraphColors = readData('userGraphColors', this.props.site.name, isStaging);
    const graphColorScale = scaleOrdinal({
      domain: eventTypes,
      range: userGraphColors || defaultGraphColors,
    });

    this.today = new Date();
    this.yesterday = new Date(this.today);
    this.yesterday.setDate(this.yesterday.getDate() - 1);

    this.state = {
      error: false,
      loading: true,
      dialog: false,
      open: false,
      submission: {},
      history: [],
      complaintsByDate: [],
      complaintsDataForBarStackGraph: {},
      whichDataToShow: 'weekly',
      graphColorScale,
      colorPalette: userGraphColors || defaultGraphColors,
      colorPalettes: [],
      colorPaletteIndex: -1,
      eventTypes,
      eventTypesFiltered: eventTypes,
      mobile: isMobile(),
      tipsMsg: 'It can take up to an hour for complaints to appear in the list',
      sundays: [],
    };
  }

  componentDidMount() {
    this.getSubmissions();

    this.checkLayout();
    window.addEventListener('resize', this.checkLayout);

    // for HPN3 mock data fix the accessibility issue -51, the right place in the future is in the AJAX call res
    document.querySelectorAll('hr').forEach((item) => {
      item.setAttribute('aria-hidden', true);
      item.style.borderBottom = '1px solid #949494';
      item.style.margin = '0';
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkLayout);
  }

  toggleDataToShow = () => {
    let currentState = this.state.whichDataToShow;
    if (currentState === 'daily') currentState = 'weekly';
    else currentState = 'daily';
    this.setState({ whichDataToShow: currentState });
  };

  doesPaletteHistoryExist = () => {
    const palettes = this.state.colorPalettes;
    if (palettes.length === 0) {
      return false;
    }
    return true;
  };

  gotoPreviousPalette = () => {
    const palettes = this.state.colorPalettes;
    if (palettes.length === 0) {
      console.log('No palettes yet');
      return;
    }
    const currentIndex = this.state.colorPaletteIndex;
    console.log('currentIndex:', currentIndex);
    const newIndex = currentIndex > 0 ? currentIndex - 1 : 0;
    console.log('newIndex:', newIndex);
    this.setState({ colorPaletteIndex: newIndex });
    const palette = this.state.colorPalettes[newIndex];
    if (palette) {
      this.setState({
        colorPalette: palette,
        graphColorScale: scaleOrdinal({
          domain: this.state.eventTypesFiltered,
          range: palette,
        }),
      });
    }
  };

  regenerateColorPalette = () => {
    const palette = generateColorPalette(this.state.eventTypesFiltered.length);
    const palettes = this.state.colorPalettes;
    palettes.push(palette);
    if (palettes.length > 10) {
      console.log('shifting');
      palettes.shift();
    }
    this.setState({ colorPalettes: palettes });
    this.setState({ colorPaletteIndex: palettes.length - 1 });
    console.log('palettes:', palettes);
    console.log('this.state.colorPaletteIndex:', this.state.colorPaletteIndex);
    writeData('userGraphColors', palette, this.props.site.name, isStaging);
    this.setState({
      colorPalette: palette,
      graphColorScale: scaleOrdinal({
        domain: this.state.eventTypesFiltered,
        range: palette,
      }),
    });
  };

  getSubmissions = () => {
    const idToken = getDetails(this.props.site.name, 'idToken');
    const { strings } = this.props;
    const unspecifiedEventTypeLabel = strings['labels/eventTypeUnspecified'] || 'Unspecified';
    if (idToken) {
      // const getSubmissionsUrl = `${getViewpointApiUrl(this.props.site)}wc/lodger/latest_submissions?timezone=${this.props.site.config.timezone || null}&limit=${Number(this.props.site.history.countLimit) || 50}&numberOfDays=${Number(this.props.site.history.pastNoOfDays) || 90}`;
      // const getSubmissionsUrl = `${getViewpointApiUrl(this.props.site)}wc/lodger/latest_submissions?timezone=${this.props.site.config.timezone || null}&limit=1000&numberOfDays=90`;
      const getSubmissionsUrl = `${getViewpointApiUrl(
        this.props.site
      )}wc/lodger/latest_submissions?timezone=${
        this.props.site.config.timezone || null
      }&limit=1000&numberOfDays=90`;
      axios({
        method: 'get',
        url: getSubmissionsUrl,
        headers: {
          Authorization: `Bearer ${idToken}`,
          'X-Clientid': this.props.site.login.auth0clientId,
          'X-Productname': 'ViewpointCollector',
          'X-Sitename': this.props.site.name,
        },
      })
        .then((response) => {
          if (!(response && response.data && response.data.data)) {
            console.log('getSubmissions response:', response);
            throw new Error('Bad response');
          }
          const complaintHistory = response.data.data;
          this.setState({
            loading: false,
            history: complaintHistory,
            filteredHistory: complaintHistory,
            historyByWeek: this.groupComplaintsByWeek(complaintHistory),
            complaintsDataForBarStackGraph: this.prepareBarStackGraphData(
              complaintHistory,
              unspecifiedEventTypeLabel
            ),
            complaintsByType: this.preparePieChartData(complaintHistory, unspecifiedEventTypeLabel),
          });
        })
        .catch((error) => {
          log('getSubmissions ERROR', error);
          this.setState({ error: true });
        });
    } else {
      log('ERROR: idToken is', idToken);
      this.setState({ error: true });
    }
  };

  checkLayout = () => {
    const isMobileDevice = isMobile();
    if (this.state.mobile !== isMobileDevice) this.setState({ mobile: isMobileDevice });
  };

  groupComplaintsByWeek = (complaints) => {
    const maxDate = moment().day(7);
    const minDate = moment().day(7).subtract(83, 'days');
    const weekRange = getWeeksBetweenDates(minDate, maxDate);
    const complaintsByWeek = {};
    for (const complaint of complaints) {
      const complaintWeek = moment(new Date(complaint.event_datetime)).format('YYYY-WW');
      const weekIndex = weekRange.findIndex((week) => week === complaintWeek);
      if (complaintsByWeek[weekIndex]) {
        complaintsByWeek[weekIndex].push(complaint);
      } else {
        complaintsByWeek[weekIndex] = [complaint];
      }
    }
    return complaintsByWeek;
  };

  prepareBarGraphData = (data) => {
    const complaintsByDate = {};
    let i = data.length;
    while (i--) {
      const dataItem = data[i];
      const complaintDate = moment(new Date(dataItem.event_datetime)).format('YYYY-MM-DD');
      if (complaintsByDate[complaintDate]) {
        complaintsByDate[complaintDate]++;
      } else {
        complaintsByDate[complaintDate] = 1;
      }
    }
    const maxDate = moment();
    const minDate = moment().subtract(90, 'days');
    const dateRange = getDaysBetweenDates(minDate, maxDate);
    const result = [];
    for (let i = 0, ii = dateRange.length; i < ii; i++) {
      const date = dateRange[i];
      const count = complaintsByDate[date] ? complaintsByDate[date] : 0;
      result.push({
        date,
        complaintsCount: count,
      });
    }
    return result;
  };

  prepareBarStackGraphData = (submissions, unspecifiedEventTypeLabel) => {
    const complaintsByDate = {};
    const complaintsByWeek = {};
    const complaintTypesObj = {};

    let i = submissions.length;
    while (i--) {
      const submission = submissions[i];
      const complaintType = submission.event_types.length
        ? submission.event_types[0]
        : unspecifiedEventTypeLabel;
      complaintTypesObj[complaintType] = 0;
    }
    const eventTypesFiltered = Object.keys(complaintTypesObj).sort();
    this.setState({ eventTypesFiltered });
    complaintTypesObj.total = 0;
    const LIMIT_GRAPH_LEGEND_TO_VISIBLE_EVENT_TYPES = true;
    if (LIMIT_GRAPH_LEGEND_TO_VISIBLE_EVENT_TYPES) {
      const graphColorScale = scaleOrdinal({
        domain: eventTypesFiltered,
        range: this.state.colorPalette,
      });
      this.setState({ graphColorScale });
    }

    i = submissions.length;
    while (i--) {
      const submission = submissions[i];
      const complaintDate = moment(new Date(submission.event_datetime)).format('YYYY-MM-DD');
      const complaintWeek = moment(new Date(submission.event_datetime)).format('YYYY-WW');
      complaintsByDate[complaintDate] = Object.assign({}, complaintTypesObj);
      complaintsByWeek[complaintWeek] = Object.assign({}, complaintTypesObj);
    }

    i = submissions.length;
    while (i--) {
      const submission = submissions[i];
      const complaintDate = moment(new Date(submission.event_datetime)).format('YYYY-MM-DD');
      const complaintWeek = moment(new Date(submission.event_datetime)).format('YYYY-WW');
      const complaintType = submission.event_types.length
        ? submission.event_types[0]
        : unspecifiedEventTypeLabel;
      complaintsByDate[complaintDate][complaintType]++;
      complaintsByDate[complaintDate].total++;
      complaintsByWeek[complaintWeek][complaintType]++;
      complaintsByWeek[complaintWeek].total++;
    }

    const maxDate = moment().day(7);
    const maxDateAlignedToNearestSunday = moment().day(7);
    const minDate = moment().day(7).subtract(83, 'days'); // 12 weeks
    const minDateDaily = moment().day(7).subtract(83, 'days'); // 12 weeks
    // const dateRange = getDaysBetweenDates(minDate, maxDate);
    const dateRangeDaily = getDaysBetweenDates(minDateDaily, maxDateAlignedToNearestSunday);

    const specificDays = getSpecificDaysBetweenDates(minDate, maxDate, 1);
    // console.log('specificDays:', specificDays);

    const dailyData = [];
    for (let i = 0, ii = dateRangeDaily.length; i < ii; i++) {
      const date = dateRangeDaily[i];
      const countObj = complaintsByDate[date]
        ? Object.assign({ date }, complaintsByDate[date])
        : Object.assign({ date }, complaintTypesObj);
      dailyData.push(countObj);
    }

    const weekRange = getWeeksBetweenDates(minDate, maxDateAlignedToNearestSunday);
    const weeklyData = [];
    for (let i = 0, ii = weekRange.length; i < ii; i++) {
      const week = weekRange[i];
      const countObj = complaintsByWeek[week]
        ? Object.assign({ date: week }, complaintsByWeek[week])
        : Object.assign({ date: week }, complaintTypesObj);
      weeklyData.push(countObj);
    }

    const barStackGraphData = {
      daily: dailyData,
      weekly: weeklyData,
      specificDays,
    };
    return barStackGraphData;
  };

  preparePieChartData = (submissions, unspecifiedEventTypeLabel) => {
    const complaintsByType = {};
    let i = submissions.length;
    while (i--) {
      const submission = submissions[i];
      const complaintTypes = submission.event_types;
      if (complaintTypes.length === 0) {
        if (complaintsByType[unspecifiedEventTypeLabel])
          complaintsByType[unspecifiedEventTypeLabel]++;
        else complaintsByType[unspecifiedEventTypeLabel] = 1;
      }
      for (let j = 0, jj = complaintTypes.length; j < jj; j++) {
        const complaintType = complaintTypes[j];
        if (complaintsByType[complaintType]) {
          complaintsByType[complaintType]++;
        } else {
          complaintsByType[complaintType] = 1;
        }
      }
    }
    // console.log('preparePieChartData: complaintsByType', complaintsByType);
    const types = Object.keys(complaintsByType);
    const pieChartData = [];
    for (let i = 0, ii = types.length; i < ii; i++) {
      const type = types[i];
      pieChartData.push({
        type,
        complaintsCount: complaintsByType[type],
      });
    }
    return pieChartData;
  };

  openDetails = (submission) => {
    submission.formattedAddress = Object.values(submission.submission_address)
      .filter((item) => item)
      .join(', ');
    this.setState({ dialog: true, submission });
  };

  displayHistoryList = () => {};

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  handleNestedListToggle = (item) => {
    this.setState({
      open: item.state.open,
    });
  };

  handleClose = () => {
    this.setState({ dialog: false });
  };

  filterHistoryByWeek = (filterData) => {
    if (filterData.dailyOrWeekly === 'daily') return;
    const weekIndex = filterData.index;
    const historyForWeek = this.state.historyByWeek[weekIndex];
    if (historyForWeek) this.setState({ filteredHistory: historyForWeek });
    else {
      console.log('this.state.historyByWeek:', this.state.historyByWeek);
      console.log('weekIndex:', weekIndex);
    }
  };

  render() {
    const { name: siteName } = this.props.site;
    const { strings } = this.props;
    const pageTitle = strings['labels/history'];
    const captionBarGraph = strings['labels/graphs/captionBarGraph'];
    const captionPieChart = strings['labels/graphs/captionPieChart'];
    const toggleDailyWeeklyButtonText = strings['labels/graphs/toggleDailyWeeklyButtonText'];
    const labelChangeColorPalette = strings['labels/graphs/changeColorPalette'];

    // is language selection required
    if (localeSelectionRequired(this.props.site))
      return <Redirect to={{ pathname: `/${siteName}${path.language}` }} />;
    if (!this.state.error && isAuthenticated(this.props.site)) {
      if (!this.props.newProfileLoaded && !isLoginSkipped(this.props.site)) {
        return <Redirect to={{ pathname: `/${siteName}${path.getProfileInfo}` }} />;
      }
      const check = this.props.isAppJustLoaded();
      if (!check.isPrivacyAccepted)
        return <Redirect to={{ pathname: `/${siteName}${path.privacy}/accept` }} />;
      if (
        check.isProfileValid &&
        this.props.site.complaintsform.openComplaintFormOnLaunch === '1'
      ) {
        return <Redirect to={{ pathname: `/${siteName}${path.compose}` }} />;
      }
      return (
        <Fragment>
          <AppNavigation site={this.props.site} strings={this.props.strings} title={pageTitle} />

          <div className={this.state.mobile ? 'none-flex-container' : 'flex-container'}>
            {!this.state.mobile && (
              <Header site={this.props.site} strings={this.props.strings} page={path.history} />
            )}

            {this.state.loading && (
              <div style={styles.spinnerWrapper}>
                <div className="spinner" />
              </div>
            )}

            {!this.state.loading && (
              <div id="complaintsBarStackGraph" style={styles.complaintsGraphBackground}>
                <ParentSize>
                  {(parent) => (
                    <ComplaintsBarStackGraph
                      isMobile={this.state.mobile}
                      width={parent.width}
                      height={200}
                      complaintsData={this.state.complaintsDataForBarStackGraph}
                      whichDataToShow={this.state.whichDataToShow}
                      colorScale={this.state.graphColorScale}
                      clickFunc={this.filterHistoryByWeek}
                    />
                  )}
                </ParentSize>
                <h2 style={styles.graphCaption}>{captionBarGraph}</h2>
                <div style={styles.centeredButtonContainer}>
                  <RaisedButton
                    primary
                    onClick={this.toggleDataToShow}
                    label={toggleDailyWeeklyButtonText}
                  />
                  &nbsp;
                  <RaisedButton
                    primary
                    onClick={this.gotoPreviousPalette}
                    label="<"
                    disabled={
                      this.state.colorPalettes.length === 0 || this.state.colorPaletteIndex === 0
                    }
                  />
                  &nbsp;
                  <RaisedButton
                    primary
                    onClick={this.regenerateColorPalette}
                    label={labelChangeColorPalette}
                  />
                </div>
              </div>
            )}

            {!this.state.loading && (
              <div id="complaintsPieChart" style={styles.complaintsGraphBackground}>
                <ParentSize>
                  {(parent) => (
                    <ComplaintsPieChart
                      width={parent.width}
                      height={200}
                      complaintsByType={this.state.complaintsByType}
                      colorScale={this.state.graphColorScale}
                    />
                  )}
                </ParentSize>
                <h2 style={styles.graphCaption}>{captionPieChart}</h2>
              </div>
            )}

            {this.props.strings['urls/Insightfull'] !== 'DISABLED' && (
              <div id="externalLinks" style={styles.externalLinksDiv}>
                <RaisedButton
                  fullWidth={false}
                  label={this.props.strings['labels/linkInsightfull']}
                  primary
                  href={this.props.strings['urls/Insightfull']}
                  target="_blank"
                />
              </div>
            )}

            {!this.state.loading && (
              <HistoryList
                complaints={this.state.filteredHistory}
                colorPalette={this.state.colorPalette}
                eventTypesFiltered={this.state.eventTypesFiltered}
                strings={this.props.strings}
                site={this.props.site}
                userProfile={this.props.userProfile}
                webTrakRootUrl={this.props.webTrakRootUrl}
                useEventTypeColors
              />
            )}
          </div>

          <Dialog
            title={strings['labels/complaintDetails']}
            actions={[
              <FlatButton label={strings['labels/close']} primary onClick={this.handleClose} />,
            ]}
            modal={false}
            open={this.state.dialog}
            titleStyle={styles.dialogTitle}
            contentStyle={styles.contentStyle}>
            <List>
              <ListItem
                primaryText={`${formatSubmissionDateTime(this.state.submission.event_datetime)}`}
                leftIcon={<ActionDateRange />}
                disabled
              />
              {this.state.submission.event_types &&
                Object.keys(this.state.submission.event_types).map((index) => (
                  <ListItem
                    key={index}
                    primaryText={this.state.submission.event_types[index]}
                    leftIcon={<MapFlight />}
                    disabled
                  />
                ))}
            </List>
            {this.state.submission.contact && this.state.submission.contact === 'Requested' && (
              <ListItem
                primaryText={strings['labels/contactRequested']}
                leftIcon={<ActionQuestionAnswer />}
                disabled
              />
            )}
            {this.state.submission.submission_address && (
              <ListItem
                primaryText={this.state.submission.formattedAddress}
                leftIcon={<MapPlace />}
                style={{ lineHeight: 1.4 }}
                disabled
              />
            )}
          </Dialog>
        </Fragment>
      );
    }
    return <Redirect to={{ pathname: `/${siteName}${path.login}` }} />;
  }
}

History.propTypes = {
  site: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  newProfileLoaded: PropTypes.string.isRequired,
  isAppJustLoaded: PropTypes.func.isRequired,
  siteName: PropTypes.string.isRequired,
};

export default withRouter(History);
