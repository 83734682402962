import TextField from 'material-ui/TextField';
import styled from 'styled-components';
import { STYLES } from '../styles';

const StyledTextField = styled(TextField)`
  label {
    font-size: ${STYLES.LABEL_FONT_SIZE} !important;
    transition: none !important;
    transform: translateY(-1.6rem) !important;
  }
  textarea,
  input {
    padding: 0.5rem 0 !important;
    font-family: 'Roboto', sans-serif;
  }
`;

export { StyledTextField as TextField };
