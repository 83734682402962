import React from 'react';
import styled from 'styled-components';
import { STYLES } from '../styles';
import { SelectArrowDownIcon } from './icons/SelectArrowDownIcon';

const SelectFieldContainer = styled.div`
  font-family: 'Roboto', sans-serif;
  position: relative;
  margin: 0.5rem 0 0;
  ${({ multiple }) =>
    !multiple &&
    `
    border-bottom: 1px solid ${STYLES.BORDER_COLOR};
  `}
`;

const StyledSelectField = styled.select`
  font-family: 'Roboto', sans-serif;
  appearance: none;
  &::-ms-expand {
    display: none;
  }
  padding: 0.5rem 0;
  height: 100%;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  background-color: transparent;
  border: 0;
  ${({ multiple }) =>
    multiple &&
    `
    border: 1px solid ${STYLES.BORDER_COLOR};
    height: 10rem;
  `}
`;

const StyledSelectArrowDownIcon = styled(SelectArrowDownIcon)`
  font-family: 'Roboto', sans-serif;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
`;

const SelectField = ({ multiple, ...props }) => (
  <SelectFieldContainer multiple={multiple}>
    {!multiple && <StyledSelectArrowDownIcon />}
    <StyledSelectField {...props} multiple={multiple} />
  </SelectFieldContainer>
);

export { SelectField };
