import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { darkBlack } from 'material-ui/styles/colors';
import Snackbar from 'material-ui/Snackbar';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import ToggleCheckBox from 'material-ui/svg-icons/toggle/check-box';
import ToggleCheckBoxOutlineBlank from 'material-ui/svg-icons/toggle/check-box-outline-blank';
import { path } from '../utils/utilities';

import {
  profileDialogDisplayed,
  profileDialog,
  validateProfileRequired,
  profileValidationPerformed,
} from '../utils/profile';

const styles = {
  block: { maxWidth: 250 },
  toggle: { marginBottom: 16 },
  contentStyle: { maxWidth: 320 },
  contentStyle2: { height: 255, maxWidth: 320 },
  description: { color: darkBlack, lineHeight: '22px' },
  secondDialog: { padding: '5px 24px 20px' },
  thirdDialog: { height: 110, padding: '5px 24px 20px' },
  dialogTitle: { fontSize: 15, fontWeight: 500, padding: '15px 24px 0px' },
  selectionContainer: { height: 85 },
  snackbarContent: { fontSize: 15 },
  radioButton: { marginBottom: 16 },
};

class Notify extends Component {
  state = {
    firstDialog: false,
    secondDialog: false,
    thirdDialog: false,
    fourthDialog: false,
    snackBar: false,
    snackBarMsg: '',
    dialogMsg: '',
    snackBarDuration: 4000,
    reasonSelected: false,
  };

  checkProfile = () => {
    setTimeout(() => {
      if (this.props.profileIncomplete) {
        if (!profileDialogDisplayed(this.props.siteName)) {
          this.setState({ firstDialog: true });
        } else if (validateProfileRequired(this.props.siteName)) {
          this.setState({ secondDialog: true });
          profileValidationPerformed(this.props.siteName);
        }
      }
    }, 500);
  };

  handleClose = () => {
    profileDialog(this.props.siteName);
    this.props.onClose && this.props.onClose();
    this.setState({
      firstDialog: false,
      secondDialog: false,
    });
  };

  openSnackBar = (message, duration) => {
    this.setState({
      snackBarMsg: message,
      snackBarDuration: duration || this.state.snackBarDuration,
      snackBar: true,
    });
  };

  closeSnackBar = () => {
    this.setState({
      snackBar: false,
    });
  };

  notifyProfileInvalid = (dialogMsg = '') => {
    this.setState({ secondDialog: true, dialogMsg });
  };

  notifyProfileCompleted = () => {
    this.setState({ fourthDialog: true });
  };

  openAddressChangeConfirmation = () => {
    this.setState({ reasonSelected: false, thirdDialog: true });
  };

  confirmAddress = () => {
    this.setState({ thirdDialog: false });
    this.props.storeProfile();
  };

  render() {
    if (this.props.goCheckProfile) this.checkProfile();
    const { strings } = this.props;
    const submitComplaint = strings['complaintsform/blocks/submitComplaint'];
    return (
      <Fragment>
        <Dialog
          title={strings['alerts/heading/createProfile']}
          actions={[
            <FlatButton
              label={strings['labels/iAccept']}
              primary
              keyboardFocused
              onClick={this.handleClose}
            />,
          ]}
          modal
          open={this.state.firstDialog}
          onRequestClose={this.handleClose}
          titleStyle={styles.dialogTitle}
          contentStyle={styles.contentStyle}>
          <p style={styles.description}>{strings['alerts/profileEmpty']}</p>
          <p style={styles.description}>{strings['alerts/profileWillBeStored']}</p>
        </Dialog>
        <Dialog
          title={strings['alerts/heading/profileIncomplete']}
          actions={[
            <FlatButton
              label={strings['labels/close']}
              primary
              keyboardFocused
              onClick={this.handleClose}
            />,
          ]}
          modal
          open={this.state.secondDialog}
          onRequestClose={this.handleClose}
          titleStyle={styles.dialogTitle}
          bodyStyle={styles.secondDialog}
          contentStyle={styles.contentStyle}>
          {this.state.dialogMsg && <p style={styles.description}>{this.state.dialogMsg}</p>}
          {!this.state.dialogMsg && (
            <p style={styles.description}>{strings['alerts/profileIncomplete']}</p>
          )}
        </Dialog>
        <Dialog
          title={strings['alerts/heading/updateAddress']}
          actions={
            this.state.reasonSelected
              ? [
                  <FlatButton
                    label={strings['labels/cancel']}
                    primary
                    onClick={() => {
                      this.setState({ thirdDialog: false });
                    }}
                  />,
                  <FlatButton
                    label={strings['labels/confirm']}
                    primary
                    onClick={this.confirmAddress}
                  />,
                ]
              : [
                  <FlatButton
                    label={strings['labels/cancel']}
                    primary
                    onClick={() => {
                      this.setState({ thirdDialog: false });
                    }}
                  />,
                ]
          }
          modal
          open={this.state.thirdDialog}
          onRequestClose={this.handleClose}
          titleStyle={styles.dialogTitle}
          bodyStyle={styles.thirdDialog}
          contentStyle={styles.contentStyle2}>
          <div style={styles.selectionContainer}>
            <RadioButtonGroup
              name="reason"
              onChange={() => {
                this.setState({ reasonSelected: true });
              }}>
              {[strings['labels/correctingMistake'], strings['labels/movingHouse']].map(
                (option) => (
                  <RadioButton
                    key={option}
                    value={option}
                    label={option}
                    checkedIcon={<ToggleCheckBox />}
                    uncheckedIcon={<ToggleCheckBoxOutlineBlank />}
                    style={styles.radioButton}
                  />
                )
              )}
            </RadioButtonGroup>
          </div>
        </Dialog>
        <Dialog
          title={strings['alerts/heading/profileUpdated']}
          actions={[
            <FlatButton
              label={submitComplaint}
              primary
              onClick={() => {
                this.setState({ fourthDialog: false });
              }}
              containerElement={<Link to={`/${this.props.siteName}${path.compose}`} />}
            />,
          ]}
          modal
          open={this.state.fourthDialog}
          titleStyle={styles.dialogTitle}
          bodyStyle={styles.secondDialog}
          contentStyle={styles.contentStyle}
        />
        <Snackbar
          open={this.state.snackBar}
          message={this.state.snackBarMsg}
          autoHideDuration={this.state.snackBarDuration}
          onRequestClose={this.closeSnackBar}
          contentStyle={styles.snackbarContent}
        />
      </Fragment>
    );
  }
}

Notify.propTypes = {
  siteName: PropTypes.string.isRequired,
  strings: PropTypes.object.isRequired,
  profileIncomplete: PropTypes.bool.isRequired,
  storeProfile: PropTypes.func.isRequired,
  goCheckProfile: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Notify;
