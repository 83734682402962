import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { path } from '../utils/utilities';
import { skipLogin } from '../utils/auth0';

class SkipLogin extends Component {
  render() {
    skipLogin(this.props.site.name);
    return (
      <Redirect
        to={{
          pathname: `/${this.props.site.name}${path.home}`,
        }}
      />
    );
  }
}

SkipLogin.propTypes = {
  site: PropTypes.object.isRequired,
};

export default SkipLogin;
