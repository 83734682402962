import decode from 'decode-html';
import React, { Component, Fragment } from 'react';
import axios from 'axios';
import platform from 'platform';
import uuidv1 from 'uuid/v1';
import moment from 'moment';
import Fingerprint2 from 'fingerprintjs2';
import PropTypes from 'prop-types';
import 'moment-timezone';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { ListItem } from 'material-ui/List';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import NavigationRefresh from 'material-ui/svg-icons/navigation/refresh';
// import IconSchedule from 'material-ui/svg-icons/action/schedule';
import RaisedButton from 'material-ui/RaisedButton';
import ActionDone from 'material-ui/svg-icons/action/done';
import AccountCircle from 'material-ui/svg-icons/action/account-circle';
import parse from 'html-react-parser';
import AppNavigation from './AppNavigation';
import { DateInput } from './DateInput';
import { Form } from './Form';
import { SelectField } from './SelectField';
import { TimePicker } from './TimePicker';
import { FormLabel } from './FormLabel';
import Header from './Header';
import {
  path,
  isMobile,
  localeSelectionRequired,
  defineValues,
  getViewpointCollectorUrl,
  getLocalTime,
  defineLocale,
  // log,
} from '../utils/utilities';
import { DatePicker } from './DatePicker';
import { isProfileCompleted, profileNeedsValidation, isValid } from '../utils/profile';
import { isAuthenticated, getDetails, isLoginSkipped, needsAuthentication } from '../utils/auth0';
import { TextField } from './TextField';

/* global grecaptcha */

const {
  Intl: { DateTimeFormat },
} = global;

const styles = {
  contentStyle: { maxWidth: 320, wordWrap: 'break-word' },
  dialogTitle: { fontSize: 15, fontWeight: 500, padding: '15px 24px 0px' },
  submitNowButton: {
    margin: '30px 0',
  },
  clearButton: { margin: 12 },
  resetBtn: { margin: '10px 8px' },
  iconStyle: { fill: '#757575' },
  divider: { width: '100%', display: 'block', height: 5 },
  spinnerWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  captchaWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 20,
  },
  accessibilityButton: {
    margin: '1rem 0',
    height: '3rem',
    flexGrow: '1',
    textAlign: 'center',
    fontSize: '14px',
    fontFamily: '"Roboto", sans-serif',
    backgroundColor: '#7215D8',
    color: '#FFF',
  },
  errorTexts: {
    fontWeight: 'bolder',
    color: 'rgb(244, 67, 54)',
    fontSize: '14px',
    fontFamily: '"Roboto", sans-serif',
    display: 'flex',
    alignItems: 'center',
  },
  externalLinksDiv: { textAlign: 'center' },
};

class Compose extends Component {
  constructor(props) {
    super(props);
    this.geolocation = { latitude: null, longitude: null };
    this.selectNodes = {};
    this.textNodes = {};
    this.locale = defineLocale(this.props.site) || props.site.config.locale;

    this.state = {
      complaint: this.getDefaultComplaintData(),
      errors: defineValues(this.props.defineComplaint, true),
      captchaResponse: this.props.site.config.showCaptcha ? null : true,
      mobile: isMobile(),
      loading: false,
      validationErrors: '',
      dialogSubmission: false,
      submitMsg: '',
      showPrivacy: false,
      nicePickers: false,
    };
  }

  componentDidMount() {
    // Modify the material UI node to reach accessibility purpose
    /*
    Object.keys(this.selectNodes).forEach(key => {
      appendButtonText(this.selectNodes[key].querySelector('button'), 'dropdown');
      appendAccessibility(this.selectNodes[key].querySelector('button'), 'aria-label', 'dropdown');
    });
    */
    Object.keys(this.textNodes).forEach((key) => {
      this.textNodes[key].querySelector('textarea').id = this.textNodes[key]
        .querySelector('label')
        .getAttribute('for');
    });
    window.scrollTo(0, 0);
    // this.updateSubmitKey();
    if (!this.props.geolocation[0] || !this.props.geolocation[1]) {
      this.props.checkLocationAddress();
    }
    this.checkLayout();
    window.addEventListener('resize', this.checkLayout);
    setTimeout(() => {
      if (
        !this.state.captchaRendered &&
        typeof grecaptcha !== 'undefined' &&
        document.querySelector('#captcha')
      ) {
        try {
          grecaptcha.render('captcha', {
            sitekey: '6LczcFIUAAAAAIX44ppCLxHwZSJPey5SiPHStYIl',
            callback: (response) => {
              this.setState({ captchaResponse: response });
            },
          });
          this.setState({ captchaRendered: true });
        } catch (error) {
          console.warn('grecaptcha.render error:', error);
        }
      }
    }, 2000);

    // set location id if only one location in the location list
    const { locations } = this.props.site.config;
    const locationKeys = Object.keys(locations);
    if (locationKeys.length === 1) {
      [this.state.complaint.locations] = locationKeys;
    } else if (locationKeys.length > 1) {
      // Fix the error on san4 where even though the dropdown shows a value,
      // submitting the form generates an error because locations is empty
      [this.state.complaint.locations] = [locationKeys[0]];
    }

    document.querySelectorAll('hr').forEach((item) => {
      item.setAttribute('aria-hidden', true);
      item.style.borderBottom = '1px solid #949494';
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkLayout);
    this.updateAppState();
  }

  // updateSubmitKey = () => {
  //   this.props.getSubmitKey().then(key => {
  //     this.submitKey = key;
  //     console.log(`%c submitKey set to ${key}`, "color: #0F0");
  //   });
  //   setTimeout(this.updateSubmitKey, 3500 * 1000);
  // }

  getDefaultComplaintData = () => {
    // console.log('--- getDefaultComplaintData ---');
    const values = Object.assign({}, this.props.complaintData);
    this.props.defineComplaint.forEach((field) => {
      if (field.element === 'date' || field.name === 'time') {
        const localTime = getLocalTime(this.props.site.config.timezone);
        if (!values[field.name]) {
          values[field.name] = localTime;
        }
      } else if (
        this.props.site.config.storeUserPreference === '1' &&
        localStorage.getItem(field.name)
      ) {
        // console.log('storeUserPreference is ON; getting data from localStorage');
        // CMS324873 get the complaint data
        values[field.name] = localStorage.getItem(field.name);
      }
    });
    return values;
  };

  checkLayout = () => {
    if (this.state.mobile !== isMobile()) this.setState({ mobile: isMobile() });
  };

  showPrivacyNow = () => {
    this.setState({ showPrivacy: true });
  };

  afterSubmit = () => {
    this.cForm = true;
    const data = this.state.complaint;
    //  VWA-108
    data.date = getLocalTime(this.props.site.config.timezone);
    data.time = data.date;
    // data.comments = "";
    this.setState({
      complaint: data,
      errors: defineValues(this.props.defineComplaint, true),
    });
    this.props.saveState('complaint', data);
    setTimeout(() => {
      const firstFieldEl = document.querySelector(`input,select`);
      firstFieldEl && firstFieldEl.focus();
      // this.props.getSubmitKey().then(key => { this.submitKey = key; });
    }, 1);
  };

  // CMS324873 Clear form when with local storage
  clearFormAndLocalStorage = () => {
    this.cForm = true;
    const data = defineValues(this.props.defineComplaint);
    this.props.defineComplaint.forEach((field) => {
      if (this.props.site.config.storeUserPreference === '1' && localStorage.getItem(field.name)) {
        localStorage.removeItem(field.name);
      }
    });
    this.setState({
      complaint: data,
      errors: defineValues(this.props.defineComplaint, true),
    });
    this.props.saveState('complaint', data);
    setTimeout(() => {
      const firstFieldEl = document.querySelector(`input,select`);
      firstFieldEl && firstFieldEl.focus();
    }, 1);
  };

  clearForm = () => {
    this.cForm = true;
    const data = defineValues(this.props.defineComplaint);
    // add date and time to data
    const localTime = getLocalTime(this.props.site.config.timezone);
    data.date = localTime;
    // data.time = localTime;
    this.setState({
      complaint: data,
      errors: defineValues(this.props.defineComplaint, true),
    });
    this.props.saveState('complaint', data);
    setTimeout(() => {
      const firstFieldEl = document.querySelector('input, select');
      firstFieldEl && firstFieldEl.focus();
      // this.props.getSubmitKey().then(key => { this.submitKey = key; });
    }, 10);
  };

  dateTimeUpdated = (field, date) => {
    // log('datetime updated', field, date);
    this.validateInput(field, date);
  };

  checkReadyToSubmit = () => isProfileCompleted(this.state.complaint, this.props.defineComplaint);

  handleTextChange = (fieldName, value) => {
    this.setState({
      complaint: Object.assign(this.state.complaint, { [fieldName]: value }),
    });
  };

  generateForm = () => {
    const fields = [];
    const { strings } = this.props;

    this.state.validationErrors &&
      fields.push(
        <p key="error message texts" style={styles.errorTexts}>
          {this.state.validationErrors}
        </p>
      );

    // if (!this.state.mobile) {
    //   const {
    //     profileData: { name, surname, email },
    //   } = this.props;
    //   fields.push(
    //     <TextField
    //       fullWidth
    //       type="text"
    //       key="profile"
    //       disabled
    //       // defaultValue={`${name} ${surname} - ${email}`}
    //       defaultValue={`Hi, ${name}`}
    //       floatingLabelText={strings['labels/profile']}
    //       floatingLabelFixed
    //     />
    //   );
    // }

    // check the double pickers setting from FOCUS
    if (this.props.site.doublepickers && this.props.site.doublepickers.enabled === '1') {
      fields.push(
        // <RaisedButton
        //   style={{ margin: '1rem 0' }}
        //   label={this.state.nicePickers ? 'Accessibility Picker' : 'Calendar/Clock Picker'}
        //   key="pickerSelect"
        //   onClick={this.handlePickerSelect}
        // />

        <div key="pickerSelect" style={{ display: 'flex' }}>
          <button
            style={{
              ...styles.accessibilityButton,
              borderColor: this.props.site.theme.appbarColor,
              backgroundColor: '#fff',
              color: this.props.site.theme.appbarColor,
            }}
            aria-label="Date and Time picker switch"
            onClick={this.handlePickerSelect}>
            {this.state.nicePickers ? 'Accessibility Picker' : 'Calendar/Clock Picker'}
          </button>
        </div>
      );
    }

    const localTime = getLocalTime(this.props.site.config.timezone);
    this.props.defineComplaint.forEach((field) => {
      // console.log('generateForm \t field: \t', field);
      const dateValue = this.state.complaint.time || localTime;

      if (field.element === 'text') {
        fields.push(
          <div
            ref={(node) => {
              this.textNodes[field.name] = node;
            }}
            key={field.name}>
            <TextField
              aria-invalid={this.state.errors[field.name] && true}
              aria-required={field.required && true}
              fullWidth
              autoCapitalize={
                field.type === 'email' || field.type === 'tel' || field.multiLine ? 'none' : 'words'
              }
              type={field.type === 'tel' ? 'number' : field.type}
              multiLine={field.multiLine}
              value={this.state.complaint[field.name]}
              floatingLabelText={
                <span>
                  {field.label}{' '}
                  <span aria-hidden className="required">
                    {field.labelExtension}
                  </span>
                </span>
              }
              floatingLabelFixed
              hintText={field.hint}
              errorText={this.state.errors[field.name]}
              maxLength={field.maxlength}
              onChange={(event) => {
                this.handleTextChange(field.name, event.target.value);
              }}
              onBlur={(event) => {
                this.validateInput(field, event.target.value);
              }}
            />
          </div>
        );
      } else if (field.element === 'select') {
        this.menuItems = () =>
          field.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ));
        fields.push(
          <div
            ref={(node) => {
              this.selectNodes[field.name] = node;
            }}
            key={field.name}>
            {/* eslint-disable-next-line */}
            <FormLabel htmlFor={field.name}>
              <span>
                {field.label}{' '}
                <span aria-hidden className="required">
                  {field.labelExtension}
                </span>
              </span>
            </FormLabel>
            <SelectField
              aria-invalid={this.state.errors[field.name] && true}
              aria-required={field.required && true}
              id={field.name}
              key={field.name}
              value={this.state.complaint[field.name]}
              multiple={field.multiSelect}
              onChange={(event) => {
                const val = event.target.value;
                const existingValues = this.state.complaint[field.name];
                let newValues = [];
                if (field.multiSelect) {
                  if (existingValues.indexOf(val) >= 0) {
                    newValues = existingValues.filter((ev) => ev !== val);
                  } else {
                    newValues = existingValues.concat(val);
                  }
                } else {
                  newValues = val;
                }
                this.handleSelectChange(field, newValues);
              }}>
              {this.menuItems(this.state.complaint[field.name])}
            </SelectField>
          </div>
        );
      }
      if (field.element === 'date') {
        let minDate = new Date(); // this.props.complaintData.date;
        minDate = new Date(
          minDate.setDate(minDate.getDate() - (this.props.site.config.dateMaxDays || 30))
        );
        fields.push(
          this.state.nicePickers ? (
            <DatePicker
              fullWidth
              dialogContainerStyle={{ top: -50 }}
              key={field.name}
              value={dateValue}
              hideCalendarDate
              formatDate={
                new DateTimeFormat('en-US', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                }).format
              }
              minDate={minDate}
              maxDate={new Date()} // {this.state.complaint[field.name] || new Date()}
              floatingLabelText={
                <span>
                  {field.label}{' '}
                  <span aria-hidden className="required">
                    {field.labelExtension}
                  </span>
                </span>
              }
              floatingLabelFixed
              hintText={field.hint}
              disableYearSelection
              okLabel={strings['labels/ok']}
              cancelLabel={strings['labels/cancel']}
              errorText={localTime ? '' : this.state.errors[field.name]}
              onChange={(event, date) => {
                // log('DatePicker date', date);
                this.setState({
                  complaint: Object.assign({}, this.state.complaint, {
                    [field.name]: date,
                  }),
                });
                this.validateInput(field, date);
              }}
              // onDismiss={() => {
              //   this.validateInput(field, this.state.complaint[field.name]);
              // }}
            />
          ) : (
            <div key="accessibilityDatePicker">
              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
              <FormLabel htmlFor="complaintDate">
                {' '}
                <span>
                  {field.label}{' '}
                  <span aria-hidden className="required" id="datePickerRequiredIndicator">
                    {field.labelExtension}
                  </span>
                </span>
              </FormLabel>
              <DateInput
                id="complaintDate"
                style={{ width: '100%', margin: '1rem 0', lineHeight: '1.5rem' }}
                type="date"
                value={moment(dateValue).format('YYYY-MM-DD')}
                key="date"
                name="date"
                placeholder={new Date()}
                onChange={(event, date) => {
                  const newTime = moment(event.target.value)
                    .hours(dateValue.getHours())
                    .minutes(dateValue.getMinutes());
                  const newDate = newTime.toDate();
                  this.setState({
                    complaint: Object.assign({}, this.state.complaint, {
                      [field.name]: newDate,
                      time: newDate,
                    }),
                  });
                  this.validateInput(field, date);
                }}
              />
            </div>
          )
        );
      }
      if (field.element === 'time') {
        fields.push(
          this.state.nicePickers ? (
            <TimePicker
              fullWidth
              style={{ maxHeight: 255 }}
              dialogStyle={{ top: -60 }}
              key={field.name}
              value={dateValue}
              floatingLabelText={
                <span>
                  {field.label}{' '}
                  <span aria-hidden className="required">
                    {field.labelExtension}
                  </span>
                </span>
              }
              floatingLabelFixed
              hintText={field.hint}
              okLabel={strings['labels/ok']}
              cancelLabel={strings['labels/cancel']}
              errorText={localTime ? '' : this.state.errors[field.name]}
              onChange={(event, time) => {
                this.setState({
                  complaint: Object.assign({}, this.state.complaint, {
                    [field.name]: time,
                  }),
                });
                this.validateInput(field, time);
              }}
            />
          ) : (
            <div key="accessibilityTimePicker">
              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
              <FormLabel htmlFor="complaintTime">
                {' '}
                <span>
                  {field.label}{' '}
                  <span aria-hidden className="required">
                    {field.labelExtension}
                  </span>
                </span>
              </FormLabel>
              <DateInput
                step="1"
                id="complaintTime"
                style={{ width: '100%', margin: '1rem 0', lineHeight: '1.5rem' }}
                type="time"
                key="time"
                name="time"
                value={moment(dateValue).format('HH:mm:ss')}
                placeholder={new Date()}
                onChange={(event, time) => {
                  const { value } = event.target;
                  const [hour, minute, second = 0] = value.split(':');
                  const newTime = moment(dateValue).hours(hour).minutes(minute).seconds(second);
                  const date = newTime.toDate();
                  this.setState({
                    complaint: Object.assign({}, this.state.complaint, {
                      [field.name]: date,
                    }),
                  });
                  this.validateInput(field, date);
                }}
              />
            </div>
          )
        );
      }
    });
    return fields;
  };

  handlePickerSelect = () => {
    this.setState({ nicePickers: !this.state.nicePickers });
  };

  handleSelectChange = (field, value) => {
    this.setState({
      complaint: Object.assign({}, this.state.complaint, {
        [field.name]: value,
      }),
    });
    this.validateInput(field, value);
  };

  validateInput = (field, value) => {
    const errorsObject = this.state.errors;
    const { config } = this.props.site;
    const error = isValid(field, value, this.props.strings, true, config);
    if (errorsObject[field.name] !== error) {
      errorsObject[field.name] = error;
      this.setState(errorsObject);
    }
  };

  updateAppState = () => {
    setTimeout(() => {
      if (!this.cForm) {
        this.props.saveState('complaint', this.state.complaint);
      }
    }, 1);
  };

  readyToSend = () => {
    this.formData = new FormData();
    this.addValues('complaint', this.state.complaint);
    this.addValues('profile', this.props.profileData);
    // this.formData.append('submitkey', this.submitKey);
    this.formData.append('source', 'PWA');
    this.appendMetaData();
    this.validateBeforeSubmit();
  };

  readyToSendForCurrentTime = () => {
    this.formData = new FormData();

    const data = this.state.complaint;
    data.date = getLocalTime(this.props.site.config.timezone);
    data.time = data.date;
    this.setState({
      complaint: data,
      errors: defineValues(this.props.defineComplaint, true),
    });
    this.props.saveState('complaint', data);

    this.addValues('complaint', this.state.complaint);
    this.addValues('profile', this.props.profileData);
    // this.formData.append('submitkey', this.submitKey);
    this.formData.append('source', 'PWA');
    this.appendMetaData();
    this.validateBeforeSubmit();
  };

  addValues = (type, data) => {
    const definition = type === 'profile' ? this.props.defineProfile : this.props.defineComplaint;
    Object.keys(data).forEach((field) => {
      const find = definition.find((item) => item.name === field);
      if (find) {
        if (find.element === 'select' && find.multiSelect) {
          data[field].forEach((value) => {
            this.formData.append(`${field}[]`, value);
          });
        } else if (find.element === 'date') {
          const date = data[field];
          this.formData.append('year', date.getFullYear());
          this.formData.append('month', date.getMonth() + 1);
          this.formData.append('day', date.getDate());
          const realYear = date.getFullYear();
          const realMonth = date.getMonth() + 1;
          const realDate = date.getDate();
          const monthPadded = (realMonth < 10 ? '0' : '') + String(realMonth);
          const datePadded = (realDate < 10 ? '0' : '') + String(realDate);
          const dateStr = `${String(realYear)}-${String(monthPadded)}-${String(datePadded)}`;
          this.formData.append('date', dateStr);
        } else if (find.element === 'time') {
          const time = data[field];
          this.formData.append('hour', time.getHours());
          this.formData.append('min', time.getMinutes());
          this.formData.append('sec', time.getSeconds());
        } else {
          this.formData.append(field, data[field]);
        }
      }
    });
    if (typeof data.lat !== 'undefined' && typeof data.lon !== 'undefined') {
      this.formData.append('home_latitude', data.lat);
      this.formData.append('home_longitude', data.lon);
    }
  };

  appendMetaData = () => {
    const {
      config: { timezone },
    } = this.props.site;
    this.formData.append('locale', this.locale);
    this.formData.append('timeZone', timezone);
    this.formData.append('browser_name', platform.name || null);
    this.formData.append('browser_version', platform.version.split('.')[0] || null);
    this.formData.append('browser_vendor', navigator.vendor || null);
    this.formData.append('browser_platform', navigator.platform || null);
    // this.formData.append('accept_privacy', 'Y');
    this.formData.append(
      'privacy_statement',
      this.props.strings['complaintsform/blocks/privacyStatement']
    );
    this.formData.append('nowebtrak', 1);
    this.formData.append('webtraklinkback', '');
    this.formData.append('device_latitude', this.props.geolocation[0]);
    this.formData.append('device_longitude', this.props.geolocation[1]);
    this.formData.append('defaulttime', 0);
    this.formData.append('g-recaptcha-response', this.gRecaptchaResponse);
    const idToken = getDetails(this.props.site.name, 'idToken');
    if (idToken) this.formData.append('token', idToken);
    new Fingerprint2().get((result) => {
      this.formData.append('browser_uuid', uuidv1());
      this.formData.append('fingerprint', result);
    });
  };

  validateBeforeSubmit = () => {
    this.setState({ loading: true });
    this.props.getSubmitKey().then((key) => {
      // console.log(`%c submitKey set to ${key}`, "color: #0F0");
      this.formData.append('submitkey', key);
      axios({
        method: 'post',
        url: `${getViewpointCollectorUrl(this.props.site)}${this.props.site.name}/home/validateall`,
        data: this.formData,
      })
        .then((response) => {
          if (response && typeof response.data !== 'undefined') {
            const errors = {};
            const isSubmittedDataValid = response.data.validation_result;
            const validationErrors = response.data.validation_errors;
            delete response.data.validation_result;
            delete response.data.validation_errors;
            Object.keys(response.data).forEach((index) => {
              if (response.data[index].ok === false) errors[index] = response.data[index];
            });
            if (!isSubmittedDataValid) {
              const errorText = validationErrors.replace('\n', '. ');
              console.log('Submitted data is not valid:\n\n', errorText);
              // this.setState({ loading: false, dialog: true, validationErrors: validationErrors.replace('\n', '. ') });
              this.setState({
                loading: false,
                validationErrors: errorText.split('.')[0],
              });
              // this.setState({ loading: false, validationErrors: errorText.split('.')[0] });

              const { errors: submittedErrors } = this.state;
              let firstField = [];

              Object.keys(submittedErrors).forEach((fieldKey) => {
                if (submittedErrors[fieldKey] === 'Required') {
                  firstField = [...firstField, key];
                }
              });

              const firstFieldEl = document.querySelector(`input[name=${firstField[0]}]`);
              firstFieldEl && firstFieldEl.focus();
            } else {
              this.submitForm();
            }
          }
        })
        .catch((response) => {
          this.setState({ loading: false });
        });
    });
  };

  submitForm = () => {
    const SUBMISSION_DIALOG_TIMEOUT = 3000;
    // console.log(`---------- submitForm: ${this.formData.get('hour')}:${this.formData.get('min')}:${this.formData.get('sec')} - ${this.formData.get('aircrafttype')} - ${this.formData.get('event_type')} - ${this.formData.get('activity_type')} - "${this.formData.get('comments')}"`);
    console.log(
      `%csubmitForm: ${this.formData.get('month')}-${this.formData.get(
        'day'
      )} - ${this.formData.get('hour')}:${this.formData.get('min')}:${this.formData.get(
        'sec'
      )}, Timezone:${this.formData.get('timeZone')}`,
      'color: #FF0'
    );
    // for(let pair of this.formData.entries()) {
    //   console.log('submitForm  \t ' + pair[0]+ ': '+ pair[1]);
    // }
    axios({
      method: 'post',
      url: `${getViewpointCollectorUrl(this.props.site)}${this.props.site.name}?response=json`,
      data: this.formData,
    })
      .then((response) => {
        if (response && typeof response.data !== 'undefined') {
          this.setState(
            { loading: false, dialogSubmission: true, submitMsg: response.data },
            () => {
              setTimeout(() => {
                this.setState({ dialogSubmission: false });
                this.afterSubmit();
              }, SUBMISSION_DIALOG_TIMEOUT);
            }
          );
          // CMS324873 Store the complaint values
          if (this.props.site.config.storeUserPreference === '1') {
            Object.keys(this.state.complaint).forEach((item) => {
              localStorage.setItem(item, this.state.complaint[item]);
            });
          }
        }
      })
      .catch((response) => {
        console.error(response);
        this.setState({ loading: false });
      });
  };

  renderHtml = (html) => (
    // eslint-disable-next-line
    <div dangerouslySetInnerHTML={{ __html: html }} />
  );

  render() {
    const {
      site: {
        name: siteName,
        theme: { appbarColor },
      },
      profileData: { name, surname, email },
      strings,
    } = this.props;
    const pageTitle = strings['labels/compose'];
    const isReadyToSubmit = this.checkReadyToSubmit();
    const close = strings['labels/close'];
    const clearContent = strings['labels/clearContent'];
    const complaintSubmitted = strings['complaintsform/headings/complaintSubmitted'];
    const closeConfirmation = strings['complaintsform/headings/closeConfirmation'];
    const complaintFailedToSubmit = strings['complaintsform/headings/complaintFailedToSubmit'];
    const confirmationText = decode(strings['complaintsform/blocks/complaintSubmitted']);
    const submitComplaint = strings['complaintsform/blocks/submitComplaint'];
    // const submitComplaintForCurrentTime = strings['complaintsform/blocks/submitComplaintForCurrentTime'];
    const privacyStatement = decode(strings['complaintsform/blocks/privacyStatement']);

    // log(this.props.site.config);

    let hasPrivacy = this.props.site.config.fields.indexOf('accept_privacy');
    if (hasPrivacy !== -1) {
      hasPrivacy = true;
    } else {
      hasPrivacy = false;
    }

    // is language selection required?
    if (localeSelectionRequired(this.props.site))
      return <Redirect to={{ pathname: `/${this.props.site.name}${path.language}` }} />;
    if (isAuthenticated(this.props.site)) {
      if (
        !this.props.newProfileLoaded &&
        !isLoginSkipped(this.props.site) &&
        needsAuthentication(this.props.site)
      ) {
        return <Redirect to={{ pathname: `/${this.props.site.name}${path.getProfileInfo}` }} />;
      }
      const check = this.props.isAppJustLoaded();
      if (!check.isPrivacyAccepted)
        return <Redirect to={{ pathname: `/${this.props.site.name}${path.privacy}/accept` }} />;
      // need to complete your profile first
      if (
        !isProfileCompleted(
          this.props.profileData,
          this.props.defineProfile,
          this.props.site.config
        )
      ) {
        profileNeedsValidation(this.props.site.name);
        return (
          <Redirect
            to={{
              pathname: `/${this.props.site.name}${path.profile}`,
              state: { goCheckProfile: true },
            }}
          />
        );
      }
      return (
        <Fragment>
          <AppNavigation site={this.props.site} strings={this.props.strings} title={pageTitle} />

          {this.state.mobile && (
            <Toolbar>
              <ToolbarGroup firstChild style={{ paddingLeft: 5 }}>
                <ListItem
                  primaryText={`${name} ${surname} - ${email}`}
                  leftIcon={<AccountCircle />}
                  innerDivStyle={{ paddingLeft: 60 }}
                  containerElement={<Link to={`/${siteName}${path.profile}`} />}
                />
              </ToolbarGroup>
            </Toolbar>
          )}

          <div className={this.state.mobile ? 'none-flex-container' : 'flex-container'}>
            {/*            <div className="button-wrapper">
              <RaisedButton
                fullWidth={false}
                label={submitComplaintForCurrentTime}
                primary
                icon={<IconSchedule />}
                style={styles.submitNowButton}
                disabled={!(isReadyToSubmit && !this.state.loading && this.state.captchaResponse)}
                onClick={this.readyToSendForCurrentTime}
                className='submitNowButton'
              />
            </div>
*/}
            {!this.state.mobile && (
              <Header site={this.props.site} strings={this.props.strings} page={path.compose} />
            )}

            <div>
              <h3>
                {this.props.strings['complaintsform/blocks/hi']} {this.props.profileData.name}
              </h3>
            </div>

            {this.props.strings['urls/WebTrak'] !== 'DISABLED' && (
              <div id="externalLinks" style={styles.externalLinksDiv}>
                <RaisedButton
                  fullWidth={false}
                  label={this.props.strings['labels/linkWebTrak']}
                  primary
                  href={this.props.strings['urls/WebTrak']}
                  target="_blank"
                />
              </div>
            )}

            <Form>
              {this.generateForm()}
              {this.props.site.config.showCaptcha && (
                <div style={styles.captchaWrapper}>
                  <div id="captcha" />
                </div>
              )}
              <div style={styles.divider} />

              {hasPrivacy && (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  href="#"
                  style={{ marginTop: '3px', marginLeft: '0', paddingLeft: '0', color: '#0645AD' }}
                  onClick={() => this.setState({ showPrivacy: true })}>
                  {strings['complaintsform/headings/privacyStatement']}
                </a>
              )}

              {!isReadyToSubmit && (
                <p id="submitButtonDisabledExplanation" style={{ textAlign: 'center' }}>
                  {parse(strings.focusStrings.labels.submitButtonDisabled)}
                </p>
              )}
            </Form>
            <div className="button-wrapper">
              {isReadyToSubmit && this.state.loading && (
                <div style={styles.spinnerWrapper}>
                  <div className="spinner" />
                </div>
              )}
              <div style={styles.spinnerWrapper}>
                <RaisedButton
                  fullWidth={false}
                  label={submitComplaint}
                  primary
                  icon={<ActionDone />}
                  disabled={!(isReadyToSubmit && !this.state.loading && this.state.captchaResponse)}
                  onClick={this.readyToSend}
                />
              </div>
              {!this.state.loading && (
                <div style={styles.spinnerWrapper}>
                  <FlatButton
                    primary
                    label={clearContent}
                    style={styles.resetBtn}
                    icon={<NavigationRefresh color={appbarColor} />}
                    onClick={this.clearFormAndLocalStorage}
                    containerElement={
                      <Link to={`/${this.props.site.name}${path.reset}${path.compose}`} />
                    }
                  />
                </div>
              )}
            </div>
          </div>
          <Dialog
            title={strings['complaintsform/headings/privacyStatement']}
            bodyClassName="privacy-policy "
            titleStyle={styles.dialogTitle}
            contentStyle={styles.contentStyle}
            actions={[
              <FlatButton
                label={strings['labels/ok']}
                primary
                onClick={() => {
                  this.setState({ showPrivacy: false });
                }}
              />,
            ]}
            modal={false}
            open={this.state.showPrivacy}>
            <div>{this.renderHtml(privacyStatement)}</div>
          </Dialog>
          <Dialog
            title="Error"
            titleStyle={styles.dialogTitle}
            contentStyle={styles.contentStyle}
            actions={[
              <FlatButton
                label={strings['labels/ok']}
                primary
                onClick={() => {
                  // rongyadong
                  // this.setState({ dialog: false });
                  this.props.history.push(`/${this.props.site.name}${path.compose}`);
                }}
              />,
            ]}
            modal={false}
            open={false}>
            {this.state.validationErrors}
          </Dialog>
          <Dialog
            title={
              this.state.submitMsg.result === '1' ? complaintSubmitted : complaintFailedToSubmit
            }
            actions={[
              <FlatButton
                label={
                  this.state.submitMsg.result === '1' && closeConfirmation.length
                    ? closeConfirmation
                    : close
                }
                primary
                onClick={() => {
                  this.afterSubmit();
                  this.setState({ dialogSubmission: false });
                }}
              />,
            ]}
            modal={false}
            open={this.state.dialogSubmission}
            titleStyle={styles.dialogTitle}
            contentStyle={styles.contentStyle}>
            {this.state.submitMsg.result === '1' ? (
              <div>{this.renderHtml(confirmationText)}</div>
            ) : (
              <p>{this.state.submitMsg.error}</p>
            )}
          </Dialog>
        </Fragment>
      );
    }
    return <Redirect to={{ pathname: `/${this.props.site.name}${path.login}` }} />;
  }
}

Compose.propTypes = {
  history: PropTypes.object.isRequired,
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  defineProfile: PropTypes.array.isRequired,
  profileData: PropTypes.object.isRequired,
  newProfileLoaded: PropTypes.string.isRequired,
  defineComplaint: PropTypes.array.isRequired,
  complaintData: PropTypes.object.isRequired,
  geolocation: PropTypes.array.isRequired,
  getSubmitKey: PropTypes.func.isRequired,
  saveState: PropTypes.func.isRequired,
  isAppJustLoaded: PropTypes.func.isRequired,
  checkLocationAddress: PropTypes.func.isRequired,
};

export default withRouter(Compose);
