import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { AppBar, Menu, MenuItem } from 'material-ui';
import IconButton from 'material-ui/IconButton';
import NavigationMenu from 'material-ui/svg-icons/navigation/menu';
import ActionHome from 'material-ui/svg-icons/action/home';
import ActionSettings from 'material-ui/svg-icons/action/settings';
import ActionHistory from 'material-ui/svg-icons/action/history';
import IconSubmitNow from 'material-ui/svg-icons/notification/priority-high';
import ImageEdit from 'material-ui/svg-icons/image/edit';
import SocialPerson from 'material-ui/svg-icons/social/person';
import CloudDownload from 'material-ui/svg-icons/file/cloud-download';
import AppBarMenu from './AppBarMenu';
import AccountButton from './AccountButton';
import { airportCode, path, isMobile, appendAccessibility, log } from '../utils/utilities';
import { getDetails } from '../utils/auth0';
import { Drawer } from './Drawer';

const navigationWidth = 224;
const styles = {
  siteName: {
    color: '#757575',
    fontSize: '1.25rem',
    fontWeight: 500,
    lineHeight: 1.6,
    letterSpacing: '0.0075em',
    margin: 0,
    padding: '11px 22px',
    borderBottom: '1px solid #949494',
  },
  appBarMobile: { position: 'fixed', top: 0, fontSize: 18 },
  appBar: { position: 'fixed', top: 0, fontSize: 18, width: `calc(100% - ${navigationWidth}px)` },
  pushDown: { height: 55 },
  noIcon: { paddingLeft: 6 },
};

const NoIcon = () => <div style={styles.noIcon} />;

class AppNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = { drawer: false, animateOpenDrawer: false, mobile: isMobile() };
  }

  componentDidMount() {
    if (this.menuBtn && this.menuBtn.button) {
      const menuBtnNode = this.menuBtn.button.button;
      appendAccessibility(menuBtnNode, 'aria-label', 'Menu');
    }
    window.scrollTo(0, 0);
    this.checkLayout();
    window.addEventListener('resize', this.checkLayout);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkLayout);
  }

  checkLayout = () => {
    this.setState({ mobile: isMobile() });
  };

  refreshMenu = () => {
    const { strings } = this.props;
    // log('refreshMenu: this.props', this.props);
    this.menu = [
      {
        title: strings['labels/home'],
        icon: <ActionHome />,
        path: `/${this.props.site.name}`,
      },
      {
        title: strings['labels/profile'],
        icon: <SocialPerson />,
        path: `/${this.props.site.name}${path.profile}`,
      },
      {
        title: strings['labels/compose'],
        icon: <ImageEdit />,
        path: `/${this.props.site.name}${path.compose}`,
      },
    ];
    if (this.props.site.config.displaySubmitNowForm === true) {
      this.menu.push({
        title: strings['labels/submitNow'],
        icon: <IconSubmitNow />,
        path: `/${this.props.site.name}${path.submitNow}`,
      });
    }
    if (this.props.site.history.enabled === '1') {
      this.menu.push({
        title: strings['labels/history'],
        icon: <ActionHistory />,
        path: `/${this.props.site.name}${path.history}`,
        protected: true,
      });
    }
    if (this.props.site.correspondence && this.props.site.correspondence.enabled === '1') {
      this.menu.push({
        title: strings['labels/correspondence'],
        icon: <CloudDownload />,
        path: `/${this.props.site.name}${path.correspondence}`,
        protected: true,
      });
    }
    if (this.props.site.login.behaviour !== 'None') {
      this.menu.push({
        title: strings['labels/settings'],
        icon: <ActionSettings />,
        path: `/${this.props.site.name}${path.settings}`,
        conditional: true,
      });
    } else {
      log('Hiding settings as login behaviour is "None" in Focus');
    }
  };

  drawerToggle = () => {
    if (this.state.mobile) {
      const drawer = !this.state.drawer;
      this.setState({ drawer });
      setTimeout(() => this.setState({ animateOpenDrawer: true }), 1);
    }
  };

  drawerClose = () => this.setState({ drawer: false });

  render() {
    this.refreshMenu();
    const { strings, site } = this.props;
    const { mobile, drawer } = this.state;
    const displaySiteName = this.props.site.config.displaySiteName === '1';
    const title = `${displaySiteName ? airportCode(site.name) : ''} ${this.props.title}`;
    const accessToken = getDetails(site.name, 'accessToken');
    const idToken = getDetails(site.name, 'idToken');
    return (
      <Fragment>
        <AppBar
          title={title}
          style={mobile ? styles.appBarMobile : styles.appBar}
          iconElementLeft={
            mobile ? (
              <IconButton
                aria-expanded={drawer}
                ref={(menuBtn) => {
                  this.menuBtn = menuBtn;
                }}>
                <NavigationMenu />
              </IconButton>
            ) : (
              <NoIcon />
            )
          }
          onLeftIconButtonClick={this.drawerToggle}
          iconElementRight={
            mobile ? (
              <AppBarMenu site={site} strings={strings} />
            ) : (
              <AccountButton site={site} strings={strings} />
            )
          }
        />
        {(!mobile || (mobile && drawer)) && (
          <Drawer
            docked={!mobile}
            width={navigationWidth}
            open={!mobile || this.state.animateOpenDrawer}
            onRequestChange={(d) => {
              this.setState({ animateOpenDrawer: d });
              setTimeout(() => this.setState({ drawer: d }), 500);
            }}>
            <div>
              <h6 style={styles.siteName}>{`${strings['viewpoint/appName']}`}</h6>
            </div>
            <Menu style={styles.menu} disableAutoFocus>
              {this.menu.map((item) => {
                if (!item.protected || (item.protected && accessToken && idToken)) {
                  return (
                    <MenuItem
                      primaryText={item.title}
                      key={item.path}
                      leftIcon={item.icon}
                      containerElement={<Link to={item.path} />}
                      onClick={this.drawerClose}
                      style={item.conditional && mobile ? { display: 'none' } : {}}
                    />
                  );
                }
                return '';
              })}
            </Menu>
          </Drawer>
        )}
        <div style={styles.pushDown} />
      </Fragment>
    );
  }
}

AppNavigation.propTypes = {
  title: PropTypes.string.isRequired,
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
};

export default AppNavigation;
