import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider } from 'material-ui/styles';
import AppBar from 'material-ui/AppBar';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import CommunicationLocationOn from 'material-ui/svg-icons/communication/location-on';
import { appName, appendButtonText, appendAccessibility } from '../utils/utilities';

const styles = {
  dropdownWidth: { width: 300 },
  appBar: { fontSize: '18px' },
  siteSelectorWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
};

class SiteSelector extends Component {
  state = { value: '' };

  componentDidMount() {
    // Modify the material UI node to reach accessibility purpose
    if (this.dropdownMenu && this.dropdownMenu.arrowNode && this.dropdownMenu.arrowNode.button) {
      const buttonNode = this.dropdownMenu.arrowNode.button.button;
      appendButtonText(buttonNode, 'dropdown');
      appendAccessibility(buttonNode, 'aria-label', 'dropdown');
    }

    if (this.iconbtn && this.iconbtn.button) {
      const iconbtnNode = this.iconbtn.button.button;
      appendAccessibility(iconbtnNode, 'aria-hidden', 'true');
    }
  }

  handleChange = (event, index, value) => {
    if (value) {
      this.setState({ value });
      window.location.href = `${window.location.origin}/${value}`;
    }
  };

  render() {
    return (
      <MuiThemeProvider>
        <Fragment>
          <div>
            <AppBar
              title={appName}
              style={styles.appBar}
              iconElementLeft={
                <IconButton
                  ref={(iconbtn) => {
                    this.iconbtn = iconbtn;
                  }}>
                  <div hidden>location icon</div>
                  <CommunicationLocationOn />
                </IconButton>
              }
            />
          </div>
          <br />
          <div style={styles.siteSelectorWrapper}>
            <DropDownMenu
              ref={(dropdown) => {
                this.dropdownMenu = dropdown;
              }}
              value={this.state.value}
              onChange={this.handleChange}
              style={styles.dropdownWidth}
              autoWidth={false}>
              <MenuItem key="" value="" primaryText="Select an airport" />
              {this.props.sites
                .filter((item) => item.site_name && item.location_name)
                .map((item) => (
                  <MenuItem
                    key={item.site_name}
                    value={item.site_name}
                    primaryText={`${item.location_name} (${item.site_name})`}
                  />
                ))}
            </DropDownMenu>
          </div>
        </Fragment>
      </MuiThemeProvider>
    );
  }
}

SiteSelector.propTypes = {
  sites: PropTypes.array.isRequired,
};

export default SiteSelector;
