import React from 'react';

export const Clock = ({ appbarColor }) => {
  const styles = {
    digitalClock: {
      background: `${appbarColor}`,
      color: '#FFF',
    },
    digitalClockText: {
      fontSize: '32px',
      margin: 0,
      padding: '20px',
      textAlign: 'center',
    },
  };

  const [date, setDate] = React.useState(new Date());

  React.useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setDate(new Date());
  }

  return (
    <div className="digitalClock" style={styles.digitalClock}>
      <div style={styles.digitalClockText}>{date.toLocaleTimeString()}</div>
    </div>
  );
};
