import React, { Component, Fragment } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import Dialog from 'material-ui/Dialog';
import { CardHeader } from 'material-ui/Card';
import { Tabs, Tab } from 'material-ui/Tabs';
import RaisedButton from 'material-ui/RaisedButton';
import NavigationChevronLeft from 'material-ui/svg-icons/navigation/chevron-left';
import FlatButton from 'material-ui/FlatButton';
import Auth0Lock from 'auth0-lock';
import { path, productName, focusEndpoint, appendAccessibility } from '../utils/utilities';
import { auth0Values, storeAccessTokenDetails } from '../utils/auth0';
import { TextField } from '../components/TextField';

const styles = {
  auth0Container: { padding: 20, backgroundColor: '#c3c3c3' },
  skipBtnContainer: { width: '100%', textAlign: 'center' },
  noBtnContainer: { backgroundColor: '#c3c3c3' },
  formSection: { padding: '2px 22px' },
  loginWrapper: { paddingTop: 20 },
  loginButton: { margin: '2px 12px 12px 0px' },
  resetButton: { margin: '35px 12px 12px 0px' },
  signupButton: { margin: '22px 12px 12px 0px' },
  forgotPassWrapper: { textAlign: 'center', padding: '18px 0px 18px' },
  forgotPass: { fontSize: 14, cursor: 'pointer', display: 'block', textDecoration: 'none' },
  spinnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 30,
  },
  spinner: { fontSize: 14, fontWeight: 500, color: '#757575' },
  contentStyle: { maxWidth: 320, wordWrap: 'break-word' },
  dialogTitle: { fontSize: 17 },
  avatarStyle: { height: '40px', width: '40px' },
  hide: { display: 'none' },
};

const SkipLoggin = (props) => {
  if (props.site.login.behaviour === 'Enable') {
    return (
      <div style={styles.skipBtnContainer}>
        <FlatButton
          label={`${props.strings['labels/skip']}`}
          primary
          containerElement={<Link to={`/${props.site.name}${path.skipLogin}`} />}
        />
      </div>
    );
  } else if (props.site.login.behaviour === 'Require') {
    return (
      <div style={styles.skipBtnContainer}>
        <FlatButton label={props.strings['labels/loginIsRequired']} primary disabled />
      </div>
    );
  }
  return <div style={styles.noBtnContainer} />;
};

class Lock extends Component {
  state = {
    loggedIn: false,
    tab: 'login',
    dialog: false,
    dialogTitle: '',
    dialogDescription: '',
    dialogActions: [],
    username: '',
    password: '',
    resetPass: false,
    loading: false,
    errors: {
      loginUsername: '',
      loginPassword: '',
      signupUsername: '',
      signupPassword: '',
      resetUsername: '',
    },
  };

  componentDidMount() {
    if (this.loginBtn && this.loginBtn.refs.container.button) {
      const loginBtnNode = this.loginBtn.refs.container.button;
      appendAccessibility(loginBtnNode, 'alt', '');
    }

    document.querySelectorAll('hr').forEach((item) => {
      item.style.borderBottom = '1px solid #949494';
    });
  }

  switchTab = (tab) => {
    this.setState({ tab, password: '' });
  };

  auth0 = auth0Values(this.props.site.name);

  lock = new Auth0Lock(this.props.site.login.auth0clientId, this.props.site.login.auth0Domain, {
    auth: {
      redirect: false,
      responseType: this.auth0.responseType,
      sso: false,
    },
  });

  submitLogin = () => {
    // validations
    const username = this.state.username.trim() !== '' ? this.state.username.trim() : false;
    const password = this.state.password.trim() !== '' ? this.state.password.trim() : false;
    const { strings } = this.props;
    const loginSuccess = strings['auth0/login/success'];
    const verify = strings['auth0/signup/verify'];

    this.setState({
      errors: {
        loginUsername: !username ? strings['labels/usernameIsRequired'] : '',
        loginPassword: !password ? strings['labels/passwordIsRequired'] : '',
      },
    });

    if (username && password) {
      const data = {
        client_id: this.props.site.login.auth0clientId,
        connection: 'Username-Password-Authentication',
        grant_type: 'password',
        scope: 'openid email profile',
        username,
        password,
        device: '',
        audience: 'http://viewpoint-pwa-api.emsbk.com',
      };

      this.setState({ loading: true });
      axios({
        method: 'post',
        url: `${this.props.site.login.auth0_endpoint}/oauth/ro`,
        data,
      })
        .then((response) => {
          localStorage.setItem(auth0Values(this.props.site.name).idToken, response.data.id_token);
          axios({
            method: 'post',
            url: `${this.props.site.login.auth0_endpoint}/oauth/token`,
            data,
          })
            .then((tokenRes) => {
              this.retrieveProfileData(tokenRes.data.access_token).then((profilData) => {
                const expiredConfig = Number(this.props.site.login.expiry_duration);
                let expiryDays = expiredConfig;
                if (expiryDays > 30 || expiryDays <= 0) {
                  expiryDays = expiredConfig > 30 ? 30 : 1;
                }
                if (profilData.email_verified) {
                  storeAccessTokenDetails(this.props.site.name, {
                    accessToken: tokenRes.data.access_token,
                    expiresIn: expiryDays * 86400,
                  });
                }
                this.setState({
                  dialog: true,
                  loading: false,
                  loggedIn: profilData.email_verified,
                  dialogTitle: !profilData.email_verified ? verify : loginSuccess,
                  dialogDescription: '',
                  dialogActions: !profilData.email_verified
                    ? [
                        <FlatButton
                          label={strings['labels/close']}
                          primary
                          onClick={() => {
                            this.setState({ dialog: false });
                          }}
                        />,
                      ]
                    : [],
                });
              });
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((response) => {
          console.error(response);
          this.setState({
            dialog: true,
            loading: false,
            dialogTitle: strings['labels/wrongEmailOrPassword'],
            dialogDescription: '',
            dialogActions: [
              <FlatButton
                label={strings['labels/close']}
                primary
                onClick={() => {
                  this.setState({ dialog: false });
                }}
              />,
            ],
          });
        });
    }
  };

  submitSignUp = () => {
    // validations
    const email = this.state.username.trim() !== '' ? this.state.username.trim() : false;
    const password = this.state.password.trim() !== '' ? this.state.password.trim() : false;
    const { strings } = this.props;
    const accountExist = strings['auth0/signup/accountExist'];
    const loginInstead = strings['auth0/signup/loginInstead'];
    const signupSuccess = strings['auth0/signup/success'];
    const verify = strings['auth0/signup/verify'];
    this.setState({
      errors: {
        signupUsername: !email ? 'Username is required' : '',
        signupPassword: !password ? 'Password is required' : '',
      },
    });
    if (email && password) {
      const data = {
        client_id: this.props.site.login.auth0clientId,
        connection: 'Username-Password-Authentication',
        email,
        password,
      };
      this.setState({ loading: true });
      axios({
        method: 'post',
        url: `${this.props.site.login.auth0_endpoint}/dbconnections/signup`,
        data,
      })
        .then((response) => {
          this.setState({
            dialog: true,
            loading: false,
            dialogTitle: signupSuccess,
            dialogDescription: verify,
            dialogActions: [
              <FlatButton
                label={strings['labels/close']}
                primary
                onClick={() => {
                  this.setState({ dialog: false, tab: 'login' });
                }}
              />,
            ],
          });
        })
        .catch((response) => {
          console.error(response);
          this.setState({
            dialog: true,
            loading: false,
            dialogTitle: accountExist,
            dialogDescription: loginInstead,
            dialogActions: [
              <FlatButton
                label={strings['labels/close']}
                primary
                onClick={() => {
                  this.setState({ dialog: false });
                }}
              />,
            ],
          });
        });
    }
  };

  resetPassRequest = () => {
    // validations
    const email = this.state.username.trim() !== '' ? this.state.username.trim() : false;
    const { strings } = this.props;
    this.setState({
      errors: {
        resetUsername: !email ? strings['labels/usernameIsRequired'] : '',
      },
    });
    if (email) {
      const data = {
        client_id: this.props.site.login.auth0clientId,
        connection: 'Username-Password-Authentication',
        email,
      };
      this.setState({ loading: true });
      axios({
        method: 'post',
        url: `${this.props.site.login.auth0_endpoint}/dbconnections/change_password`,
        data,
      })
        .then((response) => {
          this.setState({
            loading: false,
            dialogTitle: response.data || '',
            dialogDescription: '',
            resetPass: false,
            dialog: false,
            tab: 'login',
          });
        })
        .catch((response) => {
          console.error(response);
          this.setState({ loading: false });
        });
    }
  };

  retrieveProfileData(accessToken = null) {
    return new Promise((resolve, reject) => {
      this.lock.getUserInfo(
        accessToken || localStorage.getItem(this.auth0.accessToken),
        (error, profile) => {
          if (!error) {
            // localStorage.setItem(this.auth0.userProfile, JSON.stringify(profile));
            resolve(profile);
          }
          // eslint-disable-next-line
          reject('Failed to Get profile');
        }
      );
    });
  }

  render() {
    const { strings } = this.props;
    // const requiredHint = strings['forms/complaint/hint/required'];
    const {
      theme: { headerColor, subHeaderColor },
    } = this.props.site;
    return !this.state.loggedIn ? (
      <Fragment>
        <CardHeader
          title={strings['viewpoint/app/title']}
          titleStyle={{ color: headerColor }}
          subtitleStyle={{ color: subHeaderColor }}
          subtitle={strings['viewpoint/app/subTitle']}
          // avatar={`${focusEndpoint}/productinfo?action=get_object&key=pwa/192png&sitename=${
          //   this.props.site.name
          // }&product=${productName}`}
          avatar={
            <img
              style={styles.avatarStyle}
              src={`${focusEndpoint}/productinfo?action=get_object&key=pwa/192png&sitename=${this.props.site.name}&product=${productName}`}
              alt="site logo"
            />
          }
        />
        {!this.state.resetPass && (
          <div>
            <Tabs value={this.state.tab} onChange={this.switchTab}>
              <Tab
                label={strings['labels/login']}
                value="login"
                aria-expanded={this.state.tab === 'login'}>
                {this.state.tab === 'login' && (
                  <div style={styles.formSection}>
                    <TextField
                      autoComplete={this.state.tab === 'login' ? 'username' : 'email'}
                      fullWidth
                      autoCapitalize="off"
                      // hintText={requiredHint}
                      floatingLabelText={strings['labels/yourEmail']}
                      floatingLabelFixed
                      type="email"
                      errorText={this.state.errors.loginUsername}
                      value={this.state.username}
                      onChange={(event) => {
                        this.setState({
                          username: event.target.value,
                        });
                      }}
                    />
                    <TextField
                      fullWidth
                      autoComplete={
                        this.state.tab === 'login' ? 'current-password' : 'new-password'
                      }
                      autoCapitalize="off"
                      // hintText={requiredHint}
                      floatingLabelText={strings['labels/yourPassword']}
                      floatingLabelFixed
                      type="password"
                      errorText={this.state.errors.loginPassword}
                      value={this.state.password}
                      onChange={(event) => {
                        this.setState({
                          password: event.target.value,
                        });
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') this.submitLogin();
                      }}
                    />
                    {this.state.loading ? (
                      <div style={styles.spinnerWrapper}>
                        <p style={styles.spinner}>{`${strings['labels/pleaseWait']}`}</p>
                      </div>
                    ) : (
                      <div style={styles.loginWrapper}>
                        <RaisedButton
                          ref={(loginBtn) => {
                            this.loginBtn = loginBtn;
                          }}
                          fullWidth
                          primary
                          label={this.props.strings['labels/login']}
                          style={styles.loginButton}
                          onClick={this.submitLogin}
                        />
                        <div style={styles.forgotPassWrapper}>
                          {/* eslint-disable-next-line */}
                          <a
                            role="button"
                            style={styles.forgotPass}
                            onClick={() => {
                              this.setState({ resetPass: true });
                            }}>
                            {strings['labels/dontRememberPassword']}
                          </a>
                        </div>
                      </div>
                    )}
                    {!this.state.loading && (
                      <SkipLoggin site={this.props.site} strings={this.props.strings} />
                    )}
                  </div>
                )}
              </Tab>
              <Tab
                label={strings['labels/signUp']}
                value="Signup"
                aria-expanded={this.state.tab === 'Signup'}>
                {this.state.tab === 'Signup' && (
                  <div style={styles.formSection}>
                    <TextField
                      fullWidth
                      autoCapitalize="off"
                      // hintText={requiredHint}
                      type="email"
                      floatingLabelText={strings['labels/yourEmail']}
                      floatingLabelFixed
                      errorText={this.state.errors.signupUsername}
                      value={this.state.username}
                      onChange={(event) => {
                        this.setState({
                          username: event.target.value,
                        });
                      }}
                    />
                    <TextField
                      fullWidth
                      autoCapitalize="off"
                      // hintText={requiredHint}
                      floatingLabelText={strings['labels/yourPassword']}
                      floatingLabelFixed
                      type="password"
                      errorText={this.state.errors.signupPassword}
                      value={this.state.password}
                      onChange={(event) => {
                        this.setState({
                          password: event.target.value,
                        });
                      }}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') this.submitSignUp();
                      }}
                    />
                    {this.state.loading ? (
                      <div style={styles.spinnerWrapper}>
                        <p style={styles.spinner}>{`${strings['labels/pleaseWait']}`}</p>
                      </div>
                    ) : (
                      <div>
                        <RaisedButton
                          fullWidth
                          primary
                          label={strings['labels/signUp']}
                          style={styles.signupButton}
                          onClick={this.submitSignUp}
                        />
                      </div>
                    )}
                  </div>
                )}
              </Tab>
            </Tabs>
          </div>
        )}
        {this.state.resetPass && (
          <div>
            <Tabs value="reset">
              <Tab label={strings['labels/resetPassword']} value="reset">
                <div style={styles.formSection}>
                  <TextField
                    fullWidth
                    autoCapitalize="off"
                    // hintText={requiredHint}
                    floatingLabelText={strings['labels/yourEmail']}
                    floatingLabelFixed
                    type="email"
                    errorText={this.state.errors.resetUsername}
                    value={this.state.username}
                    onChange={(event) => {
                      this.setState({
                        username: event.target.value,
                      });
                    }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') this.resetPassRequest();
                    }}
                  />
                  {this.state.loading ? (
                    <div style={styles.spinnerWrapper}>
                      <p style={styles.spinner}>{`${strings['labels/pleaseWait']}`}</p>
                    </div>
                  ) : (
                    <RaisedButton
                      fullWidth
                      primary
                      label={strings['labels/sendEmail']}
                      style={styles.resetButton}
                      onClick={this.resetPassRequest}
                    />
                  )}
                </div>
              </Tab>
            </Tabs>
            <div style={styles.skipBtnContainer}>
              {!this.state.loading && (
                <div>
                  <FlatButton
                    label={strings['labels/goBack']}
                    icon={<NavigationChevronLeft />}
                    onClick={() => {
                      this.setState({ resetPass: false });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        <Dialog
          title={this.state.dialogTitle}
          actions={this.state.dialogActions}
          modal={false}
          open={this.state.dialog}
          titleStyle={styles.dialogTitle}
          contentStyle={styles.contentStyle}>
          {this.state.dialogDescription && <p>{this.state.dialogDescription}</p>}
        </Dialog>
      </Fragment>
    ) : (
      <Redirect to={{ pathname: `/${this.props.site.name}${path.getProfileInfo}` }} />
    );
  }
}

Lock.propTypes = {
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
};

SkipLoggin.propTypes = {
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
};

export default Lock;
