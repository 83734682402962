import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { CardHeader } from 'material-ui/Card';
import RaisedButton from 'material-ui/RaisedButton';
import AppNavigation from './AppNavigation';
import {
  productName,
  path,
  isMobile,
  focusEndpoint,
  localeSelectionRequired,
} from '../utils/utilities';
import { isAuthenticated, isLoginSkipped, needsAuthentication } from '../utils/auth0';

const styles = {
  intro: { padding: '2px 22px' },
  pushDown: { height: 20 },
  logo: { paddingTop: 20, width: 150 },
  newLogo: {
    color: 'rgb(255, 255, 255)',
    backgroundColor: 'rgb(188, 188, 188)',
    userSelect: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 20,
    borderRadius: '50%',
    height: 40,
    width: 40,
    marginRight: 16,
  },
};

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: isMobile(),
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.checkLayout();
    window.addEventListener('resize', this.checkLayout);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkLayout);
  }

  checkLayout = () => {
    if (this.state.mobile !== isMobile()) this.setState({ mobile: isMobile() });
  };

  intro = (html) => (
    // eslint-disable-next-line
    <div style={styles.intro} dangerouslySetInnerHTML={{ __html: html }} />
  );

  render() {
    const { strings } = this.props;
    const pageTitle = strings['labels/home'];
    const title = strings['viewpoint/app/title'];
    const subTitle = strings['viewpoint/app/subTitle'];
    const intro = strings['viewpoint/app/intro'];
    const {
      theme: { headerColor, subHeaderColor },
    } = this.props.site;

    // is language selection required?
    if (localeSelectionRequired(this.props.site))
      return <Redirect to={{ pathname: `/${this.props.site.name}${path.language}` }} />;
    if (isAuthenticated(this.props.site)) {
      if (
        !this.props.newProfileLoaded &&
        !isLoginSkipped(this.props.site) &&
        needsAuthentication(this.props.site)
      ) {
        return <Redirect to={{ pathname: `/${this.props.site.name}${path.getProfileInfo}` }} />;
      }
      const check = this.props.isAppJustLoaded();
      if (!check.isPrivacyAccepted)
        return <Redirect to={{ pathname: `/${this.props.site.name}${path.privacy}/accept` }} />;
      if (
        check.isProfileValid &&
        this.props.site.complaintsform.openComplaintFormOnLaunch === '1'
      ) {
        return <Redirect to={{ pathname: `/${this.props.site.name}${path.compose}` }} />;
      }
      return (
        <Fragment>
          <AppNavigation site={this.props.site} strings={this.props.strings} title={pageTitle} />
          {this.state.mobile && (
            <CardHeader
              ref={(crdHeader) => {
                this.crdHeader = crdHeader;
              }}
              id="homeHeader"
              title={title}
              titleStyle={{ color: headerColor }}
              subtitleStyle={{ color: subHeaderColor }}
              subtitle={subTitle}
              avatar={
                <img
                  src={`${focusEndpoint}/productinfo?action=get_object&key=pwa/192png&sitename=${this.props.site.name}&product=${productName}`}
                  alt={`${title} logo`}
                  style={styles.newLogo}
                />
              }
            />
          )}
          <div className={this.state.mobile ? 'none-flex-container' : 'flex-container'}>
            {!this.state.mobile && (
              <div className="airport-info" style={styles.intro}>
                <img
                  alt="logo"
                  src={`${focusEndpoint}/productinfo?action=get_object&key=pwa/192png&sitename=${this.props.site.name}&product=${productName}`}
                  style={styles.logo}
                />
                <div>
                  <h2 style={{ color: headerColor }}>{title}</h2>
                  <h3 style={{ color: subHeaderColor }}>{subTitle}</h3>
                </div>
              </div>
            )}
            {this.intro(intro)}
            <div className="button-wrapper">
              <RaisedButton
                fullWidth
                primary
                label={this.props.strings['complaintsform/blocks/submitComplaint']}
                style={styles.loginButton}
                containerElement={<Link to={`/${this.props.site.name}${path.compose}`} />}
              />
              {!this.state.mobile && <div style={styles.pushDown} />}
            </div>
          </div>
        </Fragment>
      );
    }
    return (
      <Redirect
        to={{
          pathname: `/${this.props.site.name}${path.login}`,
        }}
      />
    );
  }
}

Home.propTypes = {
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  newProfileLoaded: PropTypes.string.isRequired,
  isAppJustLoaded: PropTypes.func.isRequired,
};

export default Home;
