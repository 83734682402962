import { ListItem } from 'material-ui/List';
import styled from 'styled-components';

const StyledListItem = styled(ListItem)`
  hr {
    margin: 0 !important;
  }
`;

export { StyledListItem as ListItem };
