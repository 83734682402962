import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import { appName, shouldClearCache } from '../utils/utilities';
import { removeData } from '../utils/data';

const styles = {
  contentStyle: { maxWidth: 320 },
  secondDialog: { padding: '5px 24px 20px' },
  dialogTitle: { fontSize: 15, fontWeight: 500, padding: '15px 24px 0px' },
};

class RecacheApp extends Component {
  constructor(props) {
    super(props);
    const check = shouldClearCache(props.site.app.build);
    if (check) this.checkAssets();
    this.state = { dialog: false };
  }

  checkAssets = () => {
    ['script', 'link'].forEach((element) => {
      document.querySelectorAll(element).forEach((item) => {
        const src = element === 'script' ? item.getAttribute('src') : item.getAttribute('href');
        if (src && src.search('/static/') !== -1) {
          axios.get(`${window.location.origin}${src}?${new Date().getTime()}`);
        }
      });
    });
    console.log('reload 001');
    window.location.reload(true);
  };

  render() {
    const { strings } = this.props;
    return (
      <Dialog
        title={strings['labels/newVersionAvailable'].replace('<appName>', appName)}
        actions={[
          <FlatButton
            label={strings['labels/refresh']}
            primary
            keyboardFocused
            onClick={() => {
              removeData('data', this.props.site.name);
              console.log('reload 002');
              window.location.reload(true);
            }}
          />,
        ]}
        modal
        open={this.state.dialog}
        titleStyle={styles.dialogTitle}
        bodyStyle={styles.secondDialog}
        contentStyle={styles.contentStyle}
      />
    );
  }
}

RecacheApp.propTypes = {
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
};

export default RecacheApp;
