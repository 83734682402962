import PropTypes from 'prop-types';
import React, { Component, Fragment, cloneElement } from 'react';
import { MuiThemeProvider, getMuiTheme } from 'material-ui/styles';
import RecacheApp from '../components/RecacheApp';

class AppShell extends Component {
  muiTheme = () => {
    const { appbarColor, appbarTitleColor } = this.props.site.theme;
    return getMuiTheme({
      appBar: {
        height: 55,
      },
      palette: {
        primary1Color: appbarColor,
        alternateTextColor: appbarTitleColor,
        accent1Color: appbarColor,
        pickerHeaderColor: appbarColor,
      },
      snackbar: {
        actionColor: '#ffffff',
      },
      tabs: {
        backgroundColor: '#d7d7d7',
        selectedTextColor: appbarColor,
        textColor: appbarColor,
      },
      textField: {
        floatingLabelColor: '#4B4F52',
        focusColor: appbarColor,
        hintColor: '#6F7479',
      },
      SelectField: {
        floatingLabelColor: '#4B4F52',
        focusColor: appbarColor,
        hintColor: '#6F7479',
      },
    });
  };

  render() {
    const location = window.location.pathname.split('/').join(' ');
    const { strings } = this.props;
    return (
      <MuiThemeProvider muiTheme={this.muiTheme()}>
        <Fragment>
          <RecacheApp site={this.props.site} strings={strings} />
          <div id="content" className={`${this.props.mobile ? 'mobile' : 'desktop'} ${location}`}>
            {cloneElement(this.props.children)}
          </div>
        </Fragment>
      </MuiThemeProvider>
    );
  }
}

AppShell.propTypes = {
  children: PropTypes.object.isRequired,
  site: PropTypes.object.isRequired,
  mobile: PropTypes.bool.isRequired,
  strings: PropTypes.object.isRequired,
};

export default AppShell;
