import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from 'material-ui/List';
import Attachment from 'material-ui/svg-icons/file/attachment';
import Divider from 'material-ui/Divider';
import AppNavigation from './AppNavigation';
import { isMobile, getViewpointApiUrl } from '../utils/utilities';
// import { getDetails } from '../utils/auth0';
import { RequestHelper } from '../utils/RequestHelper';
// eslint-disable-next-line import/first
import styled from 'styled-components';

const ListItemContainer = styled.div`
  position: relative;
`;

const DownloadSpinner = styled.div.attrs({
  className: 'spinner',
})`
  &:after {
    margin-top: 0.75rem !important;
    width: 2rem !important;
    height: 2rem !important;
  }
`;

const DownloadSpinnerContainer = styled.div`
  position: absolute;
  text-align: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DownloadSpinnerText = styled.div`
  font-size: 1.6rem;
  color: #fff;
`;

const styles = {
  warningMsg: { textAlign: 'center', color: '#C85121', fontSize: '14px' },
  noDataMsg: { textAlign: 'center', fontSize: '14px' },
  list: { padding: '2px 22px' },
  listMobile: { padding: '8px 5px', textAlign: 'left' },
  messagesList: { padding: '13px 16px 16px 55px', textAlign: 'left' },
  emptyList: { paddingLeft: 18, textAlign: 'center' },
  spinnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '55px 22px',
  },
  downloadSpinner: {
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    backgroundColor: 'black',
    zIndex: 100,
  },
  accessibilityButton: {
    margin: '1rem 0',
    height: '3rem',
    flexGrow: '1',
    textAlign: 'center',
    fontSize: '14px',
    fontFamily: '"Roboto", sans-serif',
    backgroundColor: '#7215D8',
    color: '#FFF',
  },
};

class Correspondence extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mobile: isMobile(),
      // for mock DEMO only, the loading should be TRUE at first!!!
      loading: true,
      downloading: {},
      correspondence: [],
      errorMsg: '',
      noDataMsg: 'There is no correspondence records',
    };
  }

  componentDidMount() {
    this.getAllAttachments();

    this.checkLayout();
    window.addEventListener('resize', this.checkLayout);

    // for HPN3 mock data fix the accessibility issue -51, the right place in the future is in the AJAX call res
    document.querySelectorAll('hr').forEach((item) => {
      item.setAttribute('aria-hidden', true);
      item.style.borderBottom = '1px solid #949494';
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkLayout);
  }

  getAllAttachments = () => {
    RequestHelper.get({
      siteName: this.props.site.name,
      url: `${getViewpointApiUrl(this.props.site)}correspondence/attachments`,
      clientId: this.props.site.login.auth0clientId,
    })
      .then(({ data }) => {
        this.setState({ loading: false, correspondence: data });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          errorMsg: 'Server error, please try again.',
          correspondence: [],
        });
        console.log('getAllAttachments ERROR', err);
      });
  };

  checkLayout = () => {
    if (this.state.mobile !== isMobile()) this.setState({ mobile: isMobile() });
  };

  handleAttachmentClick = (fileName) => {
    // console.log('Download the history attachment now', fileName);
    // window.location.href = `${getViewpointApiUrl(this.props.site)}download/${fileName}`;

    const { downloading } = this.state;
    downloading[fileName] = true;
    this.setState({ downloading });

    RequestHelper.get({
      siteName: this.props.site.name,
      url: `${getViewpointApiUrl(this.props.site)}download/${fileName}`,
      clientId: this.props.site.login.auth0clientId,
      responseType: 'blob', // important
    })
      .then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        downloading[fileName] = false;
        this.setState({ downloading });
      })
      .catch((err) => {
        console.log('Download error', err);
        downloading[fileName] = false;
        this.setState({ downloading });
      });
  };

  showcorrespondence = (data) => {
    const { strings } = this.props;
    const correspondenceItems = data.attachments;
    if (correspondenceItems && correspondenceItems.length > 0) {
      const itemList = correspondenceItems.map((item) => (
        <ListItemContainer key={item.fileName} role="listitem">
          {this.state.downloading[item.fileName] && (
            <DownloadSpinnerContainer
              onClick={(event) => {
                event.stopPropagation();
              }}>
              <DownloadSpinner />
              <DownloadSpinnerText>{`${strings['labels/downloadingFile']}`}</DownloadSpinnerText>
            </DownloadSpinnerContainer>
          )}
          <ListItem
            leftIcon={<Attachment />}
            // rightIcon={<FileDownload />}
            // primaryText={`${strings['labels/sent']}: ${formatSubmissionDateTime(item.when_received)}`}
            primaryText={item.fileName}
            containerElement="button"
            innerDivStyle={styles.messagesList}
            style={{ width: '100%' }}
            secondaryText={
              <p>
                {strings['labels/fileType']}
                {': '}
                <span>{item.fileType}</span>
              </p>
            }
            secondaryTextLines={2}
            onClick={() => this.handleAttachmentClick(item.fileName)}
          />

          <Divider inset />
        </ListItemContainer>
      ));
      return (
        <div style={!this.state.mobile ? styles.list : styles.listMobile}>
          <List role="list">{itemList}</List>
        </div>
      );
    }
    return (
      <div
        style={!this.state.mobile ? styles.list : styles.listMobile}
        aria-label={this.state.errorMsg ? this.state.errorMsg : this.state.noDataMsg}>
        <p style={this.state.errorMsg ? styles.warningMsg : styles.noDataMsg}>
          {this.state.errorMsg ? this.state.errorMsg : this.state.noDataMsg}
        </p>
      </div>
    );
  };

  render() {
    const { strings } = this.props;
    const pageTitle = strings['labels/correspondence'];

    return (
      <Fragment>
        <AppNavigation site={this.props.site} strings={this.props.strings} title={pageTitle} />

        <div className={this.state.mobile ? 'none-flex-container' : 'flex-container'}>
          {this.state.loading && (
            <div style={styles.spinnerWrapper}>
              <div className="spinner" />
            </div>
          )}

          {!this.state.loading && this.showcorrespondence(this.state.correspondence)}
        </div>
      </Fragment>
    );
  }
}

Correspondence.propTypes = {
  site: PropTypes.object.isRequired,
  strings: PropTypes.object.isRequired,
  // match: PropTypes.object.isRequired,
};

export default Correspondence;
