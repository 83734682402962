import axios from 'axios';
import { getDetails } from './auth0';

const RequestHelper = {};

RequestHelper.get = ({ siteName, clientId, url, responseType }) => {
  const idToken = getDetails(siteName, 'idToken');
  // console.log('this is the TOKEN', idToken);

  if (idToken) {
    return axios({
      method: 'get',
      url,
      headers: {
        Authorization: `Bearer ${idToken}`,
        'X-Clientid': clientId,
        'X-Productname': 'ViewpointCollector',
        'X-Sitename': siteName,
      },
      responseType,
    }).catch((error) => {
      console.log('NO, something wrong, there is no DATA response from backend.');
      console.log('the Error is', error);
      return Promise.reject(error);
    });
  }
  console.log('hehe, there is no data, please check something wrong happened.');
  return Promise.reject();
};

export { RequestHelper };
