import React from 'react';
import { BarStack } from '@visx/shape';
import { Group } from '@visx/group';
import { GridRows } from '@visx/grid';
import { AxisRight, AxisBottom } from '@visx/axis';
import { scaleBand, scaleLinear } from '@visx/scale';
// eslint-disable-next-line import/no-extraneous-dependencies
import { timeFormat, timeParse } from 'd3-time-format';

const ComplaintsBarStackGraph = ({
  isMobile,
  width,
  height,
  complaintsData,
  whichDataToShow,
  colorScale,
  clickFunc,
}) => {
  let parseDate;
  let complaintsOverTime;
  let tickValues;
  switch (whichDataToShow) {
    case 'daily':
      parseDate = timeParse('%Y-%m-%d');
      complaintsOverTime = complaintsData.daily;
      tickValues = complaintsData.specificDays;
      break;
    case 'weekly':
      parseDate = timeParse('%Y-%V');
      complaintsOverTime = complaintsData.weekly;
      break;
    default:
      console.log('ComplaintsBarStackGraph: Invalid option specified for which data to use');
  }
  const dateFormat = timeFormat('%b %d');
  const dateTickFormatter = (date, index) => {
    if (isMobile) {
      if (index % 2 === 0) return dateFormat(parseDate(date));
      return '';
    }
    return dateFormat(parseDate(date));
  };
  const roundToInteger = (num) => Math.round(num);
  const margin = { top: 20, bottom: 20, left: 20, right: 20 };
  const xMax = width - (margin.left + margin.right);
  const yMax = height - (margin.top + margin.bottom);
  const x = (d) => d.date;
  // const numTicks = width < 400 ? 6 : 12;

  let max = 0;
  for (let i = 0, ii = complaintsOverTime.length; i < ii; i += 1) {
    if (max < complaintsOverTime[i].total) max = complaintsOverTime[i].total;
  }

  const dateRange = complaintsOverTime.map(x);
  const dateScale = scaleBand({
    domain: dateRange,
    range: [20, xMax],
    padding: 0.2,
  });

  // console.log('dateRange', dateRange);
  if (whichDataToShow === 'weekly') tickValues = dateRange;

  const yScale = scaleLinear({
    domain: [0, max],
    range: [yMax, 0],
    round: true,
  });

  const keys = Object.keys(complaintsOverTime[0]);
  keys.splice(keys.indexOf('date'), 1);
  keys.splice(keys.indexOf('total'), 1);

  const handleClick = (bar) => {
    // console.log('bar:', JSON.parse(JSON.stringify(bar)));
    const filterData = { dailyOrWeekly: whichDataToShow, index: bar.index };
    clickFunc(filterData);
  };

  return (
    <svg width={width} height={height}>
      <Group top={margin.top / 2} left={0}>
        <GridRows
          scale={yScale}
          width={xMax}
          height={yMax}
          stroke="#CCC"
          strokeWidth={1}
          numTicks={4}
        />
        <AxisRight left={xMax} scale={yScale} tickFormat={roundToInteger} numTicks={4} />
        <AxisBottom
          top={yMax}
          scale={dateScale}
          // numTicks={numTicks}
          tickFormat={dateTickFormatter}
          tickValues={tickValues}
          tickLabelProps={(value, index) => ({
            fontSize: 11,
            textAnchor: 'middle',
          })}
        />
        <BarStack
          data={complaintsOverTime}
          x={x}
          xScale={dateScale}
          yScale={yScale}
          color={colorScale}
          keys={keys}>
          {(barStacks) =>
            barStacks.map((barStack) =>
              barStack.bars.map((bar) => (
                <rect
                  key={`bar-stack-${barStack.index}-${bar.index}`}
                  x={bar.x}
                  y={bar.y}
                  height={bar.height}
                  width={bar.width}
                  fill={bar.color}
                  onClick={() => handleClick(bar)}
                />
              ))
            )
          }
        </BarStack>
      </Group>
    </svg>
  );
};

export default ComplaintsBarStackGraph;
