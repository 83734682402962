import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { List, ListItem } from 'material-ui/List';
import axios from 'axios';
import { LinearProgress } from 'material-ui';
import { getViewpointCollectorUrl, path, log } from '../utils/utilities';

class AddressLocator extends Component {
  state = {
    searching: false,
    addressSelected: false,
    result: null,
  };

  typing = false;

  searchAddress = (address) => {
    log('AddressLocator: searchAddress');
    this.setState({ searching: true });
    axios({
      method: 'post',
      url: `${getViewpointCollectorUrl(this.props.site)}geocode/${
        this.props.siteName
      }?address=${address}`,
    })
      .then((response) => {
        if (!this.typing) this.setState({ searching: false, result: response.data.results });
      })
      .catch((response) => {
        this.setState({ searching: false });
        console.error(response);
      });
  };

  addressSelected = () => {
    this.setState({ addressSelected: true });
  };

  render() {
    if (this.state.addressSelected) {
      return <Redirect to={{ pathname: `/${this.props.siteName}${path.reset}${path.profile}` }} />;
    } else if (!this.state.searching && this.state.result && this.state.result.length > 0) {
      const listItems = this.state.result.map((item, index) => (
        <ListItem
          key={item.lat}
          value={index}
          primaryText={item.formatted_address}
          innerDivStyle={{ padding: '7px 0px' }}
          style={{ padding: 0 }}
          onClick={() => {
            this.props.selectAddress(item);
          }}
        />
      ));
      return (
        <Fragment>
          <List>{listItems}</List>
        </Fragment>
      );
    } else if (this.state.searching) {
      return (
        <Fragment>
          <LinearProgress mode="indeterminate" />
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div />
      </Fragment>
    );
  }
}

AddressLocator.propTypes = {
  site: PropTypes.object.isRequired,
  siteName: PropTypes.string.isRequired,
  selectAddress: PropTypes.func.isRequired,
};

export default AddressLocator;
