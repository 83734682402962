import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const InvalidSite = (props) =>
  props.error ? (
    <Fragment>
      <h1 style={{ padding: '20px', fontWeight: 'bold' }}>{props.error}</h1>
    </Fragment>
  ) : (
    <Fragment>
      <h1 style={{ padding: '20px', fontWeight: 'bold' }}>{props.strings['labels/invalidSite']}</h1>
    </Fragment>
  );

InvalidSite.propTypes = {
  error: PropTypes.string.isRequired,
  strings: PropTypes.string.isRequired,
};

export default InvalidSite;
