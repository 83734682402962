const STYLES = {
  BORDER_COLOR: '#949494',
  LABEL_COLOR: 'rgb(75, 79, 82)',

  LABEL_FONT_SIZE: '.85rem',
};

const BREAKPOINT = {
  XS: 0,
  SM: 576,
  MD: 768,
  LG: 1024,
  XL: 1200,
};

const SCREEN_SIZE = {
  XS: `@media screen and (min-width: ${BREAKPOINT.XS}px)`,
  SM: `@media screen and (min-width: ${BREAKPOINT.SM}px)`,
  MD: `@media screen and (min-width: ${BREAKPOINT.MD}px)`,
  LG: `@media screen and (min-width: ${BREAKPOINT.LG}px)`,
  XL: `@media screen and (min-width: ${BREAKPOINT.XL}px)`,
};

export { STYLES, SCREEN_SIZE };
