import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

class Reset extends Component {
  render() {
    // eslint-disable-next-line
    const [a, b, c, redirectPath] = window.location.pathname.split('/');
    return (
      <Redirect
        to={{
          pathname: `/${this.props.site.name}/${redirectPath}`,
        }}
      />
    );
  }
}

Reset.propTypes = {
  site: PropTypes.object.isRequired,
};

export default Reset;
