import styled from 'styled-components';
import { STYLES } from '../styles';

const StyledDateInput = styled.input`
  border: 1px solid ${STYLES.BORDER_COLOR};
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
`;

export { StyledDateInput as DateInput };
