const AddressLookupUtils = {};

AddressLookupUtils.getCityFromFormattedAddress = (address) => {
  const { city, neighborhood, formatted_address: formattedAddress } = address;

  if (!city) {
    return neighborhood;
  }
  if (!neighborhood) {
    return city;
  }

  if (new RegExp(city).test(formattedAddress)) {
    return city;
  } else if (new RegExp(neighborhood).test(formattedAddress)) {
    return neighborhood;
  }
  return city;
};

export { AddressLookupUtils };
